import dayjs from 'dayjs';

import { NewShipmentCardCore } from 'components/AISuggestions/CoreNewShipmentCard';
import { KeyValueElement } from 'components/AISuggestions/SuggestionsCard';
import { CompanyCoreInfo, formatAddressCityStateZip } from 'types/Load';
import { Maybe, Undef } from 'types/UtilityTypes';
import { SuggestedQuoteChange } from 'types/suggestions/QuoteSuggestions';

export const QuickQuoteCard = (
  suggestion: SuggestedQuoteChange
): Undef<JSX.Element> => {
  const sug = suggestion.suggested;

  const formatQQAddress = (
    city: string,
    state: string,
    zip: string
  ): string => {
    if (!city && !state && !zip) {
      return '';
    }

    const address: CompanyCoreInfo = {
      city: city,
      state: state,
      zipCode: zip,
    } as CompanyCoreInfo;

    return formatAddressCityStateZip(address);
  };

  const pickupAddr =
    formatQQAddress(sug.pickupCity, sug.pickupState, sug.pickupZip) ??
    'Unknown';
  const dropoffAddr =
    formatQQAddress(sug.deliveryCity, sug.deliveryState, sug.deliveryZip) ??
    'Unknown';

  const formatDate = (date: Maybe<Date>): string => {
    if (!date) return '';
    return dayjs(date).format('MMM D');
  };

  const pickupDate = formatDate(sug.pickupDate);
  const dropoffDate = formatDate(sug.deliveryDate);

  if (!pickupDate && !dropoffDate && !pickupAddr && !dropoffAddr) {
    return undefined;
  }

  return (
    <NewShipmentCardCore
      pickupAddr={pickupAddr}
      dropoffAddr={dropoffAddr}
      pickupDate={pickupDate}
      dropoffDate={dropoffDate}
      additionalInfo={[
        <KeyValueElement
          name='transportType'
          changeLabel='Transport Type'
          changeValue={sug.transportType}
        />,
      ]}
    />
  );
};
