import dayjs from 'dayjs';

import { NewShipmentCardCore } from 'components/AISuggestions/CoreNewShipmentCard';
import { KeyValueElement } from 'components/AISuggestions/SuggestionsCard';
import { formatAddressCityStateZip } from 'types/Load';
import { Maybe, Undef } from 'types/UtilityTypes';
import { LoadBuildingSuggestions } from 'types/suggestions/LoadBuildingSuggestions';

export const LoadBuildingSuggestionCard = (
  suggestion: LoadBuildingSuggestions
): Undef<JSX.Element> => {
  const pickupAddr =
    formatAddressCityStateZip(suggestion.suggested.pickup) ?? 'Unknown';
  const dropoffAddr =
    formatAddressCityStateZip(suggestion.suggested.consignee) ?? 'Unknown';

  const formatDate = (date: Maybe<Date>): string => {
    if (!date) return '';
    return dayjs(date).format('MMM D');
  };

  const pickupDate = formatDate(
    suggestion.suggested.pickup.apptStartTime ??
      suggestion.suggested.pickup.readyTime
  );
  const dropoffDate = formatDate(
    suggestion.suggested.consignee.apptStartTime ??
      suggestion.suggested.consignee.apptEndTime
  );

  if (!pickupDate && !dropoffDate && !pickupAddr && !dropoffAddr) {
    return undefined;
  }

  return (
    <NewShipmentCardCore
      pickupAddr={pickupAddr}
      dropoffAddr={dropoffAddr}
      pickupDate={pickupDate}
      dropoffDate={dropoffDate}
      additionalInfo={[
        suggestion.suggested.customer?.refNumber ? (
          <div className='mt-2' key='customerRef'>
            <KeyValueElement
              name='customer.ref'
              changeLabel='Customer Ref #'
              changeValue={suggestion.suggested.customer.refNumber}
            />
          </div>
        ) : null,
        suggestion.suggested.customer?.name ? (
          <div key='customerName'>
            <KeyValueElement
              name='customer.name'
              changeLabel='Customer'
              changeValue={suggestion.suggested.customer.name}
            />
          </div>
        ) : null,
      ]}
    />
  );
};
