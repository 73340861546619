import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';
import captureException from 'utils/captureException';

export async function updateQuoteRequestSuggestion(
  suggestionId: number,
  status:
    | SuggestionStatus.InFlight
    | SuggestionStatus.Accepted
    | SuggestionStatus.Rejected,
  changes: QuoteChanges,
  quoteId: number
): Promise<Result<null, ApiError>> {
  try {
    const response = await axios.patch(
      `/suggestions/${suggestionId}/quote-request`,
      {
        status: status,
        changes: changes,
        quoteId: quoteId,
      }
    );

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'updateQuoteRequestSuggestion' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
    });
  }
}
