export const currencyList: string[] = ['USD', 'CAD'];

export const serviceTypes: string[] = ['Any', 'Expedite', 'Standard'];

export const unitsList: string[] = [
  'Metric tons',
  'Bottles',
  'Packages',
  'Racks',
  'Bin',
  'Piece',
  'Ream',
  'Spack',
  'Spool',
  'Bushels',
  'Containers',
  'Gallons',
  'Tons',
  'Kegs',
  'Cases',
  'Truckload',
  'Blocks',
  'Bulk',
  'Barrels',
  'Units',
  'Each',
  'Pallets',
  'Base units',
  'Boxes',
  'Loose',
  'Bags',
  'Bales',
  'Bundles',
  'Cans',
  'Carboys',
  'Carpets',
  'Cartons',
  'Coils',
  'Crates',
  'Cylinders',
  'Drums',
  'Pails',
  'Reels',
  'Lbs',
  'Rolls',
  'Kgs',
  'Skids',
  'Liters',
  'Totes',
  'Tubes/pipes',
  'Vehicles',
  'Other',
  'Kit',
  'Pack',
  'Pair',
  'Feet',
  'Inner Pack',
  'Grams',
  'Layer',
  'Items',
  'Buckets',
  'Combinations',
  'Hundredweight on Net',
  'Pouches',
  'Trays',
  'Tubs',
];

export const weightUnitsList: string[] = ['t', 'oz', 'ton', 'g', 'lb', 'kg'];

export const equipmentList: string[] = [
  'Chassis',
  'Bobtail',
  'Automotive racks',
  'Boxcars',
  'Centerbeams',
  'Covered hoppers',
  'Coil cars',
  'Flatcars',
  'Gondolas',
  'Refrigerated boxcar',
  'Open top hoppers',
  'Specialized rail equipment',
  'Bulk carriers',
  'Container ships',
  'Cargo ship',
  'Refrigerated ships',
  'Roll on/Roll off Ships',
  'Tank barge',
  'Deck barge',
  'Hopper barge',
  'Car float barge',
  'Single-axle',
  'Liquid cargo barge',
  'Double-axle',
  'Barrack barge',
  'Tri-axle',
  'Split Hopper barge',
  'Electric heat',
  'Power barge',
  'Straight truck',
  'Royal Barge',
  'Pickup truck',
  'Van - dry',
  'Cargo van',
  'Flatbed - standard',
  'Mini float',
  'Flatbed - legal',
  'Moffett',
  'Flatbed - stretch/extendable',
  'Winch truck',
  'Flatbed - covered wagon',
  'Crane',
  'Flatbed - curtain side',
  'Flatbed - auto',
  'Hotshot',
  'Bulk commodity',
  'Dump - end',
  'Driveaway',
  'Logging',
  'Dump - side',
  'Container - Bulk',
  'Tow - light duty',
  'Enclosed',
  'Container - Power pack',
  'Hopper',
  'Container - Upgraded',
  'Tanker - chemical',
  'Tanker - food',
  'Tanker - fuel',
  'Tow',
  'Pneumatic',
  'Food trailer - frozen',
  'Truck, van',
  'Container - temperature controlled',
  'Container - high cube',
  'Container - open top',
  'Container - flat rack',
  'Container - pallet wide',
  'Container - refrigerated',
  'Container - tanker',
  'Power unit - tractor',
  'Tow - medium duty',
  'Tow - heavy duty',
  'Tow - landoll',
  'Van',
  'Van - vented',
  'Van - curtain side',
  'Flatbed',
  "Flatbed - 4' tarp",
  "Flatbed - 6' tarp",
  "Flatbed - 8' tarp",
  'Refrigerated',
  'Auto transport',
  'Conestoga',
  'Container - standard',
  'Double drop (low boy)',
  'Removable goose neck',
  'Step deck',
  'Tanker',
  'Other',
  'Hot oil truck',
  'Hydro excavation truck',
  'Pump truck',
  'Vacuum truck',
  'Insulated Van or Reefer',
  'Reefer or Vented Van',
  'Containers',
  'Reefer',
  'Dry - Standard',
  'Dry - HC',
  'Reefer- Standard',
  'Reefer - HC',
  'Hotshot Flatbed',
  'Van - sprinter',
  'Isotank',
  'Flatbed - Quad-axle',
  'Flatbed - Quad-axle rolltite',
  'Flatbed - Super B',
  'Van - Tri-axle dry',
  'Auger feed truck',
  'Ag hopper',
  'Walking floor',
  'Roll Tite',
  'Reefer – Triaxle',
  'Reefer – Quadaxle',
  'Stepdeck Conestoga',
  'Box Truck',
  'Van - open top',
];
