import { ArrowRight, Calendar, MapPin } from 'lucide-react';

import { Maybe } from 'types/UtilityTypes';

type NewShipmentCoreProps = {
  pickupAddr: string;
  dropoffAddr: string;
  pickupDate: string;
  dropoffDate: string;
  additionalInfo?: Maybe<JSX.Element>[];
};

export const NewShipmentCardCore = ({
  pickupAddr,
  dropoffAddr,
  pickupDate,
  dropoffDate,
  additionalInfo,
}: NewShipmentCoreProps) => {
  return (
    <>
      <div className='space-y-1 text-xs'>
        <div className='max-w-full overflow-hidden grid grid-cols-[1fr_auto_1fr] items-center'>
          {/* Pickup Address */}
          <div className='flex items-center'>
            <div className='flex-shrink-0 w-3 mr-1 text-grayscale-content-2'>
              <MapPin className='w-3' />
            </div>
            <div className='line-clamp-3 text-ellipsis text-xs'>
              {pickupAddr}
            </div>
          </div>

          <div className='flex justify-center self-center mx-0.5'>
            <ArrowRight className='w-4 h-4 text-grayscale-content-2' />
          </div>

          {/* Dropoff Address */}
          <div className='flex items-center'>
            <div className='flex-shrink-0 w-3 mr-1 text-grayscale-content-2'>
              <MapPin className='w-3' />
            </div>
            <div className='line-clamp-3 text-ellipsis text-xs'>
              {dropoffAddr}
            </div>
          </div>
        </div>

        {/* Dates row */}
        <div className='grid grid-cols-[1fr_auto_1fr] gap-x-1'>
          {/* Pickup Date */}
          {pickupDate && (
            <div className='flex items-center'>
              <div className='flex-shrink-0 w-3 mr-1 text-grayscale-content-2'>
                <Calendar className='w-3' />
              </div>
              <div className='line-clamp-2 text-ellipsis text-xs'>
                {pickupDate}
              </div>
            </div>
          )}
          <div className='w-4' /> {/* Spacer to match arrow width */}
          {/* Dropoff Date */}
          {dropoffDate && (
            <div className='flex items-center'>
              <div className='flex-shrink-0 w-3 mr-1 text-grayscale-content-2'>
                <Calendar className='w-3' />
              </div>
              <div className='line-clamp-2 text-ellipsis text-xs'>
                {dropoffDate}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='mt-1'>{additionalInfo}</div>
    </>
  );
};
