import { Button } from 'components/Button';

import { TruckTextInput } from '../../TruckListFormFields';

type RedwoodOverrideDropoffFieldsetProps = {
  handleOverrideDropoff: () => void;
};

export const RedwoodOverrideDropoffFieldset = ({
  handleOverrideDropoff,
}: RedwoodOverrideDropoffFieldsetProps) => (
  <>
    <div className='grid grid-cols-3 gap-4 mt-2 mx-0 w-full'>
      <div className='col-span-2'>
        <TruckTextInput name={`override.city`} label='City' />
      </div>

      <div className='col-span-1'>
        <TruckTextInput
          name={`override.state`}
          label='State'
          aiIconOnly={true}
        />
      </div>
    </div>

    <Button
      className='w-full h-8 text-[14px] mt-2'
      onClick={handleOverrideDropoff}
      type='button'
    >
      Apply to all Trucks
    </Button>
  </>
);
