enum ButtonNamePosthog {
  // AI Suggestion Clicks
  ApptConfirmationSuggestionClick = 'appointment_confirmation_suggestion_click',
  CarrierInfoSuggestionClick = 'carrier_info_suggestion_click',
  CheckCallSuggestionClick = 'check_call_suggestion_click',
  CycleCheckCallSuggestion = 'cycle_check_call_suggestion',
  QuickQuoteSuggestionClick = 'quick_quote_suggestion_click',

  // AI Suggestion Skips
  // SkipApptSchedulingSuggestion = 'skip_appt_scheduling_suggestion', // TODO: Implement PostHog event
  // SkipCarrierInfoSuggestion = 'skip_carrier_info_suggestion',       // TODO: Implement PostHog event
  SkipCheckCallSuggestion = 'skip_check_call_suggestion',

  // Appointment Scheduling
  ConfirmSlotApptScheduling = 'confirm_slot_appt_scheduling',
  FindOpenApptSlots = 'find_open_appt_slots',
  ShowSmartReply = 'show_smart_reply',
  ValidateRetalixPONumbers = 'validate_retalix_po_numbers',
  SubmitRetalixAppt = 'submit_retalix_appointment',

  // Carrier Network
  AddCarrierNetworkQuoteDraft = 'add_carrier_network_quote_draft',
  EmailCarriers = 'email_carriers',

  // Load Building
  BuildLoad = 'build_load',
  ClearForm = 'clear_form',
  LoadBuildingDateInput = 'load_building_date_input',
  LoadBuildingLocationNameVsStreet = 'load_building_location_name_vs_street',
  LoadBuildingSuggestionClick = 'load_building_suggestion_click',
  LoadBuildingTimeInput = 'load_building_time_input',

  // Load Info
  SearchLoad = 'search_load',
  UpdateTMS = 'update_tms',

  // Miscellaneous
  // RefreshCustomers = 'refresh_customers', // TODO: Implement PostHog event
  // RefreshLocations = 'refresh_locations', // TODO: Implement PostHog event
  // RefreshOperators = 'refresh_operators', // TODO: Implement PostHog event

  // Outbox
  DeleteCarrierEmail = 'delete_carrier_email',
  // GoToOutboxTab = 'go_to_outbox', // TODO: Implement PostHog event
  UndoDeleteCarrierEmail = 'undo_delete_carrier_email',
  UpdateCarrierEmail = 'update_carrier_email',

  // Quick Quote
  // AddQuickQuoteDraft = 'add_quick_quote_draft', // TODO: Implement PostHog event
  GetQuickQuote = 'get_quick_quote',

  // Track & Trace
  GoToTrackAndTraceTab = 'go_to_track_and_trace',
  ScheduleCarrierEmails = 'schedule_carrier_emails',
  ScheduleShipperEmails = 'schedule_shipper_emails',
  SubmitCheckCall = 'submit_check_call',
  SubmitException = 'submit_exception',
  SubmitNote = 'submit_note',

  // Truck Lists
  CreatePickupGroupForTruckList = 'create_pickup_group_for_truck_list',
  CreateTruckForTruckList = 'create_truck_for_truck_list',
  CreateTruckList = 'create_truck_list',
  DeleteTruckFromTruckList = 'delete_truck_from_truck_list',
  IngestEmail = 'ingest_email',
  MoveTruckToExistingPickupGroup = 'move_truck_to_existing_pickup_group',
  MoveTruckToNewPickupGroup = 'move_truck_to_new_pickup_group',
  SubmitTruckList = 'submit_truck_list',
  ValidateTruckListCarrier = 'validate_truck_list_carrier',
}

export default ButtonNamePosthog;
