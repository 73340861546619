import { useEffect, useState } from 'react';
import {
  Controller,
  FieldErrors,
  FieldPath,
  UseFormReturn,
} from 'react-hook-form';

import { Checkbox } from 'components/Checkbox';
import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { LoadDateTimeInput } from 'pages/LoadView/LoadInformationTab';
import { NormalizedLoad, TMSLocation } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import {
  GenericCompanySearchableFields,
  locationSearchHandler,
  mapLocationsToAntdOptions,
} from 'utils/loadInfoAndBuilding';

import { LoadBuildingTextInput } from '../McleodLoadBuildingForm';

export function StopForm({
  stop,
  formMethods,
  isLoadingLocations,
  locations,
  handleRefreshLocations,
  setLocations,
}: {
  stop: 'pickup' | 'consignee';
  formMethods: UseFormReturn<NormalizedLoad>;
  isLoadingLocations: boolean;
  locations: Maybe<TMSLocation[]>;
  handleRefreshLocations: () => void;
  setLocations: React.Dispatch<React.SetStateAction<Maybe<TMSLocation[]>>>;
}): JSX.Element {
  const [isEditingLocation] = useState(false);
  // const [isCreatingLocation, setIsCreatingLocation] = useState(false); // placeholder to demo loading state
  // const { toast } = useToast();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  // const isPickup = stop === 'pickup';
  const watchedLocationID = watch(`${stop}.externalTMSID`);
  const watchedLocObj = watch(stop);
  const { tmsIntegrations } = useServiceFeatures();

  {
    /* TODO: Finalize manaul location creation UI/UX */
  }
  // const handleCreateLocation = async () => {
  //   const isValid = await validateNewLocation(watchedLocObj);
  //   if (!isValid) {
  //     return;
  //   }

  //   setIsCreatingLocation(true);
  //   const res = await createLocation({
  //     tmsID: tmsIntegrations[0]?.id,
  //     location: {
  //       ...(getValues(`${stop}`) as TMSLocation),
  //       isShipper: isPickup,
  //       isConsignee: !isPickup,
  //     },
  //   });

  //   if (res.isOk()) {
  //     setIsEditingLocation(false);
  //     toast({
  //       description: `Location ${res.value.location.externalTMSID} created in McLeod`,
  //       variant: 'success',
  //     });
  //     setLocations((prevLocations) =>
  //       injectSelectedObject(res.value.location, prevLocations ?? [])
  //     );
  //     for (const [key, value] of Object.entries(res.value.location)) {
  //       setValue(`${stop}.${key}` as FieldPath<NormalizedLoad>, value, {
  //         shouldDirty: true,
  //       });
  //     }
  //   } else {
  //     toast({
  //       description: res.error.message,
  //       variant: 'destructive',
  //     });
  //   }

  //   setIsCreatingLocation(false);
  // };

  // const handleCancelEditingLocation = () => {
  //   setValue(`${stop}.externalTMSID`, '');
  //   setValue(`${stop}.name`, '');
  //   setValue(`${stop}.addressLine1`, '');
  //   setValue(`${stop}.addressLine2`, '');
  //   setValue(`${stop}.city`, '');
  //   setValue(`${stop}.state`, '');
  //   setValue(`${stop}.zipCode`, '');
  //   setValue(`${stop}.apptRequired`, false);

  //   clearErrors(`${stop}`);
  //   setIsEditingLocation(false);
  // };

  // const handleEditlocation = () => {
  //   setIsEditingLocation(true);
  //   setValue(`${stop}.externalTMSID`, '');
  // };

  const handleLocationSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return locationSearchHandler({
      tmsID: tmsIntegrations?.[0]?.id,
      locations,
      setLocations,
      field,
      value,
    });
  };
  // const validateNewLocation = async (
  //   data: NormalizedPickup | NormalizedDropoff
  // ): Promise<boolean> => {
  //   const tmsLocationKeys: Array<keyof TMSLocation> = [
  //     'name',
  //     'addressLine1',
  //     'addressLine2',
  //     'city',
  //     'state',
  //     'zipCode',
  //     'apptRequired',
  //   ];

  //   // array.forEach does not handle async/await properly
  //   const results = await Promise.all(
  //     tmsLocationKeys.map(async (key) => {
  //       if (key in data) {
  //         const valid = await trigger(
  //           `${stop}.${key}` as FieldPath<NormalizedLoad>
  //         );
  //         return valid;
  //       }
  //       return true; // If the key is not in data, consider it valid.
  //     })
  //   );

  //   const isSectionValid = results.every((result) => result);
  //   return isSectionValid;
  // };

  useEffect(() => {
    if (watchedLocationID) {
      const selectedLoc = locations?.find(
        (loc) => loc.externalTMSID === watchedLocationID
      );
      if (!selectedLoc) {
        return;
      }

      Object.entries(selectedLoc).forEach(([key, value]) => {
        setValue(`${stop}.${key}` as FieldPath<NormalizedLoad>, value, {
          shouldDirty: true, // this ensures the AI-label is handled correctly
        });
      });
    }
  }, [watchedLocationID]);

  useEffect(() => {
    if (watchedLocationID) {
      // Remove ID if address is write-in
      const selectedLoc = locations?.find(
        (loc) => loc.externalTMSID === watchedLocationID
      );
      if (!selectedLoc) {
        return;
      }

      if (
        (selectedLoc?.name &&
          watchedLocObj?.name.toLowerCase() !==
            selectedLoc?.name.toLowerCase()) ||
        (selectedLoc?.addressLine1 &&
          watchedLocObj?.addressLine1.toLowerCase() !==
            selectedLoc?.addressLine1.toLowerCase()) ||
        (selectedLoc?.addressLine2 &&
          watchedLocObj?.addressLine2?.toLowerCase() !==
            selectedLoc?.addressLine2?.toLowerCase()) ||
        (selectedLoc?.city &&
          watchedLocObj?.city?.toLowerCase() !==
            selectedLoc?.city?.toLowerCase()) ||
        (selectedLoc?.state &&
          watchedLocObj?.state?.toLowerCase() !==
            selectedLoc?.state?.toLowerCase()) ||
        (selectedLoc?.zipCode &&
          watchedLocObj?.zipCode?.toLowerCase() !==
            selectedLoc?.zipCode?.toLowerCase())
      ) {
        setValue(`${stop}.externalTMSID` as FieldPath<NormalizedLoad>, '');
      }
    }
  }, [
    watchedLocationID,
    watchedLocObj?.name,
    watchedLocObj?.addressLine1,
    watchedLocObj?.addressLine2,
    watchedLocObj?.city,
    watchedLocObj?.state,
    watchedLocObj?.zipCode,
  ]);

  return (
    <>
      <RHFDebounceSelect
        // required={isPickup && !isEditingLocation}
        required={false}
        disabled={isEditingLocation}
        name={`${stop}.externalTMSID`}
        label='ID'
        control={control}
        errors={errors}
        data={locations}
        isLoading={isLoadingLocations}
        refreshHandler={handleRefreshLocations}
        fetchOptions={handleLocationSearch}
        mapOptions={mapLocationsToAntdOptions}
      />

      {isEditingLocation && <hr className='my-2' />}

      <LoadBuildingTextInput
        name={`${stop}.name`}
        label='Name'
        // The Mcleod location objects *probably* have all the address fields we required, but in case it doesn't conform to our expectations,
        // we don't require it when the user selects an existing location so the form isn't blocked.
        // This happened before where we required zipcodes to be 5 digits, but the existing TMS location had 6 digits and the input was read-only
        // so the user couldn't submit the form.
        options={
          !watchedLocationID
            ? { required: 'Required if existing ID not selected' }
            : isEditingLocation
              ? { required: 'Required' }
              : {}
        }
      />

      <LoadBuildingTextInput
        name={`${stop}.addressLine1`}
        label='Address Line 1'
        options={
          !watchedLocationID
            ? { required: 'Required if existing ID not selected' }
            : isEditingLocation
              ? { required: 'Required' }
              : {}
        }
      />

      <LoadBuildingTextInput
        name={`${stop}.addressLine2`}
        label='Address Line 2'
      />

      <div className='grid grid-cols-2 w-full m-0 gap-2'>
        <LoadBuildingTextInput
          name={`${stop}.city`}
          label='City'
          options={
            !watchedLocationID
              ? { required: 'Required if existing ID not selected' }
              : isEditingLocation
                ? { required: 'Required' }
                : {}
          }
        />

        <LoadBuildingTextInput
          name={`${stop}.state`}
          label='State'
          options={
            !watchedLocationID
              ? { required: 'Required if existing ID not selected' }
              : isEditingLocation
                ? { required: 'Required' }
                : {}
          }
        />
      </div>

      {/* <div className='grid items-end grid-cols-2 w-full m-0 gap-2'> */}
      <LoadBuildingTextInput
        name={`${stop}.zipCode`}
        label='Zip Code'
        placeholder='12345'
        options={
          !watchedLocationID
            ? { required: 'Required if existing ID not selected' }
            : isEditingLocation
              ? { required: 'Required' }
              : {}
        }
      />

      {/* TODO: Finalize manaul location creation UI/UX */}
      {/* {isEditingLocation ? (
          <div className='flex flex-col gap-2'>
            <AntdButton
              type='link'
              className='h-0 text-grayscale-content-description'
              onClick={handleCancelEditingLocation}
            >
              Cancel
            </AntdButton>
            <Button size='sm' type='button' onClick={handleCreateLocation}>
              {isCreatingLocation ? <ButtonLoader /> : 'Submit'}
            </Button>
          </div>
        ) : (
          <Button size='sm' type='button' onClick={handleEditlocation}>
            Add Location
          </Button>
        )} */}
      {/* </div> */}
      {/* Not supported rn. Buggy duplication behavior on update */}
      {/* <LoadBuildingTextInput
        name={`${stop}.refNumber`}
        label='Ref #'
        placeholder='Comma-separated list (e.g. 123,456,789)'
      /> */}

      {(watchedLocObj?.apptRequired ||
        isEditingLocation ||
        !watchedLocObj.externalTMSID) && (
        <Controller
          name={`${stop}.apptRequired`}
          control={control}
          render={({ field }) => (
            <div className='flex items-center space-x-2'>
              <Checkbox
                onCheckedChange={(checked) => {
                  field.onChange(checked);
                }}
                checked={field.value || undefined}
              />
              <label
                htmlFor={`${stop}.apptRequired`}
                className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-grayscale-content-input'
              >
                {'Appointment Required?'}
              </label>
            </div>
          )}
        />
      )}

      {isEditingLocation && <hr className='my-2' />}

      <LoadDateTimeInput
        name={`${stop}.apptStartTime` as FieldPath<NormalizedLoad>}
        label='Appointment Start Time'
        required
      />

      <LoadDateTimeInput
        name={`${stop}.apptEndTime` as FieldPath<NormalizedLoad>}
        label='Appointment End Time'
      />
    </>
  );
}

export function hasNoErrorsInSubsection(
  subsectionPath: string,
  formErrors: FieldErrors
): boolean {
  const subsectionErrors = Object.entries(formErrors).filter(([key]) =>
    key.startsWith(subsectionPath)
  );

  return subsectionErrors.length === 0;
}
