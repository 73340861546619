import { useContext, useEffect, useMemo, useState } from 'react';
import {
  FieldPath,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { Accordion } from '@radix-ui/react-accordion';
import { get, set } from 'lodash';
import {
  BoxIcon,
  Building2,
  CircleDollarSignIcon,
  CircleUserRound,
  Info,
  WarehouseIcon,
  Weight,
} from 'lucide-react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { Button } from 'components/Button';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { applyLoadSuggestion } from 'lib/api/applyLoadSuggestion';
import { buildLoad } from 'lib/api/buildLoad';
import { createLoad } from 'lib/api/createLoad';
import { getCustomers } from 'lib/api/getCustomers';
import { getLocations } from 'lib/api/getLocations';
import { FormStorageService } from 'lib/services/FormStorage/service';
import { LoadSectionAccordionItem } from 'pages/LoadView/LoadInformationTab';
import { CustomerSectionForm } from 'pages/QuoteView/LoadBuilding/McleodSectionForms/Customer';
import { OperatorSectionForm } from 'pages/QuoteView/LoadBuilding/McleodSectionForms/Operator';
import { RatesForm } from 'pages/QuoteView/LoadBuilding/McleodSectionForms/Rates';
import { SpecificationsForm } from 'pages/QuoteView/LoadBuilding/McleodSectionForms/Specifications';
import {
  StopForm,
  hasNoErrorsInSubsection,
} from 'pages/QuoteView/LoadBuilding/McleodSectionForms/Stop';
import { Email } from 'types/Email';
import {
  Load,
  NormalizedLoad,
  RateData,
  Specifications,
  TMSCustomer,
  TMSLocation,
  createInitLoad,
  createInitRateData,
  createInitSpecs,
  normalizeLoad,
} from 'types/Load';
import { Maybe, Undef } from 'types/UtilityTypes';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { TMS } from 'types/enums/Integrations';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { SuggestedLoad } from 'types/suggestions/LoadBuildingSuggestions';
import { concatAddress } from 'utils/formatStrings';
import { objectHasTruthyValues } from 'utils/isValidObject';
import { injectSelectedObject } from 'utils/loadInfoAndBuilding';
import { denormalizeDatesForTMSForm } from 'utils/parseDatesForTMSForm';

enum AvailableTabs {
  customer = 'customer',
  billTo = 'billTo',
  specifications = 'specifications',
  pickup = 'pickup',
  consignee = 'consignee',
  carrier = 'carrier',
  operator = 'operator',
  rates = 'rates',
}

export const devDisableRequiredFields = !isProd() && false;

type LoadBuildingTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<NormalizedLoad> };

export const LoadBuildingTextInput = (props: LoadBuildingTextInputProps) => (
  <RHFTextInput {...props} />
);

const initLoadBuildingForm = (tmsName: string): NormalizedLoad =>
  normalizeLoad(tmsName, {
    ...createInitLoad(),
    mode: 'Truckload', // Required as field is read-only
    specifications: { ...createInitSpecs(), transportType: 'Van (DAT)' },
  });

// TODO: Generalize form across all TMS integrations and use LoadAttributes, similar to LoadInformation tab
export default function McleodEnterpriseLoadBuildingForm() {
  const { serviceID, tmsIntegrations } = useServiceFeatures();
  const { toast } = useToast();
  const tmsName = TMS.McleodEnterprise;
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>(
    Object.values(AvailableTabs)
  );
  const [isLoadBuildingSuggestionClicked, setIsLoadBuildingSuggestionClicked] =
    useState(false);
  const [showAIHints, setShowAIHints] = useState(false);

  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
  const [customers, setCustomers] = useState<Maybe<TMSCustomer[]>>(null);
  const [locations, setLocations] = useState<Maybe<TMSLocation[]>>(null);
  // NOTE: Mcleod requires a location ID for pickup, but not dropoff.
  // Thus, we create a new location only if pickup ID is missing.
  const [createNewLocForPickup, setCreateNewLocForPickup] = useState(false);
  const [builtLoad, setBuiltLoad] = useState<Maybe<Load>>(null);

  const {
    currentState: { clickedSuggestion, threadId, goToSuggestionInCarousel },
    setCurrentState,
  } = useContext(SidebarStateContext);

  const fetchCustomers = async () => {
    setIsLoadingCustomers(true);

    const res = await getCustomers(tmsIntegrations[0]?.id);
    if (res.isOk()) {
      setCustomers(res.value.customerList);
    } else {
      toast({
        description: 'Error while fetching customer list.',
        variant: 'destructive',
      });
    }
    setIsLoadingCustomers(false);
  };

  // Optimization: Defined here instead of in the component to avoid multiple API lookups
  const fetchLocations = async () => {
    setIsLoadingLocations(true);

    const res = await getLocations(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setLocations(res.value.locationList);
    } else {
      toast({
        description: 'Error while fetching location list.',
        variant: 'destructive',
      });
    }
    setIsLoadingLocations(false);
  };

  const handleRefreshLocations = async () => {
    setIsLoadingLocations(true);

    const res = await getLocations(tmsIntegrations?.[0]?.id, true);
    if (res.isOk()) {
      setLocations(res.value.locationList);
      toast({
        description: 'Successfully refreshed location list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing location list.',
        variant: 'destructive',
      });
    }
    setIsLoadingLocations(false);
  };

  useEffect(() => {
    fetchLocations();
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.LoadBuilding
    ) {
      setIsLoadBuildingSuggestionClicked(true);
    } else {
      setIsLoadBuildingSuggestionClicked(false);
    }
  }, [clickedSuggestion]);
  const memoizedDefaultValues: NormalizedLoad = useMemo(() => {
    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.LoadBuilding
    ) {
      const collectionMethods = ['prepaid', 'collect', 'third-party', 'cod'];
      const castedClickedSuggestion =
        clickedSuggestion.suggested as SuggestedLoad;
      const transportType =
        castedClickedSuggestion.specifications?.transportType?.toLowerCase();

      const suggestedFields = {
        ...castedClickedSuggestion,
        mode: 'Truckload',
        specifications: {
          // NOTE: Important to initialize ALL fields or RHF can't manipulate the form, particularly when loading from cache
          ...createInitSpecs(),
          ...castedClickedSuggestion.specifications,
          transportType:
            transportType === 'reefer'
              ? 'Reefer (DAT)'
              : transportType === 'flatbed'
                ? 'Flatbed (DAT)'
                : 'Van (DAT)',
        } as Specifications,
        rateData: {
          // NOTE: Important to initialize ALL fields or RHF can't manipulate the form, particularly when loading from cache
          ...createInitRateData(),
          ...castedClickedSuggestion.rateData,
          collectionMethod: collectionMethods.includes(
            castedClickedSuggestion.rateData?.collectionMethod?.toLowerCase()
          )
            ? castedClickedSuggestion.rateData.collectionMethod
            : null,
        } as RateData,
      };
      setShowAIHints(true);

      const res = {
        ...normalizeLoad(tmsName, createInitLoad()),
        ...suggestedFields,
        mode: 'Truckload',
      } as NormalizedLoad;

      return res;
    }

    if (builtLoad) {
      return normalizeLoad(tmsName, builtLoad);
    }
    setShowAIHints(false);

    return initLoadBuildingForm(tmsName);
  }, [threadId, clickedSuggestion, buildLoad]);

  useEffect(() => {
    if (!memoizedDefaultValues) {
      return;
    }
    formMethods.reset(memoizedDefaultValues, {
      keepDefaultValues: false,
      keepTouched: false,
      keepDirtyValues: false,
      keepDirty: false,
    });

    // After resetting the form, ensure that options arrays include the necessary data
    const customerID = memoizedDefaultValues.customer?.externalTMSID;
    if (customerID) {
      setCustomers((prevCustomers) =>
        injectSelectedObject(
          memoizedDefaultValues.customer,
          prevCustomers ?? []
        )
      );
    }

    const addtlLocs: TMSLocation[] = [];
    if (memoizedDefaultValues.pickup?.externalTMSID) {
      addtlLocs.push(memoizedDefaultValues.pickup);
    }

    if (memoizedDefaultValues.consignee?.externalTMSID) {
      addtlLocs.push(memoizedDefaultValues.consignee);
    }

    setLocations((prevLocations) => {
      let updatedLocations = prevLocations ?? [];

      addtlLocs.forEach((loc) => {
        updatedLocations = injectSelectedObject(loc, updatedLocations);
      });

      return updatedLocations;
    });

    // Show validation errors (the setTimeout ensures validation happens after the form state stabilizes)
    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.LoadBuilding
    ) {
      setTimeout(() => {
        trigger();
      }, 200);
    }
  }, [memoizedDefaultValues]);

  const formMethods = useForm<NormalizedLoad>({
    defaultValues: memoizedDefaultValues,
  });

  const {
    handleSubmit,
    formState,
    watch,
    trigger,
    formState: { errors },
  } = formMethods;

  const watchedPickup = watch('pickup');

  function handleClearForm() {
    setShowAIHints(false);
    FormStorageService.clearFormState(`${tmsName}_${threadId}`);
    formMethods.reset(initLoadBuildingForm(tmsName), {
      keepDefaultValues: false,
      keepDirty: false,
      keepDirtyValues: false,
      keepTouched: false,
    });
  }

  function flattenValues(
    values: any,
    parentKey = '',
    result: string[] = []
  ): string[] {
    for (const key in values) {
      const value = values[key];
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (value && typeof value === 'object' && !Array.isArray(value)) {
        flattenValues(value, newKey, result);
      } else {
        result.push(newKey);
      }
    }
    return result;
  }

  useEffect(() => {
    if (
      !watchedPickup?.externalTMSID &&
      hasNoErrorsInSubsection('pickup', errors) &&
      objectHasTruthyValues(watchedPickup, [
        'name',
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'zipCode',
        'country',
      ])
    ) {
      setCreateNewLocForPickup(true);
    } else {
      setCreateNewLocForPickup(false);
    }
  }, [
    watchedPickup?.externalTMSID,
    watchedPickup?.name,
    watchedPickup?.addressLine1,
    watchedPickup?.addressLine2,
    watchedPickup?.city,
    watchedPickup?.state,
    watchedPickup?.zipCode,
    errors['pickup'],
  ]);

  useEffect(() => {
    const savedState = FormStorageService.getFormState<
      NormalizedLoad,
      NormalizedLoad
    >(`${tmsName}_${threadId}`);

    if (
      savedState &&
      savedState.threadID === threadId &&
      !(
        clickedSuggestion &&
        clickedSuggestion.pipeline == SuggestionPipelines.LoadBuilding
      )
    ) {
      setShowAIHints(true);
      goToSuggestionInCarousel({
        suggestionID: savedState.clickedSuggestion?.id,
      });

      // Reset the form to its initial default values or an empty state
      // Removed this line because it was causing a bug where resetField() wasn't working as expected
      // formMethods.reset({});

      // Flatten savedState.values to get all field names
      const fieldNames = flattenValues(savedState.values);
      const cleanFields = normalizeLoad(tmsName, createInitLoad());

      // For each field, set the value and mark as dirty if necessary - this ensures the AI-label is handled correctly
      fieldNames.forEach((fieldName) => {
        const isDirty: Undef<boolean> = get(savedState.dirtyFields, fieldName);
        if (isDirty === undefined || !isDirty) {
          set(cleanFields, fieldName, get(savedState.values, fieldName));
        }
      });

      formMethods.reset(cleanFields as NormalizedLoad, {
        // Add explicit reset options to ensure a clean slate
        keepDefaultValues: false,
        keepTouched: false,
        keepDirtyValues: false,
        keepDirty: false,
      });

      /*
        NOTE: Using setTimeout() to ensure all RHF internal state updates have settled before custom logic runs
        This was to address an issue where, if the user edited the form after the first saved state,
        upon return, it'd revert to the first saved state rather than the most recent one
      */
      setTimeout(() => {
        fieldNames.forEach((fieldName) => {
          const isDirty: Undef<boolean> = get(
            savedState.dirtyFields,
            fieldName
          );

          if (isDirty) {
            formMethods.setValue(
              fieldName as FieldPath<NormalizedLoad>,
              get(savedState.values, fieldName),
              { shouldDirty: isDirty, shouldTouch: true }
            );
          }
        });

        // After resetting the form, ensure that options arrays include the necessary data
        {
          const customerID = savedState.values.customer?.externalTMSID;
          if (customerID) {
            setCustomers((prevCustomers) =>
              injectSelectedObject(
                savedState.values.customer,
                prevCustomers ?? []
              )
            );
          }

          const addtlLocs: TMSLocation[] = [];
          if (savedState.values.pickup?.externalTMSID) {
            addtlLocs.push(savedState.values.pickup);
          }

          if (savedState.values.consignee?.externalTMSID) {
            addtlLocs.push(savedState.values.consignee);
          }

          setLocations((prevLocations) => {
            let updatedLocations = prevLocations ?? [];

            addtlLocs.forEach((loc) => {
              updatedLocations = injectSelectedObject(loc, updatedLocations);
            });

            return updatedLocations;
          });
        }
      }, 100);
    }

    // Wait for RHF to finish rendering before caching initial form state
    setTimeout(() => {
      if (!savedState || threadId === savedState.threadID) {
        FormStorageService.saveFormState(`${tmsName}_${threadId}`, {
          threadID: threadId,
          clickedSuggestion: isLoadBuildingSuggestionClicked
            ? clickedSuggestion
            : null,
          values: formMethods.getValues(),
          dirtyFields: formMethods.formState.dirtyFields,
        });
      }
    }, 100);

    // Subscribe to form to cache state on changes
    const subscription = formMethods.watch((value) => {
      if (!savedState || threadId === savedState.threadID) {
        FormStorageService.saveFormState(`${tmsName}_${threadId}`, {
          threadID: threadId,
          values: value,
          clickedSuggestion: isLoadBuildingSuggestionClicked
            ? clickedSuggestion
            : null,
          dirtyFields: formMethods.formState.dirtyFields,
        });
      }
    });

    // Unsubscribe from form state when unmounting
    return () => {
      subscription.unsubscribe();
    };
  }, [memoizedDefaultValues]);

  const onSubmit: SubmitHandler<NormalizedLoad> = async (data) => {
    setIsLoading(true);

    const reqData = {
      load: {
        ...data,
        tmsID: tmsIntegrations[0]?.id,
        operator: data.operator,
        mode: data.mode,
        customer: denormalizeDatesForTMSForm(tmsName, data.customer),
        billTo: denormalizeDatesForTMSForm(tmsName, data.billTo),
        specifications: data.specifications,
        pickup: {
          ...denormalizeDatesForTMSForm(tmsName, data.pickup),
        },
        consignee: {
          ...denormalizeDatesForTMSForm(tmsName, data.consignee),
        },
        carrier: {
          ...denormalizeDatesForTMSForm(tmsName, data.carrier),
          rateConfirmationSent: data.carrier?.rateConfirmationSent
            ? true
            : false,
        },
        rateData: denormalizeDatesForTMSForm(tmsName, data.rateData),
      } as unknown as Load,
    };

    const res = await createLoad(reqData);

    if (res.isOk()) {
      setBuiltLoad(res.value.load);
      setCreateNewLocForPickup(false);
      FormStorageService.clearFormState(`${tmsName}_${threadId}`);
      handleAcceptedSuggestion(res.value.load as SuggestedLoad); // Pass in the resulting load so we also capture the generated ID
      toast({
        title: res.value.message,
        description:
          'Load ID: ' +
          (res.value.load.externalTMSID ?? res.value.load.externalTMSID),
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setIsLoading(false);
  };

  const onInvalid: SubmitErrorHandler<Email> = async () => {
    toast({
      description: 'Some fields are invalid.',
      variant: 'destructive',
    });
  };

  const handleAcceptedSuggestion = async (data: SuggestedLoad) => {
    if (clickedSuggestion) {
      await applyLoadSuggestion(clickedSuggestion.id, {
        newLoadSuggestion: data,
      });

      setCurrentState((prevState) => {
        const filteredList = prevState.curSuggestionList.filter(
          ({ id }) => id !== clickedSuggestion.id
        );
        return {
          ...prevState,
          clickedSuggestion: null,
          curSuggestionList: filteredList,
        };
      });
    }
  };

  return (
    <div className='mb-5'>
      <ExtendedFormProvider aiDefaultValues={showAIHints} aiIconOnly={true}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <Accordion
              type='multiple'
              value={activeTabs}
              onValueChange={setActiveTabs}
            >
              <LoadSectionAccordionItem
                label='Customer'
                icon={<Building2 className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.customer}
                activeTabs={activeTabs}
              >
                <CustomerSectionForm
                  formMethods={formMethods}
                  customers={customers}
                  setCustomers={setCustomers}
                  isLoadingCustomers={isLoadingCustomers}
                  setIsLoadingCustomers={setIsLoadingCustomers}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Specs'
                icon={<Weight className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.specifications}
                activeTabs={activeTabs}
              >
                <SpecificationsForm formMethods={formMethods} />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Rates'
                icon={
                  <CircleDollarSignIcon className='h-6 w-6' strokeWidth={1} />
                }
                name={AvailableTabs.rates}
                activeTabs={activeTabs}
              >
                <RatesForm
                  formMethods={formMethods}
                  showCarrierFields={false}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Pickup'
                icon={<BoxIcon className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.pickup}
                activeTabs={activeTabs}
              >
                <StopForm
                  stop='pickup'
                  formMethods={formMethods}
                  isLoadingLocations={isLoadingLocations}
                  locations={locations}
                  handleRefreshLocations={handleRefreshLocations}
                  setLocations={setLocations}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Consignee'
                icon={<WarehouseIcon className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.consignee}
                activeTabs={activeTabs}
              >
                <StopForm
                  stop='consignee'
                  formMethods={formMethods}
                  isLoadingLocations={isLoadingLocations}
                  locations={locations}
                  handleRefreshLocations={handleRefreshLocations}
                  setLocations={setLocations}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Operator'
                icon={<CircleUserRound className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.operator}
                activeTabs={activeTabs}
              >
                <OperatorSectionForm formMethods={formMethods} />
              </LoadSectionAccordionItem>
            </Accordion>

            <section className='w-full mt-4'>
              {/* NOTE: Mcleod requires a location ID for pickup, but not dropoff.
            Thus, we create a new location only if pickup ID is missing. */}
              {createNewLocForPickup && (
                <span className='flex flex-row bg-violet-blue-bg rounded-lg space-x-1 py-1 mb-4'>
                  <div>
                    <Info
                      className='h-4 w-4 pl-1'
                      color='#969696'
                      strokeWidth={3}
                    />
                  </div>
                  <span className='text-xs text-grayscale-content-description font-medium'>
                    {`Upon submission, Drumkit will also create a new location ID for pickup (${concatAddress(watchedPickup)}).`}
                  </span>
                </span>
              )}

              <Button
                buttonName={ButtonName.BuildLoad}
                buttonNamePosthog={ButtonNamePosthog.BuildLoad}
                type='submit'
                className='w-full'
                disabled={isLoading}
                logProperties={{ serviceID }}
              >
                {isLoading ? <ButtonLoader /> : ButtonName.BuildLoad}
              </Button>

              {formState.isDirty && (
                <div className='flex flex-row justify-center align-center'>
                  <Button
                    buttonName={ButtonName.ClearForm}
                    buttonNamePosthog={ButtonNamePosthog.ClearForm}
                    type='button'
                    className='w-50% mt-4 h-8 text-sm text-grayscale-content-input'
                    disabled={isLoading}
                    variant='outline'
                    onClick={handleClearForm}
                    logProperties={{ serviceID }}
                  >
                    {ButtonName.ClearForm}
                  </Button>
                </div>
              )}
              <div className='flex-col justify-center'>
                {(builtLoad?.freightTrackingID || builtLoad?.externalTMSID) && (
                  <>
                    <div className='whitespace-pre-wrap my-3 rounded py-3 text-grayscale-content-label px-4 bg-green-bg'>
                      <p className='mb-2'>Load Created 🎉</p>
                      <p className='mb-2 text-sm'>
                        <b className='text-[14px]'>Load ID #: </b>
                        {builtLoad?.freightTrackingID ||
                          builtLoad?.externalTMSID}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </section>
          </form>
        </FormProvider>
      </ExtendedFormProvider>
    </div>
  );
}

export enum RateType {
  Flat = 'flat',
  Distance = 'distance',
  CWT = 'cwt',
  Tons = 'tons',
}
