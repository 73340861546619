import * as React from 'react';

export default function TruckstopLogo(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 800 800'
      xmlSpace='preserve'
      {...props}
    >
      <g>
        <g>
          <path
            d='M189.2,481.7c-37.3-48-56.6-105.7-55.7-167c1.9-134.6,108.2-249.4,242-261.4c8.2-0.7,16.4-1.1,24.5-1.1
            c147,0,266.5,119.6,266.5,266.5c0,59.7-19.4,116.2-56,163.3L400,747.9L189.2,481.7z'
          />
          <path
            d='M297.8,155.5h204.3c32.9,20.7,59.1,51,74.7,87H223.1C238.7,206.5,264.9,176.2,297.8,155.5z M241.4,387.9
            c-19.7,3.1-36.4-13.7-33.2-33.3c2.1-12.9,13.4-23.1,26.5-24c17.6-1.2,32,13.3,30.8,30.9C264.6,374.6,254.3,385.8,241.4,387.9z
             M339.2,552.2l-30.4-38.4V288.5h30.4V552.2z M415.2,609.7L400,628.9l-15.2-19.2V288.5h30.4V609.7z M491.2,513.8l-30.4,38.4V288.5
            h30.4V513.8z M568.1,387.9c-19.7,3.1-36.4-13.7-33.2-33.3c2.1-12.9,13.4-23.1,26.5-24c17.6-1.2,32,13.3,30.8,30.9
            C591.2,374.6,581,385.8,568.1,387.9z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
}
