import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore auth is in the parent dir
import AuthService from '@auth/AuthService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { CheckCall } from 'types/CheckCall';
import { Maybe } from 'types/UtilityTypes';
import { ApiError } from 'types/api/ApiError';
import { TMS } from 'types/enums/Integrations';
import {
  CheckCallSuggestion,
  SuggestionStatus,
} from 'types/suggestions/LoadSuggestions';
import captureException from 'utils/captureException';

type PostCheckCallResponse = {
  history: CheckCall[];
  message: string;
};

export type SubmitCheckCallProps = Omit<
  CheckCall,
  | 'dateTime'
  | 'dateTimeWithoutTimezone'
  | 'endDateTimeWithoutTimezone'
  | 'capturedDateTime'
> & {
  tmsName: string;
  dateTime: Date;
  // In "America/New_York" IANA format
  // Some TMS's require you to specify the timezone to display the timestamp in.
  // For now, we hard-code it to be the user's locale rather than geocode the city & state
  userTimezone: string;
  outDatetimeWithoutTimezone: Date;
  nextStopETAWithoutTimezone: Date;
  suggestion: Maybe<CheckCallSuggestion>;
};

export async function submitCheckCall(
  props: SubmitCheckCallProps
): Promise<Result<PostCheckCallResponse, ApiError>> {
  let dateISO = props.dateTime.toISOString();
  let outDatetimeISO = props.outDatetimeWithoutTimezone?.toISOString();
  let nextStopETAISO = props.nextStopETAWithoutTimezone?.toISOString();

  const isTZAgnosticTMS =
    props.tmsName === TMS.Aljex || props.tmsName === TMS.Relay;
  if (isTZAgnosticTMS) {
    // Use UTC as placeholder TZ; user is expected to input in driver's locale for Aljex
    // This logic must be done in the front-end because backend doesn't know user's original locale for calculating the offset
    dateISO = dayjs(props.dateTime).utcOffset(0, true).toISOString();

    if (props.outDatetimeWithoutTimezone) {
      outDatetimeISO = dayjs(props.outDatetimeWithoutTimezone)
        ?.utcOffset(0, true)
        .toISOString();
    }

    if (props.nextStopETAWithoutTimezone) {
      nextStopETAISO = dayjs(props.nextStopETAWithoutTimezone)
        ?.utcOffset(0, true)
        .toISOString();
    }
  }

  // NFI request: associate check call with author
  const user = await AuthService.getCurrentUser();
  const finalNotes = props.notes + ' - ' + user?.email.split('@')[0];

  const requestBody = {
    checkCall: {
      ...props,
      dateTime: isTZAgnosticTMS ? new Date(0) : dateISO,
      dateTimeWithoutTimezone: isTZAgnosticTMS ? dateISO : new Date(0),
      endDateTimeWithoutTimezone: outDatetimeISO,
      nextStopETAWithoutTimezone: nextStopETAISO,
      capturedDateTime: '',
      notes: finalNotes,
    } as CheckCall,
  } as { [key: string]: any }; // allow adding properties dynamically to const

  if (props.suggestion) {
    const isCityApplied =
      props.city === props.suggestion.suggested.checkCallChanges.city;
    const isStateApplied =
      props.state === props.suggestion.suggested.checkCallChanges.state;
    const isStatusApplied =
      props.status === props.suggestion.suggested.checkCallChanges.status;

    if (isCityApplied || isStateApplied || isStatusApplied) {
      requestBody['suggestionID'] = props.suggestion.id;
      requestBody['suggestionStatus'] = SuggestionStatus.Accepted;
    } else {
      requestBody['suggestionID'] = props.suggestion.id;
      requestBody['suggestionStatus'] = SuggestionStatus.Rejected;
    }
  }

  try {
    const resp = await axios.post<PostCheckCallResponse>(
      `/load/${props.loadID}/checkCall`,
      requestBody
    );

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'submitCheckCall' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to submit check call' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data?.message || 'Failed to submit check call',
    });
  }
}
