// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import _ from 'lodash';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { CartesianGrid, Line, LineChart, YAxis } from 'recharts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore utils is in the parent dir
} from './Card';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore utils is in the parent dir
} from './Chart';

interface ChartDataItem {
  week: string;
  averageRate: number;
  lowestRate: number;
  quotes: number;
  lowestCarrierName: string;
}

interface GenericLineChartProps {
  data: ChartDataItem[];
  title: string;
  description: string;
  chartConfig: ChartConfig;
  yAxisDomainMax: number;
  yAxisDomainMin?: number;
  thirdTooltipLabel?: string;
  yAxisWidth?: number;
  dataKeys: string[];
}

// Only non-generic param here is data
export function GenericLineChart({
  data,
  title,
  description,
  chartConfig,
  yAxisDomainMax,
  yAxisDomainMin,
  thirdTooltipLabel,
  yAxisWidth,
  dataKeys,
}: GenericLineChartProps) {
  return (
    <Card className='bg-transparent border-none shadow-none'>
      <CardHeader className='p-0 pb-3'>
        <CardTitle className='text-md text-grayscale-content-label font-semibold'>
          {title}
        </CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className='p-0'>
        <ChartContainer config={chartConfig}>
          <LineChart accessibilityLayer data={data}>
            <CartesianGrid vertical={false} />
            <YAxis
              domain={[yAxisDomainMin || 0, yAxisDomainMax]}
              tickFormatter={(value: number) => `$${value}`}
              tickLine={false}
              width={yAxisWidth}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  hideLabel
                  className='w-[180px]'
                  formatter={(
                    value: any,
                    name: any,
                    item: Payload<any, any>,
                    index: number
                  ) => {
                    if (item.payload.quotes === 0) {
                      return (
                        <>
                          {index === 0 && (
                            <div className='flex flex-col items-center w-full gap-2'>
                              <div className='w-full'>{item.payload.week}</div>
                              <div className='italic text-[11px] text-grayscale-content-description'>
                                No Data Available
                              </div>
                            </div>
                          )}
                          {index === 1 && null}
                        </>
                      );
                    }

                    return (
                      <>
                        {index === 0 && (
                          <div className='w-full'>{item.payload.week}</div>
                        )}
                        <div
                          className='h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]'
                          style={
                            {
                              '--color-bg': `var(--color-${name})`,
                            } as React.CSSProperties
                          }
                        />
                        {chartConfig[name as keyof typeof chartConfig]?.label ||
                          name}
                        <div className='ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground'>
                          ${value.toFixed(2)}
                        </div>
                        {index === 1 && (
                          <>
                            <div className='ml-[17px] flex items-start mt-[-6px] italic text-[11px] text-grayscale-content-description'>
                              {_.startCase(
                                _.toLower(
                                  String(item.payload.lowestCarrierName)
                                )
                              )}
                            </div>
                            <div className='flex basis-full items-center border-t pt-1.5 text-xs font-medium text-foreground'>
                              {thirdTooltipLabel}
                              <div className='ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground'>
                                {item.payload.quotes}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    );
                  }}
                />
              }
              cursor={false}
              defaultIndex={1}
            />
            <Line
              dataKey={dataKeys[0]}
              type='monotone'
              stroke={chartConfig[dataKeys[0]].color}
              strokeWidth={2}
              dot={true}
            />
            <Line
              dataKey={dataKeys[1]}
              type='monotone'
              stroke={chartConfig[dataKeys[1]].color}
              strokeWidth={2}
              dot={true}
            />
            <ChartLegend content={<ChartLegendContent />} />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
