import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Info, MailIcon } from 'lucide-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import { MiniLoadInfo } from 'pages/LoadView/AppointmentScheduling/MiniLoadInfo';
import { NormalizedLoad } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';
import Pageview from 'types/enums/Pageview';

import { AppointmentEditor } from './AppointmentScheduling/AppointmentTab';

dayjs.extend(utc);
dayjs.extend(timezone);

type AppointmentSchedulingSectionProps = {
  normalizedLoad: NormalizedLoad;
  loadPickupWarehouse: Maybe<Warehouse>;
  loadDropoffWarehouse: Maybe<Warehouse>;
};

export default function AppointmentSchedulingSection({
  normalizedLoad: load,
  loadPickupWarehouse,
  loadDropoffWarehouse,
}: AppointmentSchedulingSectionProps) {
  useLogPostHogPageView(Pageview.AppointmentScheduling, {
    service_id: load.serviceID,
    load_id: load.ID,
    freightTrackingID: load.freightTrackingID,
  });

  return (
    <div className='px-5'>
      <Accordion type='multiple' defaultValue={['request-appt']}>
        <AccordionItem value='load-info'>
          <AccordionTrigger icon={<Info className='h-6 w-6' strokeWidth={1} />}>
            Review Load Info
          </AccordionTrigger>
          <AccordionContent>
            <MiniLoadInfo load={load} />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value='request-appt'>
          <AccordionTrigger
            icon={<MailIcon className='h-6 w-6' strokeWidth={1} />}
          >
            Request Appointment
          </AccordionTrigger>
          <AccordionContent>
            <AppointmentEditor
              normalizedLoad={load}
              loadPickupWarehouse={loadPickupWarehouse}
              loadDropoffWarehouse={loadDropoffWarehouse}
            />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
