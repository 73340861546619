import { useMemo, useState } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { NotebookIcon, NotebookTabsIcon } from 'lucide-react';

import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { NoteTimeline } from 'components/NoteTimeline';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import useTMSContext from 'hooks/useTMSContext';
import { useToast } from 'hooks/useToaster';
import { submitNote } from 'lib/api/submitNote';
import { NormalizedLoad, Note } from 'types/Load';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { TMS } from 'types/enums/Integrations';
import Pageview from 'types/enums/Pageview';

import { LoadSectionAccordionItem } from '../LoadInformationTab';
import { relaySourceEnums } from './CheckCalls';

type NoteSectionProps = { normalizedLoad: NormalizedLoad };

type NoteInputs = Omit<Note, 'isException' | 'isOnTime'> & {
  isOnTime: 'Yes' | 'No'; // Primarily for Relay
  isException: 'Yes' | 'No'; // Primarily for Relay
};

type NoteTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<NoteInputs> };
const NoteTextInput = (props: NoteTextInputProps) => (
  <RHFTextInput {...props} />
);

export default function NoteSection({
  normalizedLoad: load,
}: NoteSectionProps) {
  useLogPostHogPageView(Pageview.CheckCallNotes, {
    service_id: load.serviceID,
    load_id: load.ID,
    freightTrackingID: load.freightTrackingID,
  });

  const { tmsName } = useTMSContext();
  const isRelay = tmsName === TMS.Relay;
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>(['']);
  const [history, setHistory] = useState<Array<Note>>(load.notes);

  const defaultValues = useMemo<NoteInputs>(() => {
    const parsedValues = {
      updatedBy: '',
      createdAt: '',
      note: '',
      source: '',
    } as NoteInputs;

    return parsedValues as NoteInputs;
  }, [load]);

  const NoteFormMethods = useForm<NoteInputs>({ defaultValues });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = NoteFormMethods;

  const onSubmitExcpetion: SubmitHandler<NoteInputs> = async (data) => {
    setLoading(true);

    const res = await submitNote(
      load.ID!,
      data.note,
      data.source,
      data.isException?.toLowerCase() === 'yes',
      data.isOnTime?.toLowerCase() === 'yes'
    );

    if (res.isOk()) {
      if (res.value) {
        setHistory(res.value);
      }
      toast({
        description: 'Successfully submitted note.',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setLoading(false);
  };

  return (
    <Accordion type='multiple' value={activeTabs} onValueChange={setActiveTabs}>
      <LoadSectionAccordionItem
        label='Submit Note'
        icon={<NotebookIcon className='h-6 w-6' strokeWidth={1} />}
        name='submitNote'
        activeTabs={activeTabs}
      >
        <div>
          <ExtendedFormProvider aiDefaultValues={true}>
            <FormProvider {...NoteFormMethods}>
              <div>
                <form
                  onSubmit={handleSubmit(onSubmitExcpetion)}
                  className='grid gap-4 grid-cols-1 mt-4 mx-0 w-full'
                >
                  {isRelay && (
                    <div>
                      <Label name='source'>Source</Label>
                      <Controller
                        name='source'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              {relaySourceEnums.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`source`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {isRelay && (
                    <div>
                      <Label name='isOnTime'>{'On Time?'}</Label>
                      <Controller
                        name='isOnTime'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem key={'yes'} value={'yes'}>
                                Yes
                              </SelectItem>
                              <SelectItem key={'no'} value={'no'}>
                                No
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`isOnTime`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {isRelay && (
                    <div>
                      <Label name='isException'>{'Is Exception?'}</Label>
                      <Controller
                        name='isException'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem key={'yes'} value={'yes'}>
                                Yes
                              </SelectItem>
                              <SelectItem key={'no'} value={'no'}>
                                No
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`isException`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  <NoteTextInput name='note' label='Note' required={true} />
                  <Button
                    buttonName={ButtonName.SubmitNote}
                    buttonNamePosthog={ButtonNamePosthog.SubmitNote}
                    type='submit'
                    className='w-full'
                    disabled={loading}
                    logProperties={{
                      loadID: load.ID,
                      freightTrackingID: load.freightTrackingID,
                      serviceID: load.serviceID,
                    }}
                  >
                    {loading ? <ButtonLoader /> : ButtonName.SubmitNote}
                  </Button>
                </form>
              </div>
            </FormProvider>
          </ExtendedFormProvider>
        </div>
      </LoadSectionAccordionItem>

      <LoadSectionAccordionItem
        label='Note History'
        icon={<NotebookTabsIcon className='h-6 w-6' strokeWidth={1} />}
        name='NoteHistory'
        activeTabs={activeTabs}
      >
        {/* TODO: refactor NoteTimeline to support other TMS's */}
        {history ? <NoteTimeline events={history} /> : null}
      </LoadSectionAccordionItem>
    </Accordion>
  );
}
