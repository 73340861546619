import { ReactNode, useEffect, useState } from 'react';

import {
  DrumkitPlatform,
  SidebarState,
  SidebarStateContext,
  defaultSidebarState,
} from 'contexts/sidebarStateContext';

type SidebarBaseProps = {
  drumkitPlatform: DrumkitPlatform;
  children: ReactNode;
};

export type SidebarStateProviderProps = SidebarBaseProps & {
  inboxEmailAddress?: string; // Available in email client platforms (Gmail, Outlook, etc.)
  subject?: string; // Available in email client platforms (Gmail, Outlook, etc.); helpful for debugging
  threadId?: string; // Available in email client platforms (Gmail, Outlook, etc.)
  threadItemId?: string; // Available in email client platforms (Gmail, Outlook, etc.)
  isOutlookReply?: boolean; // Available in Outlook
  isChromeSidePanel?: boolean; // Available only when running as Google Chrome extension
};

export default function SidebarStateProvider({
  drumkitPlatform,
  children,
  inboxEmailAddress,
  subject,
  threadId,
  threadItemId,
  isOutlookReply,
  isChromeSidePanel,
}: SidebarStateProviderProps) {
  const [currentState, setCurrentState] = useState<SidebarState>({
    ...defaultSidebarState,
    drumkitPlatform: drumkitPlatform,
    inboxEmailAddress: inboxEmailAddress ?? '',
    subject: subject ?? '',
    threadId: threadId ?? '',
    threadItemId: threadItemId ?? '',
    isOutlookReply: isOutlookReply ?? false,
    isChromeSidePanel: isChromeSidePanel ?? false,
  });

  useEffect(() => {
    setCurrentState((prevState) => ({
      // Only reset state if thread ID has changed; prevents suggestions from being reset to empty
      ...(prevState.threadId === threadId ? prevState : defaultSidebarState),
      drumkitPlatform: drumkitPlatform,
      inboxEmailAddress: inboxEmailAddress ?? '',
      subject: subject ?? '',
      threadId: threadId ?? '',
      threadItemId: threadItemId ?? '',
      isOutlookReply: isOutlookReply ?? false,
      isChromeSidePanel: isChromeSidePanel ?? false,
    }));
  }, [
    drumkitPlatform,
    inboxEmailAddress,
    subject,
    threadId,
    threadItemId,
    isOutlookReply,
    isChromeSidePanel,
  ]);

  return (
    <SidebarStateContext.Provider
      value={{
        currentState: currentState,
        setCurrentState: setCurrentState,
      }}
    >
      {children}
    </SidebarStateContext.Provider>
  );
}
