import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type GetQuoteNumberResponse = {
  quoteNumber: string;
  hasThirdPartyQuoteURLs: boolean;
};

// getQuoteNumber returns the quote number for the latest email in the thread with a submissionURL
export async function getQuoteNumber(
  emailID: number
): Promise<Result<GetQuoteNumberResponse, ApiError>> {
  try {
    const response = await axios.get(
      `quote/private/quote-number/email/${encodeURIComponent(emailID)}`
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getQuoteNumber' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get quote number' });
    }

    return err({
      message: error.response?.data.message || 'Failed to get quote number',
    });
  }
}
