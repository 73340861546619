import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { Truck } from 'types/Truck';
import { CarrierInformation, TruckListErrors } from 'types/Truck';
import { MaybeUndef } from 'types/UtilityTypes';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export type TruckListResponse = {
  serviceName: string;
  carrier: CarrierInformation;
  trucks: Truck[];
  errors?: MaybeUndef<TruckListErrors>;
};

export default async function getTruckList(
  emailID: number,
  threadID: string
): Promise<Result<TruckListResponse, ApiError>> {
  try {
    const response = await axios.get(
      `thread/${encodeURIComponent(threadID)}/email/${emailID}/trucks`
    );

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getTruckList' });

    return err({
      message: 'Oops, something went wrong!',
    });
  }
}
