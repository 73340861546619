import React, { useEffect } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { AIHintProps } from 'components/AIHint';
import InputPreviousValue from 'components/InputPreviousValue';
import {
  ExtendedFormContextType,
  useExtendedFormContext,
} from 'contexts/extendedFormContext';
import { useFieldAttributes } from 'hooks/useLoadContext';
import {
  FieldAttributes,
  getFieldAttribute,
  initFieldAttributes,
} from 'types/LoadAttributes';
import { Maybe } from 'types/UtilityTypes';
import { cn } from 'utils/shadcn';

import FormInputWrapper from './FormInputWrapper';
import { Input } from './Input';

export enum InputValue {
  PHONE_NUMBER = 'PHONE_NUMBER',
}

type RHFTextInputProps = AIHintProps & {
  name: string;
  label: string;
  description?: string;
  required?: boolean;
  defaultValue?: string;
  placeholder?: string;
  options?: RegisterOptions<any>;
  inputValue?: InputValue;
  step?: number; // Step size (e.g. 0.01) for inputs where inputType === 'number'
  inputType?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  prevValue?: string;
  requiredIcon?: boolean;
  highlightError?: boolean;
  inputClassName?: string;
};

export function RHFTextInput({
  name,
  label,
  required,
  defaultValue,
  placeholder,
  options,
  inputValue,
  inputType = 'text',
  prevValue,
  step = 0.01,
  highlightError = false,
  inputClassName = '',
  ...props
}: RHFTextInputProps) {
  required = required || hasRequiredValidation(options);
  const allFieldAttrs = useFieldAttributes();
  const { register, setValue, getValues, resetField } = useFormContext();

  let extendedFormContext: Maybe<ExtendedFormContextType> = null;

  try {
    extendedFormContext = useExtendedFormContext();
  } catch {
    // no extended form context available
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const oldValue = getValues(name);
    let newValue = event.target.value;

    if (inputValue === InputValue.PHONE_NUMBER) {
      newValue = FormatPhoneNumber(newValue);
    }

    setValue(name, newValue);

    // track field change if callback exists
    if (extendedFormContext?.onFieldChange) {
      extendedFormContext.onFieldChange(name, oldValue, newValue);
    }
  };

  const formattedPhoneValue =
    inputValue === InputValue.PHONE_NUMBER
      ? FormatPhoneNumber(defaultValue || getValues(name))
      : defaultValue || getValues(name);

  const thisFieldAttr: FieldAttributes = (() => {
    // Handle fields of type `ValueUnit`
    const normalizedName = name.replace('.val', '');

    return (
      getFieldAttribute(allFieldAttrs, normalizedName) ?? initFieldAttributes
    );
  })();

  // For read-only phone number fields, format default value so it passes validation.
  useEffect(() => {
    if (
      inputValue === InputValue.PHONE_NUMBER &&
      thisFieldAttr.isReadOnly &&
      formattedPhoneValue
    ) {
      resetField(name, { defaultValue: formattedPhoneValue });
    }
  }, [name]);

  return thisFieldAttr.isNotSupported ? null : (
    <FormInputWrapper
      name={name}
      label={label}
      readOnly={thisFieldAttr.isReadOnly || props.readOnly}
      required={required}
      {...props}
    >
      <Input
        readOnly={thisFieldAttr.isReadOnly || props.readOnly}
        placeholder={placeholder}
        type={inputType}
        min={inputType === 'number' ? 0 : undefined} // Handle `min` conditionally for numbers
        step={inputType === 'number' ? step : undefined}
        value={defaultValue !== '' ? defaultValue : undefined} // Handle `value` conditionally
        className={cn(
          inputClassName ?? '',
          highlightError
            ? 'bg-red-50 disabled:bg-red-50/50 read-only:bg-red-50/50'
            : ''
        )}
        {...register(name, {
          ...options,
          onChange: handleInputChange,
          required: required ? 'Required' : undefined, // Conditional required message
          maxLength: thisFieldAttr.maxLength
            ? {
                value: thisFieldAttr.maxLength,
                message: `Maximum length is ${thisFieldAttr.maxLength} characters`,
              }
            : undefined, // Conditional maxLength validation
        })}
      />

      {prevValue ? <InputPreviousValue prevValue={prevValue} /> : null}
    </FormInputWrapper>
  );
}

export function FormatPhoneNumber(value: string): string {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '').substring(0, 10);

  if (phoneNumber.length < 4) return phoneNumber;
  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6)}`;
}

/**
 * Checks if the given RHF registration options contain the 'required' validation rule.
 *
 * @param options - The registration options object to verify.
 * @returns `true` if the 'required' rule exists; otherwise `false`.
 */
export function hasRequiredValidation(options?: RegisterOptions): boolean {
  return !!options?.required;
}
