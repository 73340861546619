import captureException from 'utils/captureException';

import { FormStorageServiceInterface, SavedFormState } from './types';

/* Full key format: formState_{tmsName}_{threadId} */

// TODO: Save clicked suggestion too
export const FormStorageService: FormStorageServiceInterface = {
  storageKey: 'formState',

  saveFormState<T, U>(key: string, formState: SavedFormState<T, U>): void {
    try {
      const stateToSave = JSON.stringify({ ...formState });
      localStorage.setItem(`${this.storageKey}_${key}`, stateToSave);
      // Test throwing error; this should be fail-open
    } catch (error) {
      captureException(error, {
        functionName: 'FormStorageService.saveFormState',
      });
    }
  },

  getFormState<T, U>(key: string): SavedFormState<T, U> | undefined {
    try {
      const storedState = localStorage.getItem(`${this.storageKey}_${key}`);
      if (storedState) {
        return JSON.parse(storedState) as SavedFormState<T, U>;
      }
    } catch (error) {
      captureException(error, {
        functionName: 'FormStorageService.getFormState',
      });
    }
    return undefined;
  },

  clearFormState(key: string): void {
    try {
      localStorage.removeItem(`${this.storageKey}_${key}`);
    } catch (error) {
      captureException(error, {
        functionName: 'FormStorageService.clearFormState',
      });
    }
  },
};
