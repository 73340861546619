import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldPathByValue,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { get } from 'lodash';
import { XCircleIcon } from 'lucide-react';

import { AIHintProps } from 'components/AIHint';
import { DatePicker } from 'components/DatePicker';
import { Label } from 'components/Label';
import { RHFTextInput } from 'components/input/RHFTextInput';
import {
  ExtendedFormContextType,
  useExtendedFormContext,
} from 'contexts/extendedFormContext';
import { useFieldAttributes } from 'hooks/useLoadContext';
import useTMSContext from 'hooks/useTMSContext';
import {
  FieldAttributes,
  getFieldAttribute,
  initFieldAttributes,
} from 'types/LoadAttributes';
import { Maybe } from 'types/UtilityTypes';
import { TMS } from 'types/enums/Integrations';
import { cn } from 'utils/shadcn';
import {
  timepickerChangeHandler,
  timepickerParseTimeWithoutColon,
} from 'utils/timepickerFunctions';

export type DateTimeInputProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, Date>,
> = React.ComponentPropsWithoutRef<typeof RHFTextInput> &
  Omit<AIHintProps, 'name'> & {
    control: Control<TFieldValues>;
    name: TPath;
    label: string;
    required?: boolean;
    hideAIHint?: boolean;
    // Prevents appending short TZ name to label (e.g. Start Time (EDT) ==> Start Time (EDT))
    preventNormalizedLabelTZ?: boolean;
    options?: Omit<
      RegisterOptions<TFieldValues>,
      'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
    >;
    // options?: any;
    disabled?: boolean;
  };

export default function DateTimeInput<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, Date>,
>({
  control,
  name,
  label,
  required,
  hideAIHint,
  preventNormalizedLabelTZ,
  options,
  disabled,
}: DateTimeInputProps<TFieldValues, TPath>) {
  const { tmsName } = useTMSContext();
  const isTZAgnosticTMS = tmsName === TMS.Aljex;
  const shortTZ = isTZAgnosticTMS ? '' : `(${dayjs().tz().format('z')})`;
  // Show user's timezone if TMS is not TZ-agnostic
  const normalizedLabel =
    preventNormalizedLabelTZ || isTZAgnosticTMS ? label : label + ' ' + shortTZ;
  const [highlightDirtyField, setHighlightDirtyField] = useState(false);

  const allFieldAttrs = useFieldAttributes();

  const thisFieldAttr: FieldAttributes =
    getFieldAttribute(allFieldAttrs, name) ?? initFieldAttributes;

  const formContext = useFormContext();
  const {
    formState: { errors },
  } = formContext;

  let extendedFormContext: Maybe<ExtendedFormContextType> = null;
  try {
    extendedFormContext = useExtendedFormContext();
  } catch {
    // No extended form context available
  }

  useEffect(() => {
    if (formContext && extendedFormContext && name) {
      const {
        getFieldState,
        formState: { dirtyFields },
      } = formContext;
      const highlightDirtyFields =
        extendedFormContext.highlightDirtyFields ?? false;
      const fieldState = getFieldState(name);
      const isDirty = fieldState.isDirty || get(dirtyFields, name);

      setHighlightDirtyField(isDirty && highlightDirtyFields);
    } else {
      // If no form context, reset the highlight
      setHighlightDirtyField(false);
    }
  }, [extendedFormContext, formContext, name]);

  return thisFieldAttr.isNotSupported ? null : (
    <p>
      <Label name={name} hideAIHint={hideAIHint} required={required}>
        {normalizedLabel}
      </Label>
      <Controller
        name={name}
        control={control}
        rules={{ required: required ? 'Required' : undefined, ...options }}
        render={({ field }) => (
          <div className='mt-1 flex flex-row gap-1' data-name={name}>
            <div className='flex gap-4 flex-1'>
              <DatePicker
                field={field}
                thisFieldAttr={thisFieldAttr}
                highlightDirtyField={highlightDirtyField}
              />

              <TimePicker
                className={cn(
                  '!pr-1 pl-3 w-1/2 h-8 rounded-[4px] border border-grayscale-border-input text-grayscale-content-input',
                  highlightDirtyField && 'bg-yellow-50'
                )}
                allowClear={false}
                disabled={!field.value || thisFieldAttr.isReadOnly || disabled}
                readOnly={thisFieldAttr.isReadOnly || disabled}
                placeholder='00:00'
                format={'HH:mm'}
                suffixIcon={null}
                value={
                  field.value ? dayjs(field.value) : dayjs().hour(0).minute(0)
                }
                onBlur={(e: React.FocusEvent<HTMLElement>) => {
                  const parsedTime = timepickerParseTimeWithoutColon(
                    e.target as HTMLInputElement
                  );
                  if (parsedTime) {
                    timepickerChangeHandler(
                      dayjs(field.value)
                        .hour(parsedTime.hours)
                        .minute(parsedTime.minutes),
                      field
                    );
                  }
                }}
                onChange={(dayjsTime: Dayjs, _: string | string[]) =>
                  timepickerChangeHandler(dayjsTime, field)
                }
                /**
                 * This prevents the Timepicker input from crashing when the
                 * key 'Enter' is pressed with an invalid time.
                 *
                 * dayjs' .isValid() method unfortunately doesn't work here since
                 * hours greater than 24 just add another day to the date object, and
                 * creating string validation worked a little clunky (even with regex).
                 *
                 * The current solution works because e.preventDefault() doesn't allow
                 * for the input component's value to change, so when it's valid the event
                 * just closes the modal accordingly, but when it's not, nothing happens.
                 */
                onKeyDown={(e: React.KeyboardEvent) =>
                  e.key === 'Enter' && e.preventDefault()
                }
              />
            </div>
            {field.value && (
              <button
                title='Clear date'
                onClick={() => field.onChange(null)}
                className='h-9 flex items-center justify-center'
              >
                <XCircleIcon className='w-4 h-4' />
              </button>
            )}
          </div>
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }: { message: string }) => (
          <p className='text-red-500 text-xs'>{message}</p>
        )}
      />
    </p>
  );
}
