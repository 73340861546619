import dayjs from 'dayjs';

/**
 * We can do `obj === Object(obj)` because, according to MDN:
 *
 * The Object constructor creates an object wrapper for the given value.
 * If the value is null or undefined, it will create and return an empty object, otherwise,
 * it will return an object of a type that corresponds to the given value.
 * If the value is an object already, it will return the value.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object
 *
 * Note that because Date objects are also objects that are empty but contain methods,
 * we need to explicitly check for Date/dayjs objects
 */
export const isValidNonDateObject = (obj: any): boolean =>
  obj === Object(obj) && !(obj instanceof Date) && !dayjs.isDayjs(obj);

/**
 *
 * @param obj  object to check for truthy values
 * @param subset  optional subset of keys to check for truthy values. If not provided, all keys will be checked.
 * @returns boolean indicating whether the object has truthy values for the given subset, or all keys if no subset is provided
 */
export const objectHasTruthyValues = <T extends Record<string, any>>(
  obj: T,
  subset?: (keyof T)[]
): boolean => {
  const hasValues = Object.entries(obj).some(([key, v]) => {
    if (subset?.length && !subset.includes(key as keyof T)) return false;

    const isObject = typeof v === 'object' && v !== null;
    return isObject ? objectHasTruthyValues(v as Record<string, any>) : !!v; // https://airbnb.io/javascript/#coercion--booleans
  });

  return hasValues;
};
