import React, { useContext, useState } from 'react';

import DRUMKIT_AUTH_URL from '@constants/DrumkitAuthUrl';

interface IPortalContext {
  portalUrl: string;
  setPortalUrl: (url: string) => void;
}

const DEFAULT_PORTAL_URL = `${DRUMKIT_AUTH_URL}/login`;

const INITIAL_PORTAL_CONTEXT: IPortalContext = {
  portalUrl: DEFAULT_PORTAL_URL,
  setPortalUrl: () => {},
};

const PortalContext = React.createContext<IPortalContext>(
  INITIAL_PORTAL_CONTEXT
);

export const usePortalContext = () => useContext(PortalContext);

interface PortalContextProviderProps {
  children: React.ReactNode;
}

export const PortalContextProvider: React.FC<PortalContextProviderProps> = ({
  children,
}) => {
  const [portalUrl, setPortalUrl] = useState<string>(DEFAULT_PORTAL_URL);

  return (
    <PortalContext.Provider value={{ portalUrl, setPortalUrl }}>
      {children}
    </PortalContext.Provider>
  );
};
