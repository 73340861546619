import { useState } from 'react';

import { SearchIcon } from 'lucide-react';

import { cn } from 'utils/shadcn';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './Command';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuTrigger,
} from './Dropdown';

type ComboboxDropdownMenuProps = {
  selectedOperator: string;
  operators: Array<string>;
  setSelectedOperator: (operator: string) => void;
  canEdit: boolean;
  highlightDirtyField?: boolean;
};

export function ComboboxDropdownMenu({
  selectedOperator,
  operators,
  setSelectedOperator,
  canEdit,
  highlightDirtyField,
}: ComboboxDropdownMenuProps) {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <button className='mt-1' disabled={!canEdit}>
          <span
            className={cn(
              'flex items-center justify-between h-9 w-full rounded border hover:cursor-pointer border-orange-border-main bg-white px-3 py-2 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-grayscale-content-input/30  focus-visible:border-pink-crimson',
              highlightDirtyField && 'bg-yellow-50',
              !canEdit &&
                'hover:cursor-not-allowed opacity-50 border-grayscale-border-input focus-visible:outline-none focus-visible:border-grayscale-border-input'
            )}
          >
            <span className='text-grayscale-content-input text-sm'>
              {selectedOperator}
            </span>
            <SearchIcon strokeWidth={1} className='h-4 w-4' color='#585757' />
          </span>
        </button>
      </DropdownMenuTrigger>
      {canEdit && (
        <>
          <DropdownMenuContent align='start' className='z-50'>
            <DropdownMenuGroup>
              <DropdownMenuSub>
                <Command>
                  <CommandInput placeholder='Assign to...' autoFocus={true} />
                  <CommandList>
                    <CommandEmpty>No operator found.</CommandEmpty>
                    <CommandGroup>
                      {operators.map((operator) => (
                        <CommandItem
                          key={operator}
                          value={operator}
                          onSelect={() => {
                            setSelectedOperator(operator);
                            setOpen(false);
                          }}
                        >
                          {operator}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </DropdownMenuSub>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className='text-red-600'
                onSelect={() => {
                  setSelectedOperator('');
                }}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </>
      )}
    </DropdownMenu>
  );
}
