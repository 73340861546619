// These enums must match those in drumkit/common/models/integration.
export enum TMS {
  Aljex = 'aljex',
  Turvo = 'turvo',
  Relay = 'relay',
  Tai = 'tai',
  McleodEnterprise = 'mcleodenterprise',
  Mcleod = 'mcleod',
}

export enum CarrierVerification {
  MyCarrierPortal = 'mycarrierportal',
  Highway = 'highway',
  FreightValidate = 'freightvalidate',
  Truckstop = 'truckstop',
}
