import { isAxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import {
  GetSlotsResponse,
  GroupedSlot,
  OrderedSlots,
  Slot,
} from 'types/Appointment';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

dayjs.extend(timezone);

interface GetOpenSlotsProps {
  loadTypeID: string;
  warehouseID: string;
  warehouseTimezoneStartDate: dayjs.Dayjs;
  warehouseTimezoneEndDate: dayjs.Dayjs;
  freightTrackingID?: string;
  trailerType?: string;
  dockId?: string;
}
export async function getOpenApptSlots({
  loadTypeID,
  warehouseID,
  warehouseTimezoneStartDate,
  warehouseTimezoneEndDate,
  freightTrackingID,
  trailerType,
  dockId,
}: GetOpenSlotsProps): Promise<Result<OrderedSlots, ApiError>> {
  try {
    const base = '/appt/slots';
    const params = [
      `loadTypeID=${encodeURIComponent(loadTypeID)}`,
      `warehouseID=${encodeURIComponent(warehouseID)}`,
      `start=${encodeURIComponent(warehouseTimezoneStartDate.format())}`,
      `end=${encodeURIComponent(warehouseTimezoneEndDate.format())}`,
    ];

    if (freightTrackingID) {
      params.push(`freightTrackingID=${encodeURIComponent(freightTrackingID)}`);
    }

    if (trailerType) {
      params.push(`trailerType=${encodeURIComponent(trailerType)}`);
    }

    if (dockId) {
      params.push(`dockID=${encodeURIComponent(dockId)}`);
    }

    const url = `${base}?${params.join('&')}`;

    const response = await axios.get<GetSlotsResponse>(url);

    const newGroupedSlots = groupSlotsByDate(response.data);
    return ok(newGroupedSlots);
  } catch (error) {
    captureException(error, { functionName: 'getOpenApptSlots' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
    });
  }
}

function groupSlotsByDate(resp: GetSlotsResponse): OrderedSlots {
  const grouped: Record<string, GroupedSlot[]> = {};
  let warehouseTimezone = '';

  resp.slots.forEach((slot: Slot) => {
    if (warehouseTimezone === '') {
      warehouseTimezone = slot.warehouse.warehouseTimezone;
    }

    slot.startTimes.forEach((startTime: string) => {
      const startTimeWithTimezone = dayjs(startTime).tz(
        slot.warehouse.warehouseTimezone
      );

      const date = formatDateToMMDD(startTimeWithTimezone);

      if (!grouped[date]) {
        grouped[date] = [];
      }

      const hasSlotOnDateTime = grouped[date].some(
        (s) =>
          startTimeWithTimezone.toDate().toISOString() ===
          s.startTime.toISOString()
      );

      if (hasSlotOnDateTime) {
        return;
      }

      grouped[date].push({
        dock: slot.dock,
        startTime: startTimeWithTimezone.toDate(),
        timezone: slot.warehouse.warehouseTimezone,
      });
    });
  });

  for (const date in grouped) {
    grouped[date].sort((a: GroupedSlot, b: GroupedSlot) => {
      return dayjs(a.startTime).diff(dayjs(b.startTime));
    });
  }

  return {
    start: resp.start,
    end: resp.end,
    warehouse: resp.warehouse,
    warehouseTimezone: warehouseTimezone,
    loadType: resp.loadType,
    trailerType: resp.trailerType,
    slots: grouped,
  };
}

function formatDateToMMDD(date: Dayjs): string {
  const formattedDate = date.format('M/D/YY');
  const dayOfWeek = date.format('ddd');

  return `${dayOfWeek}, ${formattedDate}`;
}
