import Fuse from 'fuse.js';

import {
  CustomApptFieldsTemplate,
  StopTypes,
  WarehouseSettings,
} from 'types/Appointment';
import { NormalizedLoad } from 'types/Load';
import { MaybeUndef, Undef } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';

export const SevenElevenVirginiaID = '9b1beeac-c174-4282-860e-36eac63779c1';
export const SevenElevenMarylandID = '04782838-7a73-423f-9df5-67df700bdb75';

export enum TrailerType {
  Van = 'Cargo Van',
  Trailer = 'LTL/FTL Trailer',
  Passenger = 'Passenger Vehicle',
  Truck = 'Straight Truck',
}

// If user is rescheduling appt, exclude previous OpenDock info from notes of new appt
export function removeSchedulerInfo(input: string): string {
  return input.replace(/Opendock \b\d+\b\.?/i, '').trim();
}

export function aiSuggestCustomApptFields(
  load: NormalizedLoad,
  stopType: StopTypes,
  warehouse: Warehouse,
  customFields: CustomApptFieldsTemplate[]
) {
  const fieldMap = new Map<string, CustomApptFieldsTemplate>();
  customFields &&
    customFields.forEach((field) => fieldMap.set(field.name, field));

  const setField = (fieldName: string, value: string) => {
    const field = fieldMap.get(fieldName);
    if (field) {
      field.value = value;
    }
  };

  switch (warehouse.warehouseID) {
    case SevenElevenVirginiaID:
      setField('strTrailer', load.carrier.trailerNumber);
      setField('dropdownVehicleType', TrailerType.Truck);
      setField('intMisc1', load.outPalletCount?.toString() ?? '');
      setField('dropdownOrder Type', 'PO');

      load.poNums &&
        load.poNums.split(',').forEach((poNum, index) => {
          const i = index + 1;
          setField(poFieldName(i), poNum);
        });
      break;

    case SevenElevenMarylandID:
      setField('phoneCell Phone', load.carrier.firstDriverPhone);
      setField('strHauling Carrier Name', load.carrier.name);
      break;

    default: {
      const stop =
        stopType === StopTypes.Dropoff ? load.consignee : load.pickup;
      const refNum = load.poNums || load.customer.refNumber || stop.refNumber;

      customFields &&
        customFields.forEach((field) => {
          const lowerLabel = field.label.toLowerCase();
          const lowerDescription = field.description.toLowerCase();

          const isMatch = (term: string): boolean => {
            return lowerLabel.includes(term) || lowerDescription.includes(term);
          };

          // Handle non-dropdown fields
          if (field.requiredForCarrier && field.type !== 'dropdown') {
            if (
              isMatch('pallet') &&
              load.outPalletCount &&
              load.outPalletCount > 0
            ) {
              field.value = load.outPalletCount?.toString() ?? '';
              return;
            }

            // AI-fill carrier name, if present
            if (carrierTerms.some((term) => isMatch(term))) {
              field.value = load.carrier.name;
              return;
            }

            // AI-fill customer name, if present
            if (customerTerms.some((term) => isMatch(term))) {
              field.value = load.customer.name ?? load.pickup.name;
              return;
            }

            // AI-fill PO number, if present
            if (poTerms.some((term) => isMatch(term))) {
              field.value = refNum;
              return;
            }

            // AI-fill PRO number, if present
            if (proTerms.some((term) => isMatch(term))) {
              field.value =
                (lowerLabel.toUpperCase().includes('PRO OR BOL') ||
                  field.label.toUpperCase().includes('BOL OR PRO')) &&
                warehouse.warehouseName.toLowerCase().startsWith('om')
                  ? load.customer.refNumber
                  : load.freightTrackingID;
              return;
            }
          } else if (
            // Handle dropdown fields
            field.requiredForCarrier &&
            field.type === 'dropdown' &&
            field.dropDownValues &&
            field.dropDownValues?.length > 0
          ) {
            if (carrierTerms.some((term) => isMatch(term))) {
              const fuse = new Fuse(field.dropDownValues, {
                threshold: 0.7,
                isCaseSensitive: false,
              });
              const result = fuse.search(load.carrier.name);
              if (result.length > 0) {
                field.value = result[0].item;
                return;
              }
            }

            if (customerTerms.some((term) => isMatch(term))) {
              const fuse = new Fuse(field.dropDownValues, {
                threshold: 0.7,
                isCaseSensitive: false,
              });
              const result = fuse.search(
                load.customer.name ?? load.pickup.name
              );
              if (result.length > 0) {
                field.value = result[0].item;
              }
            }
          }
        });
    }
  }
}

const poTerms = [
  'po#',
  'po #',
  'po num',
  'po number',
  'po numbers',
  'purchase order',
  'order number',
];

const proTerms = [
  'pro',
  'tracking number',
  'load number',
  'load id',
  'load#',
  'load #',
  'shipment id',
  'id#',
  'id #',
  'shipment#',
  'shipment #',
];

const carrierTerms = ['carrier'];

// QN: Is vendor customer/pickup or carrier? Based on OpenDock 7/11 form, seems to be customer/pickup
const customerTerms = ['customer', 'vendor', 'supplier'];
// AI-fills only *required* PO # fields
export function suggestPONumCustomFields(
  customApptFields: CustomApptFieldsTemplate[],
  poNum: string
) {
  if (!poNum) {
    return;
  }

  poTerms.forEach((term) => {
    customApptFields?.map((field) => {
      if (
        (field.label.toLowerCase().includes(term) ||
          field.description.toLowerCase().includes(term)) &&
        field.requiredForCarrier &&
        (field.type !== 'dropdown' || field.dropDownValues?.length === 0) &&
        field.value === ''
      ) {
        field.value = poNum;
      }
      return field;
    });
  });
}

// AI-fills only *required* PRO # fields
export function suggestPROCustomFields(
  customApptFields: CustomApptFieldsTemplate[],
  freightTrackingID: Undef<string>,
  externalTMSID: Undef<string>
) {
  if (!freightTrackingID) {
    return;
  }

  proTerms.forEach((term) => {
    customApptFields?.map((field) => {
      if (
        (field.label.toLowerCase().includes(term) ||
          field.description.toLowerCase().includes(term)) &&
        field.requiredForCarrier &&
        (field.type !== 'dropdown' || field.dropDownValues?.length === 0)
      ) {
        if (field.label.toUpperCase().includes('PRO OR BOL') && externalTMSID) {
          field.value = externalTMSID;
        } else {
          field.value = freightTrackingID;
        }

        return field;
      }

      return field;
    });
  });
}

// AI-fills reference number if OpenDock warehouse makes it visible
export function suggestRefNumberField(
  settings: WarehouseSettings,
  poNum: string,
  pro: Undef<string>
): string {
  if (!settings.referenceNumberIsVisible) {
    return '';
  }

  const suggestPRO = proTerms.some((term) => {
    return (
      settings.referenceNumberHelperText.toLowerCase().includes(term) ||
      settings.referenceNumberDisplayName.toLowerCase().includes(term)
    );
  });

  if (suggestPRO) {
    return pro ?? '';
  }

  const suggestPONum = poTerms.some((term) => {
    return (
      settings.referenceNumberHelperText.toLowerCase().includes(term) ||
      settings.referenceNumberDisplayName.toLowerCase().includes(term)
    );
  });

  if (suggestPONum) {
    return poNum ?? '';
  }

  return pro ?? '';
}

// AI-fills *required* pallet count
export function suggestPalletCountField(
  customApptFields: CustomApptFieldsTemplate[],
  palletCount: MaybeUndef<number>
) {
  if (!palletCount || palletCount < 1) {
    return;
  }

  customApptFields?.map((field) => {
    if (
      (field.label.toLowerCase().includes('pallet') ||
        field.description.toLowerCase().includes('pallet')) &&
      field.requiredForCarrier &&
      (field.type !== 'dropdown' || field.dropDownValues?.length === 0)
    ) {
      field.value = `${palletCount}`;
      return field;
    }

    return field;
  });
}

// Given the PO index (1-based), return the name of the associated form name.
// PO field names for warehouse 8010 (NFI 7-11 VA) are as follows:
//
// "strOrder\d" for 40/50 PO form inputs
//
// "strOrder \d" for the other 10
function poFieldName(index: number): string {
  switch (index) {
    case 1:
    case 2:
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
    case 46:
    case 48:
    case 49:
      // Add space
      return 'strOrder ' + index;
    default:
      // No space
      return 'strOrder' + index;
  }
}
