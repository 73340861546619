import dayjs from 'dayjs';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { CarrierInformation, Truck, TruckListErrors } from 'types/Truck';
import { MaybeUndef } from 'types/UtilityTypes';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export type CreateTruckListResponse = {
  serviceName: string;
  carrier: CarrierInformation;
  trucks: Truck[];
  errors: MaybeUndef<TruckListErrors>;
};

export default async function createTruckList(
  emailID: number,
  threadID: string
): Promise<Result<CreateTruckListResponse, ApiError>> {
  const params = [
    `threadID=${encodeURIComponent(threadID)}`,
    `emailID=${emailID}`,
  ];

  try {
    const response = await axios.post(`/trucklist/create?${params.join('&')}`, {
      pickupDate: `${dayjs().add(1, 'day').toISOString().split('.')[0]}Z`,
    });

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'createTruckList' });

    return err({
      message: 'Oops, something went wrong!',
    });
  }
}
