import { AxiosError, AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { SidebarState } from 'contexts/sidebarStateContext';

type FetchStarredLoadsResponse = {
  starredLoads: string[];
};

export function fetchStarredLoads(setCurrentState: (state: any) => void): void {
  axios
    .get<FetchStarredLoadsResponse>(`load/starred`)
    .then((axiosRes: AxiosResponse<FetchStarredLoadsResponse>) => {
      const {
        data: { starredLoads },
      } = axiosRes;

      setCurrentState((prevState: SidebarState) => ({
        ...prevState,
        starredLoads: starredLoads ?? [],
      }));
    })
    .catch((error: AxiosError) => {
      console.error('Error fetching starred loads:', error);
    });
}
