import { useContext } from 'react';
import type { KeyboardEvent as ReactKeyboardEvent } from 'react';

import { AutoComplete } from 'antd';
import { SearchIcon } from 'lucide-react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { LOAD_MONIKER } from '@constants/LoadMoniker';

import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useServiceFeatures } from 'hooks/useServiceContext';

import { useLoadSearch } from '../contexts/loadSearchContext';
import AdvancedSearchPopover from './AdvancedSearchPopover';

export default function SearchBar() {
  const { proNumberInput, setProNumberInput, addLoadTab } = useLoadSearch();

  const {
    currentState: { viewedLoads },
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: { isAdvancedSearchEnabled },
  } = useServiceFeatures();

  const handleBlurSearchInput = async () => {
    if (proNumberInput === '') return;
    try {
      await addLoadTab(proNumberInput);
    } catch (error) {
      console.error('Error adding load tab:', error);
    }
  };

  const handleKeyDownSearchInput = (
    ev: ReactKeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === 'Enter') {
      handleBlurSearchInput();
    }
  };

  return (
    <div className='w-full'>
      <div className='search-container relative flex border border-grayscale-border-main rounded-[4px] bg-white h-8 z-20'>
        <div className='search-filter flex items-center text-grayscale-content-label font-bold text-[11px] px-2 rounded-[4px] rounded-r-none bg-gray-200'>
          <div>{LOAD_MONIKER}&nbsp;#</div>
        </div>
        <div className='flex-1 flex justify-between bg-white rounded gap-2'>
          {isAdvancedSearchEnabled && (
            <div className='w-full relative flex' id='search-bar-container'>
              <AutoComplete
                options={viewedLoads.reverse().map((vlID) => ({
                  label: `${vlID}`,
                  value: vlID,
                }))}
                onSelect={async (v: string) => {
                  try {
                    await addLoadTab(v);
                  } catch (error) {
                    console.error('Error adding load tab:', error);
                  }
                }}
                value={proNumberInput}
                suffixIcon={null}
                className='w-full'
              >
                <input
                  type='text'
                  className='w-full pl-2 pr-20 h-[30px] bg-transparent focus-visible:outline-none text-[13px]'
                  value={proNumberInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setProNumberInput(e.target.value)
                  }
                  onBlur={handleBlurSearchInput}
                  onKeyDown={handleKeyDownSearchInput}
                  placeholder='Search loads...'
                />
              </AutoComplete>
              <div className='absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2'>
                <SearchIcon className='h-3.5 w-3.5 stroke-grayscale-icon-stroke cursor-pointer z-[1]' />
                <AdvancedSearchPopover />
              </div>
            </div>
          )}

          {!isAdvancedSearchEnabled && (
            <div className='w-full relative flex'>
              <input
                type='text'
                className='w-full pl-2 pr-8 h-[30px] bg-transparent focus-visible:outline-none text-[13px]'
                value={proNumberInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setProNumberInput(e.target.value)
                }
                onBlur={handleBlurSearchInput}
                onKeyDown={handleKeyDownSearchInput}
                placeholder='Search loads...'
              />
              <SearchIcon className='h-3.5 w-3.5 stroke-grayscale-icon-stroke absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
