import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

import AIHintLabel, { AIHintProps } from 'components/AIHint';
import RequiredHint from 'components/RequiredHint';

type FormInputWrapperProps = AIHintProps & {
  name: string;
  label: string;
  required?: boolean;
  description?: string;
  requiredIcon?: boolean;
};

export default function FormInputWrapper({
  name,
  label,
  required,
  description,
  children,
  ...rest
}: React.PropsWithChildren<FormInputWrapperProps>) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <label className='flex flex-col'>
      <div className='mb-1'>
        <span className='flex flex-row whitespace-nowrap items-center text-sm text-grayscale-content-label'>
          {label}
          {required && <RequiredHint />}

          <AIHintLabel name={name} {...rest} />
        </span>
        {description && (
          <span className='text-xs text-grayscale-content-description'>
            {description}
          </span>
        )}
      </div>
      {children}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }: { message: string }) => (
          <p className='text-red-500 text-xs'>{message}</p>
        )}
      />
    </label>
  );
}
