import { useState } from 'react';

import { format } from 'date-fns';
import dayjs from 'dayjs';
import {
  Calendar as CalendarIcon,
  SlidersHorizontal,
  XCircleIcon,
} from 'lucide-react';

import ButtonLoader from 'components/loading/ButtonLoader';

import { STATE_ABBREVIATIONS } from '../constants/StateTimezones';
import { useLoadSearch } from '../contexts/loadSearchContext';
import { useToast } from '../hooks/useToaster';
import fetchLoadBySearchQuery from '../lib/api/fetchLoadBySearchQuery';
import ButtonNamePosthog from '../types/enums/ButtonNamePosthog';
import { cn } from '../utils/shadcn';
import { Button } from './Button';
import { Calendar } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './Select';
import { Input } from './input';

// FIXME: Don't fetch TMS objects for each load tab
export default function AdvancedSearchPopover() {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [routePickupCity, setRoutePickupCity] = useState('');
  const [routePickupState, setRoutePickupState] = useState('');
  const [routeDropoffCity, setRouteDropoffCity] = useState('');
  const [routeDropoffState, setRouteDropoffState] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [pickupDate, setPickupDate] = useState<Date>();
  const [dropoffDate, setDropoffDate] = useState<Date>();
  const [pickupCalendarOpen, setPickupCalendarOpen] = useState(false);
  const [dropoffCalendarOpen, setDropoffCalendarOpen] = useState(false);

  const { addLoadTab } = useLoadSearch();
  const { toast } = useToast();

  const handleFetchLoadBySearch = async () => {
    if (!pickupDate || !routePickupCity || !routeDropoffCity) {
      toast({
        description: 'Pickup date, pickup city, and dropoff city are required.',
        variant: 'destructive',
      });
      return;
    }

    setIsLoading(true);

    const resp = await fetchLoadBySearchQuery({
      customerName,
      routePickupCity,
      routePickupState,
      routeDropoffCity,
      routeDropoffState,
      pickupDate: pickupDate ? dayjs(pickupDate) : null,
      dropoffDate: dropoffDate ? dayjs(dropoffDate) : null,
    });

    if (resp.isOk()) {
      const {
        value: { loadIDs },
      } = resp;

      if (loadIDs?.length === 0) {
        toast({
          description: 'No loads were found.',
          variant: 'destructive',
        });
        setIsLoading(false);
        return;
      }

      for (const loadID of loadIDs || []) {
        try {
          await addLoadTab(loadID);
        } catch (error) {
          console.error('Error adding load tab:', error);
        }
      }

      // Close the popover after successful search
      setOpen(false);
    } else {
      toast({
        description: resp.error.message,
        variant: 'destructive',
      });
    }

    setIsLoading(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button className='flex items-center justify-center cursor-pointer'>
          <SlidersHorizontal className='h-3.5 w-3.5 stroke-grayscale-icon-stroke' />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className='p-4 pb-1 bg-white w-[268px]'
        align='center'
        side='bottom'
        style={{
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
        }}
      >
        <div className='flex flex-col gap-2'>
          <Input
            type='text'
            placeholder='Customer Name'
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className='h-[30px] text-[13px]'
          />

          <div className='grid gap-2'>
            <Popover
              open={pickupCalendarOpen}
              onOpenChange={setPickupCalendarOpen}
            >
              <PopoverTrigger asChild>
                <Button
                  variant='outline'
                  className={cn(
                    'w-full justify-start text-left font-normal h-[30px] text-[13px]',
                    !pickupDate && 'text-muted-foreground'
                  )}
                >
                  <CalendarIcon className='mr-2 h-3.5 w-3.5' />
                  {pickupDate ? (
                    format(pickupDate, 'MM/dd/yyyy')
                  ) : (
                    <>Pick pickup date *</>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className='w-auto p-0'>
                <Calendar
                  mode='single'
                  selected={pickupDate}
                  onSelect={(date) => {
                    setPickupDate(date);
                    setPickupCalendarOpen(false);
                  }}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>

          <div className='grid gap-2'>
            <Popover
              open={dropoffCalendarOpen}
              onOpenChange={setDropoffCalendarOpen}
            >
              <PopoverTrigger asChild>
                <Button
                  variant='outline'
                  className={cn(
                    'w-full justify-between items-center font-normal h-[30px] text-[13px]',
                    !dropoffDate && 'text-muted-foreground'
                  )}
                >
                  <div className='flex items-center'>
                    <CalendarIcon className='mr-2 h-3.5 w-3.5' />
                    {dropoffDate
                      ? format(dropoffDate, 'MM/dd/yyyy')
                      : 'Pick dropoff date'}
                  </div>
                  {dropoffDate && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent opening the calendar
                        setDropoffDate(undefined);
                      }}
                      className='text-gray-500 hover:text-orange-pressed focus:outline-none'
                      title='Clear dropoff date'
                      aria-label='Clear dropoff date'
                    >
                      <XCircleIcon className='h-3.5 w-3.5' />
                    </button>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className='w-auto p-0'>
                <Calendar
                  mode='single'
                  selected={dropoffDate}
                  onSelect={(date) => {
                    setDropoffDate(date);
                    setDropoffCalendarOpen(false);
                  }}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>

          <div className='flex gap-2'>
            <Input
              type='text'
              placeholder='Pickup City *'
              value={routePickupCity}
              onChange={(e) => setRoutePickupCity(e.target.value)}
              className='h-[30px] text-[13px]'
            />
            <Select
              value={routePickupState}
              onValueChange={setRoutePickupState}
            >
              <SelectTrigger className='min-w-[80px] w-[80px] h-[30px] text-[13px]'>
                <SelectValue placeholder='State' className='text-[13px]' />
              </SelectTrigger>
              <SelectContent>
                {STATE_ABBREVIATIONS.map((state) => (
                  <SelectItem key={state} value={state} className='text-[13px]'>
                    {state}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className='flex gap-2'>
            <Input
              type='text'
              placeholder='Dropoff City *'
              value={routeDropoffCity}
              onChange={(e) => setRouteDropoffCity(e.target.value)}
              className='h-[30px] text-[13px]'
            />
            <Select
              value={routeDropoffState}
              onValueChange={setRouteDropoffState}
            >
              <SelectTrigger className='min-w-[80px] w-[80px] h-[30px] text-[13px]'>
                <SelectValue placeholder='State' />
              </SelectTrigger>
              <SelectContent>
                {STATE_ABBREVIATIONS.map((state) => (
                  <SelectItem key={state} value={state}>
                    {state}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <Button
            variant='default'
            className='bg-orange-pressed text-white h-6 rounded-[4px] text-[12px]'
            disabled={isLoading}
            buttonNamePosthog={ButtonNamePosthog.SearchLoad}
            onClick={handleFetchLoadBySearch}
          >
            {isLoading ? <ButtonLoader /> : 'Search'}
          </Button>
          <span className='text-[10px] italic text-center text-grayscale-content-3 mb-0 pt-0'>
            Max 10 results shown
          </span>
        </div>
      </PopoverContent>
    </Popover>
  );
}
