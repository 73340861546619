import React, { useState } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  UseFieldArrayUpdate,
} from 'react-hook-form';

import { DatePicker, Divider, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import {
  AlertCircleIcon,
  ArrowLeftIcon,
  CalendarCogIcon,
  CheckCircleIcon,
  FileUpIcon,
  MoveRightIcon,
  PencilLineIcon,
  PlusIcon,
  Trash2Icon,
} from 'lucide-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/Tooltip';
import { FormTruck } from 'types/Truck';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { cn } from 'utils/shadcn';

import { TruckTextInput } from '../../TruckListFormFields';
import { TruckType } from '../../TruckListTab';
import {
  RedwoodTruckListInputs,
  TruckGroupByPickupDate,
} from './../RedwoodTruckListForm';

type RedwoodTruckFieldsetProps = {
  control: Control<RedwoodTruckListInputs, any>;
  errors: FieldErrors<RedwoodTruckListInputs>;
  trucksInGroup: FormTruck[]; // List of Trucks for current pickup date
  truckArrayWatcher: FormTruck[]; // List of all Trucks in RHF, used for getting it's index
  trucksArrayByPickupDate: TruckGroupByPickupDate;
  updateTruck: UseFieldArrayUpdate<RedwoodTruckListInputs, 'trucks'>;
  updateTruckPickupDate: (arrayIdx: number, newDate: string) => void;
  handleRemoveTruck: (idx: number) => void;
};

export default function RedwoodTruckFieldset({
  control,
  errors,
  trucksInGroup,
  truckArrayWatcher,
  trucksArrayByPickupDate,
  updateTruck,
  updateTruckPickupDate,
  handleRemoveTruck,
}: RedwoodTruckFieldsetProps) {
  const posthog = usePostHog();
  const [dropdownNewDate, setDropdownNewDate] = useState<Dayjs>();

  const updatePickup = (truckId: number, date: string | Dayjs) =>
    updateTruckPickupDate(
      truckId,
      dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
    );

  const setIsEditing = (truckId: number, isEditing: boolean) => {
    const truck = truckArrayWatcher.find((t) => t.id === truckId);

    truck &&
      updateTruck(
        truckArrayWatcher.findIndex((t) => t.id === truckId),
        { ...truck, isEditing: isEditing }
      );
  };

  const moveDestinationItems = (t: FormTruck): ItemType[] => [
    ...Object.keys(trucksArrayByPickupDate)
      .sort((a, b) => dayjs(a).unix() - dayjs(b).unix())
      .map((date) => ({
        label: dayjs(date).format('MMM D - dddd'),
        key: `trucks-dropdown-${date}`,
        disabled: dayjs(date).isSame(t.pickupDate),
        onClick: () => handleMoveToExistingGroup(t.id, date),
      })),
  ];

  const handleMoveToExistingGroup = (id: number, existingDate: string) => {
    // Manually logging event since it's not triggered through a Button
    posthog?.capture(ButtonNamePosthog.MoveTruckToExistingPickupGroup);

    updatePickup(id, existingDate);
  };

  const handleMoveToNewGroup = (id: number, dropdownNewDate: Dayjs) => {
    // Manually logging event since it's not triggered through a Button
    posthog?.capture(ButtonNamePosthog.MoveTruckToNewPickupGroup);

    updatePickup(id, dropdownNewDate);
  };

  return trucksInGroup
    .sort((a, b) => a.id - b.id)
    .map((truck) => {
      // Truck index on useFieldArray, useful since we often reorder/group them
      const rhfFieldArrayIdx = truckArrayWatcher.findIndex(
        (t) => truck.id === t.id
      );
      const formErrors = errors?.trucks?.[rhfFieldArrayIdx];
      const isEmptyTruck =
        truck.pickupLocation.city === '' &&
        truck.pickupLocation.state === '' &&
        truck.dropoffLocation.city === '' &&
        truck.dropoffLocation.state === '';

      // Filter and organize Redwood errors in the Edit View
      const editViewRedwoodErrors = truck?.errors
        ? Object.entries(truck.errors)
            ?.filter(([_, v]) => Boolean(v) && v.length)
            .filter(([key, _]) => key != 'pickupDateErrors')
            .map(([key, value]) => ({ [key]: value }))
        : [];

      const hasPostingErrors = Object.values(editViewRedwoodErrors).some(
        (err) => err?.['postingErrors']?.length
      );

      return (
        <TooltipProvider key={truck.id}>
          <div
            className={cn(
              'mt-4 mb-2 border border-gray-600 rounded-[4px] p-3 bg-gray-100',
              !truck.isEditing ? 'flex flex-row-reverse justify-between' : ''
            )}
          >
            <div className='flex gap-3 justify-end items-center'>
              {!truck.isEditing &&
                (editViewRedwoodErrors.length || formErrors) && (
                  <Tooltip delayDuration={10}>
                    <TooltipTrigger asChild>
                      <AlertCircleIcon className='h-5 w-5 cursor-pointer stroke-red-main' />
                    </TooltipTrigger>
                    <TooltipContent>Truck has errors</TooltipContent>
                  </Tooltip>
                )}

              {!truck.isEditing && isEmptyTruck && (
                <Tooltip delayDuration={10}>
                  <TooltipTrigger asChild>
                    <AlertCircleIcon className='h-5 w-5 cursor-pointer stroke-red-main' />
                  </TooltipTrigger>
                  <TooltipContent>Truck content is empty</TooltipContent>
                </Tooltip>
              )}

              {truck.isEditing && hasPostingErrors && (
                <Tooltip delayDuration={10}>
                  <TooltipTrigger asChild>
                    <FileUpIcon className='h-5 w-5 cursor-pointer stroke-red-main' />
                  </TooltipTrigger>
                  <TooltipContent>
                    Error while posting truck to Redwood
                  </TooltipContent>
                </Tooltip>
              )}

              <Tooltip delayDuration={10}>
                {truck.isEditing ? (
                  <>
                    <TooltipTrigger asChild>
                      <ArrowLeftIcon
                        onClick={() => setIsEditing(truck.id, false)}
                        className='h-4 w-4 cursor-pointer stroke-grayscale-content-1'
                      />
                    </TooltipTrigger>
                    <TooltipContent>Quit Editing</TooltipContent>
                  </>
                ) : (
                  <>
                    <TooltipTrigger asChild>
                      <PencilLineIcon
                        onClick={() => setIsEditing(truck.id, true)}
                        className='h-4 w-4 cursor-pointer stroke-grayscale-content-1'
                      />
                    </TooltipTrigger>
                    <TooltipContent>Edit Truck</TooltipContent>
                  </>
                )}
              </Tooltip>

              <Tooltip delayDuration={10}>
                <TooltipTrigger asChild>
                  <div>
                    <Dropdown
                      menu={{ items: moveDestinationItems(truck) }}
                      dropdownRender={(menu) => (
                        <div className='bg-white rounded shadow-lg'>
                          {React.cloneElement(menu as React.ReactElement, {
                            style: { boxShadow: 'none' },
                          })}
                          <Divider className='my-2' />
                          <div className='flex items-center gap-2 px-3 py-2'>
                            <DatePicker
                              onChange={(date) => setDropdownNewDate(date)}
                              suffixIcon={null}
                              className='w-28'
                              placement='bottomLeft'
                              popupAlign={{
                                points: ['bc', 'tc'], // Align calendar bottom-center with trigger's top-center
                                offset: [-20, -20], // Offset by 20 pixels from the trigger for breathing room
                              }}
                            />
                            <Button
                              variant='titlebarActionIcon'
                              size='sm'
                              className='h-7 w-7 p-0 border-0 bg-orange-hover text-white'
                              onClick={() =>
                                dropdownNewDate &&
                                handleMoveToNewGroup(truck.id, dropdownNewDate)
                              }
                            >
                              <PlusIcon className='h-4 w-4' />
                            </Button>
                          </div>
                        </div>
                      )}
                      trigger={['click']}
                    >
                      <CalendarCogIcon className='h-[18px] w-4 cursor-pointer stroke-grayscale-content-1' />
                    </Dropdown>
                  </div>
                </TooltipTrigger>
                <TooltipContent>Change Date</TooltipContent>
              </Tooltip>

              <Tooltip delayDuration={10}>
                <TooltipTrigger asChild>
                  <Trash2Icon
                    className='h-4 w-4 cursor-pointer stroke-grayscale-content-1'
                    onClick={() => handleRemoveTruck(rhfFieldArrayIdx)}
                  />
                </TooltipTrigger>
                <TooltipContent>Delete Truck</TooltipContent>
              </Tooltip>
            </div>

            {truck.isEditing ? (
              <div>
                <div className='grid grid-cols-3 gap-4 mt-2 mx-0 w-full'>
                  <div className='col-span-2'>
                    <Label
                      name={`trucks.${rhfFieldArrayIdx}.type`}
                      required={true}
                    >
                      Truck Type
                    </Label>
                    <Controller
                      name={`trucks.${rhfFieldArrayIdx}.type`}
                      control={control}
                      rules={{ required: 'Required' }}
                      render={({ field }) => {
                        const truckTypeError = errors?.trucks?.[
                          rhfFieldArrayIdx
                        ]?.type as FieldError;

                        return (
                          <>
                            <Select
                              onValueChange={field.onChange}
                              value={field.value as string}
                            >
                              <SelectTrigger
                                className={cn(
                                  `w-full mt-1 h-[30px]`,
                                  truckTypeError && 'bg-red-50'
                                )}
                              >
                                <SelectValue placeholder={'Choose'} />
                              </SelectTrigger>
                              <SelectContent>
                                {Object.values(TruckType).map((option) => (
                                  <SelectItem key={option} value={option}>
                                    {option}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>

                            {truckTypeError ? (
                              <p className='text-red-500 text-xs mb-2'>
                                {truckTypeError.message}
                              </p>
                            ) : null}
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className='col-span-1'>
                    <TruckTextInput
                      name={`trucks.${rhfFieldArrayIdx}.length`}
                      label='Length'
                    />
                  </div>
                </div>

                <div className='grid grid-cols-3 gap-4 mt-2 mx-0 w-full'>
                  <div className='col-span-2'>
                    <TruckTextInput
                      name={`trucks.${rhfFieldArrayIdx}.pickupLocation.city`}
                      label='Origin City'
                      required
                    />
                  </div>

                  <div className='col-span-1'>
                    <TruckTextInput
                      name={`trucks.${rhfFieldArrayIdx}.pickupLocation.state`}
                      label='State'
                      required
                    />
                  </div>
                </div>

                <div className='grid grid-cols-3 gap-4 my-2 mx-0 w-full'>
                  <div className='col-span-2 relative'>
                    <TruckTextInput
                      name={`trucks.${rhfFieldArrayIdx}.dropoffLocation.city`}
                      label='Dropoff City'
                    />
                    {truck.dropoffIsCarrierDomicile && (
                      <CarrierDomicileTooltip />
                    )}
                  </div>
                  <div className='col-span-1 relative'>
                    <TruckTextInput
                      name={`trucks.${rhfFieldArrayIdx}.dropoffLocation.state`}
                      label='State'
                    />
                    {truck.dropoffIsCarrierDomicile && (
                      <CarrierDomicileTooltip />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex gap-2 justify-between items-center mr-2'>
                <div>
                  {isEmptyTruck ? (
                    <p className='text-[12px]'>Empty Truck</p>
                  ) : (
                    <>
                      <p className='text-[12px]'>
                        {_.capitalize(truck.type)}
                        {truck.length && ` ${truck.length} ft`}
                      </p>
                      <p className='text-[12px]'>
                        {truck.pickupLocation.city},{' '}
                        {truck.pickupLocation.state}
                        {truck.dropoffLocation.city &&
                          truck.dropoffLocation.state && (
                            <>
                              <MoveRightIcon className='stroke-[1px] h-4 w-4 inline-block mx-1' />
                              {truck.dropoffLocation.city},{' '}
                              {truck.dropoffLocation.state}
                            </>
                          )}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </TooltipProvider>
      );
    });
}

const CarrierDomicileTooltip = () => (
  <div className='absolute top-8 right-2'>
    <Tooltip delayDuration={10}>
      <TooltipTrigger asChild>
        <CheckCircleIcon className='h-[14px] w-[14px] text-blue-main' />
      </TooltipTrigger>
      <TooltipContent className='w-60'>
        This address has been populated automatically with the carrier's
        domicile.
      </TooltipContent>
    </Tooltip>
  </div>
);
