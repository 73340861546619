import { useState } from 'react';

import { Loader2, MailCheckIcon, MailPlusIcon } from 'lucide-react';

import useFetchCarrierVerificationRequest from 'hooks/useFetchCarrierVerification';
import { useToast } from 'hooks/useToaster';
import FreightValidateLogo from 'icons/FreightValidateLogo';
import HighwayLogo from 'icons/HighwayLogo';
import MCPLogo from 'icons/MCPLogo';
import TruckstopLogo from 'icons/TruckstopLogo';
import { inviteCarrier } from 'lib/api/inviteCarrier';
import { CarrierVerification } from 'types/CarrierVerification';
import { Maybe } from 'types/UtilityTypes';
import { CarrierVerification as CarrierVerificationEnum } from 'types/enums/Integrations';

import { Button } from './Button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from './Card';
import { Skeleton } from './Skeleton';

interface CarrierVerificationCardProps {
  fromEmail: string;
}

export default function CarrierVerificationCard({
  fromEmail,
}: CarrierVerificationCardProps) {
  const { carrier, isLoading } = useFetchCarrierVerificationRequest(fromEmail);
  const { toast } = useToast();
  const [inviteLoading, setInviteLoading] = useState(false);

  function borderColor(carrier: Maybe<CarrierVerification>): string {
    if (!carrier) return 'gray';
    switch (carrier.integration_name) {
      case CarrierVerificationEnum.FreightValidate: {
        const rating = carrier.risk_rating.toLowerCase();
        switch (true) {
          case rating.includes('none'):
            return 'red';
          case rating.includes('warning'):
            return 'orange';
          default:
            return 'green';
        }
      }

      case CarrierVerificationEnum.Highway: {
        const highwayRating = carrier.risk_rating.toLowerCase();
        switch (true) {
          case highwayRating.includes('not authorized'):
            return 'red';
          case highwayRating.includes('review required'):
            return 'orange';
          default:
            return '#f8c617';
        }
      }

      case CarrierVerificationEnum.MyCarrierPortal: {
        const mcpRating = carrier.risk_rating.toLowerCase();
        switch (true) {
          case mcpRating.includes('unacceptablefail'):
            return 'red';
          case mcpRating.includes('unacceptablereview'):
            return 'orange';
          default:
            return '#4786FE';
        }
      }
      case CarrierVerificationEnum.Truckstop: {
        const truckstopRating = carrier.risk_rating.toLowerCase();
        switch (true) {
          case truckstopRating.includes('unauthorized'):
            return 'red';
          case truckstopRating.includes('unacceptablereview'):
            return 'orange';
          default:
            return '#D90119';
        }
      }
    }

    return 'gray';
  }

  function reviewText(carrier: Maybe<CarrierVerification>): string {
    if (!carrier) return '';
    switch (carrier.integration_name) {
      case CarrierVerificationEnum.Highway:
      case CarrierVerificationEnum.FreightValidate:
      case CarrierVerificationEnum.Truckstop:
        return carrier.risk_rating;

      case CarrierVerificationEnum.MyCarrierPortal: {
        const mcpReviewRating = carrier.risk_rating.toLowerCase();
        switch (true) {
          case mcpReviewRating.includes('unacceptablefail'):
            return 'Unacceptable Fail';
          case mcpReviewRating.includes('unacceptablereview'):
            return 'Review Required';
          default:
            return '';
        }
      }
    }
    return '';
  }

  function cardActions(carrier: Maybe<CarrierVerification>) {
    if (!carrier) return { viewProfile: null, invite: null };

    const isUnauthorized = (() => {
      const rating = carrier.risk_rating.toLowerCase();
      switch (carrier.integration_name) {
        case CarrierVerificationEnum.Highway:
          return rating.includes('not authorized');
        case CarrierVerificationEnum.MyCarrierPortal:
          return rating.includes('unacceptablefail');
        case CarrierVerificationEnum.FreightValidate:
          return rating.includes('warning');
        case CarrierVerificationEnum.Truckstop:
          return rating.includes('unauthorized');
        default:
          return false;
      }
    })();

    const profileUrl = (() => {
      switch (carrier.integration_name) {
        case CarrierVerificationEnum.MyCarrierPortal:
          return `https://mycarrierpackets.com/CarrierInformation/DOTNumber/${carrier.dot_number}/DocketNumber/${carrier.docket_number}`;
        case CarrierVerificationEnum.Highway:
          return `https://highway.com/carriers/${carrier.dot_number}`;
        case CarrierVerificationEnum.FreightValidate:
          return `https://freightvalidate.com/carrier/${carrier.dot_number}`;
        case CarrierVerificationEnum.Truckstop:
          return `https://truckstop.com/carrier/${carrier.dot_number}`;
        default:
          return '';
      }
    })();

    const viewProfile = profileUrl ? (
      <a
        key='View Profile'
        className='group flex items-center justify-center gap-2 h-11 px-6 w-40 rounded-[4px] text-sm font-medium border bg-white text-black hover:bg-gray-100'
        href={profileUrl}
        target='_blank'
        rel='noopener noreferrer'
      >
        {(() => {
          switch (carrier.integration_name) {
            case CarrierVerificationEnum.MyCarrierPortal:
              return (
                <MCPLogo
                  fill='#4786FE'
                  className='group-hover:fill-gray-500 transition-colors'
                  width={20}
                  height={20}
                />
              );
            case CarrierVerificationEnum.Highway:
              return (
                <HighwayLogo
                  fill='#F8C617'
                  className='group-hover:fill-gray-500 transition-colors'
                  width={20}
                  height={20}
                />
              );
            case CarrierVerificationEnum.FreightValidate:
              return (
                <FreightValidateLogo
                  fill='#4786FE'
                  className='group-hover:fill-gray-500 transition-colors'
                  width={20}
                  height={20}
                />
              );
            case CarrierVerificationEnum.Truckstop:
              return (
                <TruckstopLogo
                  fill='#D90119'
                  className='group-hover:fill-gray-500 transition-colors'
                  width={20}
                  height={20}
                />
              );
            default:
              return null;
          }
        })()}
        View Profile
      </a>
    ) : null;

    const invite =
      !isUnauthorized &&
      carrier?.integration_name == CarrierVerificationEnum.MyCarrierPortal &&
      !carrier?.completed_packet ? (
        <Button
          key='Invite'
          onClick={onInvite}
          disabled={inviteLoading}
          variant='outline'
          className='w-40 text-sm'
        >
          {inviteLoading ? (
            <Loader2 className='h-4 w-4 animate-spin mr-2' />
          ) : carrier?.completed_packet ? (
            <>
              <MailCheckIcon className='h-4 w-4 mr-2' />
              In Network
            </>
          ) : (
            <>
              <MailPlusIcon className='h-4 w-4 mr-2' />
              Invite
            </>
          )}
        </Button>
      ) : null;

    return { viewProfile, invite };
  }

  async function onInvite() {
    setInviteLoading(true);

    const res = await inviteCarrier({
      dot_number: carrier?.dot_number.toString() || '',
      email: fromEmail,
    });

    if (res.isOk()) {
      toast({
        description: 'Carrier invited.',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setInviteLoading(false);
  }

  const carrierNotFound = !carrier && !isLoading;

  return (
    <div className='flex flex-row px-4 items-center'>
      <Card
        className='flex-grow w-[300px] mt-4 relative rounded-[4px]'
        style={{ borderColor: borderColor(carrier) }}
      >
        {carrierNotFound ? (
          <CardContent className='pt-6'>No carrier found.</CardContent>
        ) : isLoading ? (
          <CardContent className='space-y-2 pt-6'>
            <Skeleton className='h-4 w-[250px]' />
            <Skeleton className='h-4 w-[200px]' />
          </CardContent>
        ) : (
          <>
            <CardHeader className='space-y-1 pb-2'>
              {carrier?.risk_rating.toLowerCase().includes('unacceptable') && (
                <p className='uppercase text-sm font-bold text-center text-red-600'>
                  {reviewText(carrier)}
                </p>
              )}
              {carrier?.integration_name ==
                CarrierVerificationEnum.Truckstop && (
                <p className='text-sm'>{carrier.risk_rating}</p>
              )}
              <CardTitle className='text-base'>{carrier?.name}</CardTitle>
            </CardHeader>
            <CardContent className='grid grid-cols-2 gap-2 text-sm w-full mx-0'>
              {carrier?.dba && (
                <div className='col-span-2'>
                  <p className='text-muted-foreground'>DBA</p>
                  <p>{carrier.dba}</p>
                </div>
              )}
              <div>
                <p className='text-sm font-semibold leading-none'>
                  DOT:{' '}
                  <span className='font-normal'>{carrier?.dot_number}</span>
                </p>
              </div>
              <div>
                <p className='text-sm font-semibold leading-none'>
                  MC:{' '}
                  <span className='font-normal'>{carrier?.docket_number}</span>
                </p>
              </div>
            </CardContent>
            {!carrierNotFound && !isLoading && (
              <CardFooter className='flex justify-center gap-2 p-4 pt-0 mt-0'>
                {Object.values(cardActions(carrier)).filter(Boolean)}
              </CardFooter>
            )}
          </>
        )}
      </Card>
    </div>
  );
}
