import { useEffect, useState } from 'react';
import { Controller, FieldPath, useFormContext } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import flatten from 'flat';
import { XCircleIcon } from 'lucide-react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import { DatePicker } from 'components/DatePicker';
import { Label } from 'components/Label';
import { RHFTextInput } from 'components/input/RHFTextInput';

import { RedwoodTruckListInputs } from './Redwood/RedwoodTruckListForm';

type TruckTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: FieldPath<RedwoodTruckListInputs>;
};

export const TruckTextInput = (props: TruckTextInputProps) => {
  const [hasError, setHasError] = useState(false);
  const formContext = useFormContext();

  useEffect(() => {
    if (formContext && props.name) {
      const {
        formState: { errors },
      } = formContext;
      const errorFormNames = Object.keys(flatten(errors));

      // Since RHF's error objects have values for type, message and ref, flattening them will result in a path like
      // 'trucks.<idx>.pickupLocation.city.ref'. Hence why we use .startWith() to match with the input name.
      setHasError(errorFormNames.some((err) => err.startsWith(props.name)));
    }
  }, [formContext]);

  return (
    <RHFTextInput
      inputClassName='h-[30px] text-[12px]'
      highlightError={hasError}
      {...props}
    />
  );
};

type TruckDateTimeInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: `${string}.pickupDate` | `${string}.dropoffDate`;
  label: string;
  hasPickupDateError?: boolean;
  includeTime?: boolean;
};

export const TruckDateTimeInput = ({
  name,
  label,
  hasPickupDateError,
  required,
}: TruckDateTimeInputProps) => {
  const [hasError, setHasError] = useState(false);
  const {
    control,
    formState: { errors },
  } = useFormContext<Record<string, any>>();

  useEffect(() => {
    if (errors && name) {
      const errorFormNames = Object.keys(flatten(errors));

      // Since RHF's error objects have values for type, message and ref, flattening them will result in a path like
      // 'trucks.<idx>.dropoffDate.ref'. Hence why we use .startWith() to match with the input name.
      setHasError(
        hasPickupDateError || errorFormNames.some((err) => err.startsWith(name))
      );
    }
  }, [errors]);

  return (
    <div className='mt-2'>
      <Label
        name={name}
        required={required}
        className='text-grayscale-content-label text-sm'
      >
        {label}
      </Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className='mt-1 flex flex-row gap-1'>
            <div className='flex gap-4 flex-1'>
              <DatePicker highlightError={hasError} field={field} />
            </div>
            {field.value && (
              <button
                title='Clear date'
                onClick={() => field.onChange(null)}
                className='h-9 flex items-center justify-center'
              >
                <XCircleIcon className='w-4 h-4' />
              </button>
            )}
          </div>
        )}
      />
    </div>
  );
};

type TruckListSectionAccordionItemProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  contentClassName?: string;
  triggerClassName?: string;
  label: string;
  name: string;
};

export function TruckListSectionAccordionItem({
  icon,
  children,
  contentClassName = '',
  name,
  label,
}: TruckListSectionAccordionItemProps) {
  return (
    <AccordionItem value={name}>
      <AccordionTrigger className='hover:no-underline' icon={icon}>
        {label}
      </AccordionTrigger>
      <AccordionContent className={contentClassName}>
        {children}
      </AccordionContent>
    </AccordionItem>
  );
}

export type TruckFieldType = 'text' | 'location' | 'date' | 'select';

type TruckFieldProps = {
  label: string;
  type: TruckFieldType;
};

export const truckFieldMap: Record<string, TruckFieldProps> = {
  pickupLocation: { label: 'Pickup', type: 'location' },
  pickupDate: { label: 'Pickup Date', type: 'date' },
  dropoffLocation: { label: 'Dropoff', type: 'location' },
  dropoffDate: { label: 'Dropoff Date', type: 'date' },
  type: { label: 'Type', type: 'text' },
  length: { label: 'Length', type: 'text' },
};
