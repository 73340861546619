import _ from 'lodash';
import {
  BadgeDollarSignIcon,
  CircleCheckIcon,
  CoinsIcon,
  InfoIcon,
  ShieldCheckIcon,
} from 'lucide-react';

import QuoteConfidenceLevel from 'components/QuoteConfidenceLevel';
import { SelectedCarrierType } from 'lib/api/getQuickQuote';
import { DATQuoteLocationType } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import { cn } from 'utils/shadcn';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';

export type TooltipContentType = {
  timeframe: string;
  originName: string;
  originType: DATQuoteLocationType;
  destinationName: string;
  destinationType: DATQuoteLocationType;
};

export type PriceRangeType = {
  lowEstimate: number;
  highEstimate: number;
};

export type CarrierCardType = {
  type: SelectedCarrierType; // e.g. NETWORK, BUYPOWER, DAT
  title: string; // e.g. "Network Quote"
  icon: React.ReactNode; // e.g. <GreenscreensLogo />
  cost: number; // e.g. 1125 (flat number, not a function)
  priceRange: Maybe<PriceRangeType>; // e.g. $1125 - $1350
  confidence: Maybe<number>; // e.g. 85 (confidence out of 100)
  tooltipContent: MaybeUndef<TooltipContentType>; // e.g. Misc info about the carrier values
  costPerMile?: number; // e.g. 2.10 (flat number, not a function)
  priceRangePerMile?: Maybe<PriceRangeType>; // e.g. $2.05 - $2.15
};

type CarrierCardProps = {
  carrier: CarrierCardType;
  isSelected: boolean;
  onClick: () => void;
  lowConfidenceThreshold: number;
  mediumConfidenceThreshold: number;
};

export const CarrierCard = ({
  carrier,
  isSelected,
  onClick,
  lowConfidenceThreshold,
  mediumConfidenceThreshold,
}: CarrierCardProps) => {
  return (
    <TooltipProvider>
      <div
        key={carrier.type}
        className={cn(
          'relative flex-column w-full border-2 border-[#FE9659] rounded-[4px] p-2 transition-transform',
          isSelected
            ? 'bg-orange-bg'
            : 'cursor-pointer hover:scale-[1.05] bg-white'
        )}
        onClick={onClick}
      >
        <div className='flex justify-between items-center'>
          <div className='flex gap-2'>
            <h4>{carrier.title}</h4>

            {carrier.tooltipContent && (
              <Tooltip delayDuration={10}>
                <TooltipTrigger onClick={(e: any) => e.preventDefault()}>
                  <InfoIcon className='h-4 w-4 text-grayscale-content-input' />
                </TooltipTrigger>
                <TooltipContent className='ml-2 max-w-60 whitespace-pre-wrap'>
                  <p>
                    Rate based on a
                    <b>{` ${carrier.tooltipContent.timeframe} `}</b>
                    timeframe from
                    <b>{` ${parseDATLocationDetails(DATQuoteLocationType['3_DIGIT_ZIP'], '123Xx')} `}</b>
                    to
                    <b>{` ${parseDATLocationDetails(carrier.tooltipContent.destinationType, carrier.tooltipContent.destinationName)}`}</b>
                  </p>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
          {carrier.icon}
        </div>
        <div className='text-[#444] text-[14px]'>
          <div className='flex justify-between mt-2'>
            <div className='flex items-center gap-x-1.5'>
              <BadgeDollarSignIcon className='h-4 w-4' />
              Target Buy
            </div>
            <div className='flex items-baseline gap-x-2'>
              {`$${_.round(carrier.cost)}`}
              {carrier.costPerMile && (
                <p className='text-xs'>{`($${carrier.costPerMile}/mi)`}</p>
              )}
            </div>
          </div>
          <div className='flex justify-between'>
            {carrier.confidence && (
              <>
                <div className='flex items-center gap-x-1.5'>
                  <ShieldCheckIcon className='h-4 w-4' />
                  Confidence
                </div>
                <QuoteConfidenceLevel
                  confidence={carrier.confidence}
                  lowConfidenceThreshold={lowConfidenceThreshold}
                  mediumConfidenceThreshold={mediumConfidenceThreshold}
                />
              </>
            )}
          </div>
          <div className='flex justify-between'>
            {carrier.priceRange && (
              <>
                <div className='flex items-center gap-x-1.5'>
                  <CoinsIcon className='h-4 w-4' />
                  Price Range
                </div>
                {carrier.priceRangePerMile ? (
                  <Tooltip delayDuration={10}>
                    <TooltipTrigger className='border-b border-dashed border-black'>
                      {`$${carrier.priceRange.lowEstimate} - $${carrier.priceRange.highEstimate}`}
                    </TooltipTrigger>
                    <TooltipContent className='ml-2 max-w-60 whitespace-pre-wrap'>
                      <p>
                        {`The price range per mile for this carrier is ` +
                          `$${carrier.priceRangePerMile.lowEstimate}/mi to ` +
                          `$${carrier.priceRangePerMile.highEstimate}/mi.`}
                      </p>
                      <br />
                      <p>All prices include fuel.</p>
                    </TooltipContent>
                  </Tooltip>
                ) : (
                  <p>{`$${carrier.priceRange.lowEstimate} - $${carrier.priceRange.highEstimate}`}</p>
                )}
              </>
            )}
          </div>

          {isSelected && (
            <CircleCheckIcon className='absolute -top-4 -right-3 fill-white stroke-green-500 w-6 h-6 mt-1' />
          )}
        </div>
      </div>
    </TooltipProvider>
  );
};

const parseDATLocationDetails = (
  type: DATQuoteLocationType,
  name: string
): string => {
  switch (type) {
    case DATQuoteLocationType['3_DIGIT_ZIP']:
      return `${name.toUpperCase()}`;
    case DATQuoteLocationType['MARKET_AREA']:
      return `${name.replace(' Mkt', '')} Market Area`;
    case DATQuoteLocationType['EXTENDED_MARKET_AREA']:
      return `${name.replace(' X-Mkt', '')} Extended Market Area`;
    case DATQuoteLocationType['STATE']:
      return `${name} State`;
    case DATQuoteLocationType['REGION']:
      return `${name} Region`;
    case DATQuoteLocationType['COUNTRY']:
      return `${name} Country`;
    default:
      return '';
  }
};
