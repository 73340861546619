import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import _ from 'lodash';

import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { DatePicker } from 'components/DatePicker';
import { GenericLineChart } from 'components/GenericLineChart';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import CarrierPriceCalculator from 'components/pricing/CarrierPriceCalculator';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import {
  DrumkitPlatform,
  SidebarStateContext,
} from 'contexts/sidebarStateContext';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { getCustomers } from 'lib/api/getCustomers';
import { LaneHistoryResponse } from 'lib/api/getLaneHistory';
import {
  QuickQuoteInputs,
  QuickQuoteResponse,
  SelectedCarrierType,
  TransportType,
  getQuickQuote,
} from 'lib/api/getQuickQuote';
import { submitFinalQuote } from 'lib/api/submitFinalQuote';
import { submitQuoteToTMS } from 'lib/api/submitQuoteToTMS';
import { updateQuoteRequestSuggestion } from 'lib/api/updateQuoteRequestSuggestion';
import { createMailClientInstance } from 'lib/mailclient/interface';
import { Email } from 'types/Email';
import { TMSCustomer } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import Pageview from 'types/enums/Pageview';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';
import { chartConfig } from 'utils/laneHistoryChart';
import {
  GenericCompanySearchableFields,
  customerSearchHandler,
  mapCustomerToAntdOptions,
} from 'utils/loadInfoAndBuilding';

import {
  CarrierCard,
  CarrierCardType,
} from '../../../../components/CarrierCard';
import { formatCurrency } from '../ReviewCarrierNetworkQuotes';
import { useHelperFunctions } from './helperFunctions';
import { MarginType, QuickQuoteTextInputProps } from './types';

export const QuickQuoteTextInput = (props: QuickQuoteTextInputProps) => (
  <RHFTextInput {...props} />
);

export default function QuickQuoteSection({ email }: { email: Maybe<Email> }) {
  const { toast } = useToast();
  const [customers, setCustomers] = useState<Maybe<TMSCustomer[]>>(null);
  const [initialCustomers, setInitialCustomers] =
    useState<Maybe<TMSCustomer[]>>(null);
  const [quote, setQuote] = useState<Maybe<QuickQuoteResponse>>(null);
  const [hasThirdPartyQuoteURLs, setHasThirdPartyQuoteURLs] =
    useState<boolean>(false);
  const [laneHistory, setLaneHistory] =
    useState<Maybe<LaneHistoryResponse[]>>(null);
  const [quoteNotConfident, setQuoteNotConfident] = useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] = useState<SelectedCarrierType>(
    SelectedCarrierType.NETWORK
  );
  const [carrierCards, setCarrierCards] = useState<CarrierCardType[]>([]);
  const [carrierCost, setCarrierCost] = useState(0);
  const [marginType, setMarginType] = useState<MarginType>(
    MarginType.Percentage
  );
  const [margin, setMargin] = useState(
    marginType === MarginType.Flat ? 100 : 10
  );
  const [finalPrice, setFinalPrice] = useState(
    carrierCost * (1 + margin / 100)
  );
  const [draftResponse, setDraftResponse] = useState('');
  const [loadingDraftReply, setLoadingDraftReply] = useState(false);
  const [isSubmitToTMS, setIsSubmitToTMS] = useState<boolean>(false);
  const [createdQuoteId, setCreatedQuoteId] = useState<Maybe<number>>();
  const [tmsTenant, setTMSTenant] = useState<Maybe<string>>();

  const [greenscreensQuoteID, setGreenscreensQuoteID] = useState<string>('');

  const scrollResultsIntoViewRef = useRef<HTMLDivElement>(null);

  const {
    currentState: {
      drumkitPlatform,
      threadItemId,
      isOutlookReply,
      clickedSuggestion,
      goToSuggestionInCarousel,
      curSuggestionList,
    },
    setCurrentState,
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: {
      isTMSQuoteSubmissionEnabled,
      isQuoteLaneHistoryEnabled,
      isQuoteSubmissionViaURLEnabled,
      isQuoteSubmissionToServiceEnabled,
      isGetLaneRateFromServiceEnabled,
    },
    serviceID,
    tmsIntegrations,
  } = useServiceFeatures();

  useLogPostHogPageView(Pageview.QuickQuote, {
    service_id: serviceID,
  });

  const memoizedDefaultValues = useMemo<QuickQuoteInputs>(() => {
    const suggestedFields = clickedSuggestion?.suggested as QuoteChanges;

    const parsedValues = {
      transportType: suggestedFields?.transportType ?? '',
      pickupDate: suggestedFields?.pickupDate,
      deliveryDate: suggestedFields?.deliveryDate,
      stops: [
        {
          city: suggestedFields?.pickupCity ?? '',
          state: suggestedFields?.pickupState ?? '',
          zip: suggestedFields?.pickupZip ?? '',
        },
        {
          city: suggestedFields?.deliveryCity ?? '',
          state: suggestedFields?.deliveryState ?? '',
          zip: suggestedFields?.deliveryZip ?? '',
        },
      ],
      customerName: '',
      isSubmitToTMS: false,
    };

    // Only need to fetch customers when submitting quotes is supported
    if (
      isTMSQuoteSubmissionEnabled &&
      drumkitPlatform === DrumkitPlatform.Outlook
    ) {
      useHelperFunctions.fetchCustomers({
        setInitialCustomers,
        setCustomers,
        setTMSTenant,
        tmsIntegrations,
      });
    }

    return parsedValues as QuickQuoteInputs;
  }, [clickedSuggestion]);

  const formMethods = useForm<QuickQuoteInputs>({
    defaultValues: memoizedDefaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    resetField,
    formState: { errors, isSubmitting },
  } = formMethods;

  const handleRefreshCustomers = async () => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id, true);
    if (res.isOk()) {
      setInitialCustomers(res.value.customerList);
      setCustomers(res.value.customerList);
      toast({
        description: 'Successfully refreshed customer list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing customer list.',
        variant: 'destructive',
      });
    }
  };

  const handleResetCustomerSearch = () => {
    setCustomers(initialCustomers);
  };

  const handleCustomerSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return customerSearchHandler({
      tmsID: tmsIntegrations?.[0]?.id,
      customers,
      setCustomers,
      field,
      value,
    });
  };

  // Setting default carrier
  useEffect(() => {
    if (!carrierCards?.length) return;

    const hasDATCarrier = carrierCards.find(
      (c) => c.type === SelectedCarrierType.DAT
    );
    const defaultCarrier =
      hasDATCarrier ??
      carrierCards.reduce((prev, curr) =>
        prev.cost < curr.cost ? prev : curr
      );

    setSelectedCarrier(defaultCarrier.type);
    setCarrierCost(_.round(defaultCarrier.cost));
  }, [carrierCards]);

  const onSubmitForm: SubmitHandler<QuickQuoteInputs> = async (formValues) => {
    await useHelperFunctions.onSubmitForm({
      getValues,
      formValues,
      setIsSubmitToTMS,
      setCreatedQuoteId,
      setQuote,
      setCarrierCards,
      isQuoteSubmissionViaURLEnabled,
      email,
      setHasThirdPartyQuoteURLs,
      setValue,
      isGetLaneRateFromServiceEnabled,
      clickedSuggestion,
      formMethods,
      setQuoteNotConfident,
      getQuickQuote,
      isQuoteLaneHistoryEnabled,
      setLaneHistory,
      setCarrierCost,
      setMargin,
      marginType,
      setError,
    });
  };

  useEffect(() => {
    // Go to first quick quote suggestion in carousel
    if (!clickedSuggestion) {
      goToSuggestionInCarousel({
        suggestionPipeline: SuggestionPipelines.QuickQuote,
      });
    }
  }, [curSuggestionList, clickedSuggestion]);

  useEffect(() => {
    if (!quote || !isQuoteSubmissionToServiceEnabled) return;
    useHelperFunctions.sendGreenscreensQuote({
      email,
      quote,
      setGreenscreensQuoteID,
    });
  }, [quote]);

  useEffect(() => {
    if (!quote) {
      const draft = `Thank you for your request from
      ${
        getValues('stops.0.city')
          ? `${useHelperFunctions.toTitleCase(getValues('stops.0.city'))}, ${getValues('stops.0.state')}`
          : getValues('stops.0.zip')
      }
        to
        ${
          getValues('stops.1.city')
            ? `${useHelperFunctions.toTitleCase(getValues('stops.1.city'))}, ${getValues('stops.1.state')}`
            : getValues('stops.1.zip')
        }
          on ${new Date(getValues('pickupDate')).toDateString()} for a
          ${useHelperFunctions.toTitleCase(getValues('transportType'))}. The rate would be
          ${formatCurrency(finalPrice, 'USD')}.`;
      setDraftResponse(draft);
      return;
    }

    const draft =
      `Thank you for your request from ` +
      `${useHelperFunctions.toTitleCase(quote.stops[0].city)}, ${quote?.stops[0].state} ` +
      `to ` +
      `${useHelperFunctions.toTitleCase(quote.stops[1].city)}, ${quote?.stops[1].state} ` +
      `on ` +
      `${new Date(getValues('pickupDate')).toDateString()} ` +
      `for a ` +
      `${getValues('transportType')?.toLowerCase()}. ` +
      `The rate would be ${formatCurrency(finalPrice, 'USD')}.`;

    setDraftResponse(draft);
  }, [quote, finalPrice]);

  // Update form values when suggestion changes
  useEffect(() => {
    if (memoizedDefaultValues) {
      reset(memoizedDefaultValues);
    }
    // Use setValue so as to not show AI-filled helper text
    if (!getValues('pickupDate')) {
      setValue('pickupDate', dayjs().add(1, 'day').toDate());
    }
    if (!getValues('deliveryDate')) {
      setValue('deliveryDate', dayjs().add(2, 'day').toDate());
    }
    if (!getValues('transportType')) {
      setValue('transportType', TransportType.VAN);
    }
  }, [memoizedDefaultValues]);

  useEffect(() => {
    if (quote || quoteNotConfident) {
      scrollResultsIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });

      // in order to get the ai label to show up, we need to reset the quoteNumber field with the value as a default value
      resetField('quoteNumber', { defaultValue: getValues('quoteNumber') });
    }
  }, [quote, quoteNotConfident]);

  let handleDraftResponse: () => void;
  if (drumkitPlatform === DrumkitPlatform.Outlook) {
    const mailClient = createMailClientInstance(drumkitPlatform);
    handleDraftResponse = async () => {
      if (!finalPrice || finalPrice <= 0) {
        return;
      }

      setLoadingDraftReply(true);

      await submitFinalQuote({
        id: quote?.id || 0,
        finalQuotePrice: _.round(finalPrice),
        finalMargin: margin,
        marginType: marginType,
        finalCarrierCost: carrierCost,
      });

      // Using separate API calls for draft and TMS Quote submission since the draft call is conditional
      // e.g. we create the draft directly on the client-side in Outlook's Compose View.
      if (isTMSQuoteSubmissionEnabled && isSubmitToTMS) {
        const customerId = getValues('customerName');
        if (!customerId) return;

        const res = await submitQuoteToTMS({
          customerId: customerId.toString(),
          quotePrice: _.round(finalPrice),
          transportType: getValues('transportType'),
          pickupLocationZip: getValues('stops.0.zip'),
          pickupLocationCity: getValues('stops.0.city'),
          pickupLocationState: getValues('stops.0.state'),
          pickupDate: new Date(getValues('pickupDate')).toISOString(),
          deliveryLocationZip: getValues('stops.1.zip'),
          deliveryLocationCity: getValues('stops.1.city'),
          deliveryLocationState: getValues('stops.1.state'),
          deliveryDate: new Date(getValues('deliveryDate')).toISOString(),
        });

        if (res.isOk()) {
          setCreatedQuoteId(res.value.quoteId);
        } else {
          toast({
            description: 'Error creating Quote in TMS.',
            variant: 'destructive',
          });
        }
      }

      try {
        await mailClient.draftReply({ threadItemId, draftBody: draftResponse });

        if (isQuoteSubmissionToServiceEnabled)
          useHelperFunctions.sendUserQuote({
            email,
            quote,
            greenscreensQuoteID,
            carrierCost,
            margin,
            finalPrice,
            draftResponse,
            marginType,
          });

        // only remove suggestion from list at the very end of a successful draft creation
        if (
          clickedSuggestion &&
          clickedSuggestion.pipeline === SuggestionPipelines.QuickQuote
        ) {
          const quoteInfo: QuoteChanges = {
            transportType: getValues('transportType'),
            pickupZip: getValues('stops.0.zip'),
            pickupCity: getValues('stops.0.city'),
            pickupState: getValues('stops.0.state'),
            pickupDate: new Date(getValues('pickupDate')),
            deliveryZip: getValues('stops.1.zip'),
            deliveryCity: getValues('stops.1.city'),
            deliveryState: getValues('stops.1.state'),
            deliveryDate: new Date(getValues('deliveryDate')),
          };
          await updateQuoteRequestSuggestion(
            clickedSuggestion.id,
            SuggestionStatus.Accepted,
            quoteInfo,
            quote?.id || 0
          );

          setCurrentState((prevState) => ({
            ...prevState,
            clickedSuggestion: null,
            curSuggestionList: prevState.curSuggestionList.filter(
              (s) => s.id !== clickedSuggestion.id
            ),
          }));
        }

        // When in Read View, there's a lag between when Outlook created the draft in the backend
        // and showing it in the client so wait for a moment before showing toaster.
        setTimeout(
          () => {
            toast({
              description: 'Successfully created draft reply.',
              variant: 'success',
            });

            setLoadingDraftReply(false);
          },
          isOutlookReply ? 1 : 3500
        );
      } catch (error: unknown) {
        // narrow type to safely access the message
        const errMsg = error instanceof Error ? error.message : String(error);

        toast({
          description: errMsg || 'Something went wrong creating draft reply',
          variant: 'destructive',
        });

        setLoadingDraftReply(false);
      }
    };
  }

  return (
    <div className='mb-4'>
      <ExtendedFormProvider aiDefaultValues={true}>
        <FormProvider {...formMethods}>
          <div>
            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className='grid gap-6 grid-cols-1 mt-4 mx-0 w-full'
            >
              <div className='grid gap-3 grid-cols-1 w-full mx-0 '>
                <div className='flex flex-row gap-5 items-center xxs:items-baseline justify-between'>
                  <div className='text-md whitespace-nowrap text-grayscale-content-label font-semibold mb-1'>
                    Load Information
                  </div>
                </div>
                <div>
                  <Label name={'transportType'} required={true}>
                    Transport Type
                  </Label>
                  <Controller
                    name='transportType'
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger className='w-full mt-1'>
                          <SelectValue placeholder='Choose' />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.keys(TransportType)
                            .filter((key) => key != TransportType.SPECIAL)
                            .map((option) => (
                              <SelectItem key={option} value={option}>
                                {option}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
              </div>

              {/* Pickup */}
              <div className='grid gap-3 grid-cols-1 w-full mx-0 '>
                <div className='text-md text-grayscale-content-label font-semibold mb-1'>
                  Pickup
                </div>

                <div className='flex items-start flex-row gap-1 w-full'>
                  <div className='w-full'>
                    <QuickQuoteTextInput
                      name='stops.0.location'
                      label='Location'
                      placeholder='e.g. 02116 or Boston, MA'
                      required
                    />
                  </div>
                </div>

                <div className='flex flex-col w-full'>
                  <Label
                    name='pickupDate'
                    className='text-base'
                    required={true}
                  >
                    Date
                  </Label>
                  <Controller
                    name={`pickupDate`}
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <div className='mt-1 flex flex-row gap-1'>
                        <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                          <div className='col-span-2'>
                            <DatePicker field={field} />
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>

              {/* Dropoff */}
              <div className='grid gap-3 grid-cols-1 w-full mx-0 '>
                <div className='text-md text-grayscale-content-label font-semibold mb-1'>
                  Dropoff
                </div>

                <div className='flex items-start flex-row gap-1 w-full'>
                  <div className='w-full'>
                    <QuickQuoteTextInput
                      name='stops.1.location'
                      label='Location'
                      placeholder='e.g. 02116 or Boston, MA'
                      required
                    />
                  </div>
                </div>

                <div className='flex flex-col w-full'>
                  <Label
                    name='deliveryDate'
                    className='text-base'
                    required={true}
                  >
                    Date
                  </Label>
                  <Controller
                    name={`deliveryDate`}
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <div className='mt-1 flex flex-row gap-1'>
                        <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                          <div className='col-span-2'>
                            <DatePicker field={field} />
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>

              {errors.root && (
                <div className='flex justify-center'>
                  <div className='w-auto text-sm border-red-500 bg-red-500 text-neutral-50 dark:border-red-900 dark:bg-red-900 dark:text-neutral-50 rounded-lg p-2 text-center'>
                    {errors.root.message}
                  </div>
                </div>
              )}

              <Button
                buttonName={'Get Quick Quote'}
                buttonNamePosthog={ButtonNamePosthog.GetQuickQuote}
                type='submit'
                className='w-full'
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoader /> : 'Get Quick Quote'}
              </Button>

              {quote && !quoteNotConfident && (
                <div className='flex flex-col items-center py-2'>
                  <div className=' w-full text-left text-md text-grayscale-content-label font-semibold'>
                    Quote for
                  </div>
                  <h3 className='text-sm w-full text-left'>{`
                      ${useHelperFunctions.toTitleCase(quote.stops[0].city)}, ${quote.stops[0].state} to
                      ${useHelperFunctions.toTitleCase(quote.stops[1].city)}, ${quote.stops[1].state}
                    `}</h3>
                  {Math.max(
                    quote.networkLaneRateDistance,
                    quote.laneRateDistance
                  ) > 0 && (
                    <h3 className='text-sm w-full text-left'>
                      {`Distance: ${_.round(
                        Math.max(
                          quote.networkLaneRateDistance,
                          quote.laneRateDistance
                        )
                      )} miles`}
                    </h3>
                  )}

                  <div className='w-full h-0.5 bg-[#305645] mt-2' />

                  <div className='flex flex-col gap-4 mt-4 w-full'>
                    {carrierCards.map((card) => (
                      <CarrierCard
                        key={card.type}
                        carrier={card}
                        isSelected={selectedCarrier === card.type}
                        onClick={() => {
                          setSelectedCarrier(card.type);
                          setCarrierCost(_.round(card.cost));
                        }}
                        lowConfidenceThreshold={
                          quote.lowConfidenceThreshold || 70
                        }
                        mediumConfidenceThreshold={
                          quote.mediumConfidenceThreshold || 80
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
              {(quoteNotConfident || quote) && (
                <>
                  {isQuoteLaneHistoryEnabled ? (
                    laneHistory?.length ? (
                      (() => {
                        const highestAverageRate = Math.max(
                          ...laneHistory.map((item) => item.averageRate)
                        );

                        return (
                          <GenericLineChart
                            data={laneHistory}
                            title='Lane History'
                            description='Rates from the last four weeks'
                            chartConfig={chartConfig}
                            yAxisDomainMax={highestAverageRate + 100}
                            yAxisDomainMin={0}
                            yAxisWidth={highestAverageRate > 999 ? 45 : 35}
                            thirdTooltipLabel='Loads'
                            dataKeys={['averageRate', 'lowestRate']}
                          />
                        );
                      })()
                    ) : (
                      <div className='flex justify-center'>
                        <p className='text-sm text-grayscale-content-input'>
                          No Lane History Available
                        </p>
                      </div>
                    )
                  ) : null}
                  <CarrierPriceCalculator
                    showTitle={false}
                    finalPrice={finalPrice}
                    margin={margin}
                    marginType={marginType}
                    setMarginTypeHandler={setMarginType}
                    carrierCost={carrierCost}
                    setCarrierCostHandler={setCarrierCost}
                    setMarkupHandler={setMargin}
                    setFinalPriceHandler={setFinalPrice}
                  />
                </>
              )}
            </form>

            {quote && quote.belowThresholdMessage && (
              <div className='w-auto text-sm border-red-500 bg-red-500 text-neutral-50 dark:border-red-900 dark:bg-red-900 dark:text-neutral-50 rounded-lg p-2 mb-4 text-center'>
                {quote.belowThresholdMessage}
              </div>
            )}

            {(quote || quoteNotConfident) && (
              <div
                className='flex-col justify-center'
                ref={scrollResultsIntoViewRef}
              >
                {isQuoteSubmissionViaURLEnabled && hasThirdPartyQuoteURLs ? (
                  <>
                    <h3>Submission Hyperlink Detected</h3>
                    <div className='rounded bg-[#eaeaea] text-sm py-3 px-3 mt-1'>
                      <div className='w-full'>
                        <QuickQuoteTextInput
                          name='quoteNumber'
                          placeholder='Q000001'
                          label='Quote #'
                          required
                        />
                      </div>
                      <div className='w-full flex flex-row justify-between gap-2 mt-2'>
                        <div className='flex flex-col flex-1'>
                          <Label
                            name='quoteExpirationDate'
                            className='text-base'
                          >
                            Expiration
                          </Label>
                          <Controller
                            name={`quoteExpirationDate`}
                            control={control}
                            render={({ field }) => (
                              <div className='mt-1 flex flex-row gap-1'>
                                <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                                  <div className='col-span-2'>
                                    <DatePicker field={field} />
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                          {errors.quoteExpirationDate && (
                            <p className='text-red-500 text-xs'>
                              {errors.quoteExpirationDate.message}
                            </p>
                          )}
                        </div>
                        <div className='flex flex-col flex-1'>
                          <Label name='Eta' className='text-base'>
                            ETA
                          </Label>
                          <Controller
                            name={`quoteEta`}
                            control={control}
                            render={({ field }) => (
                              <div className='mt-1 flex flex-row gap-1'>
                                <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                                  <div className='col-span-2'>
                                    <DatePicker field={field} />
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                          {errors.quoteEta && (
                            <p className='text-red-500 text-xs'>
                              {errors.quoteEta.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h3>Draft Response</h3>
                    {quote ? (
                      <div className='rounded bg-[#eaeaea] text-sm py-3 px-3 mt-1'>
                        Thank you for your request from{' '}
                        {useHelperFunctions.toTitleCase(quote.stops[0].city)},{' '}
                        {quote?.stops[0].state} to{' '}
                        {useHelperFunctions.toTitleCase(quote.stops[1].city)},{' '}
                        {quote?.stops[1].state} on{' '}
                        {new Date(getValues('pickupDate')).toDateString()} for a{' '}
                        {useHelperFunctions.toTitleCase(
                          getValues('transportType')
                        )}
                        . The rate would be{' '}
                        <b>{formatCurrency(finalPrice, 'USD')}</b>.
                      </div>
                    ) : (
                      <div className='rounded bg-[#eaeaea] text-sm py-3 px-3 mt-1'>
                        Thank you for your request from{' '}
                        {getValues('stops.0.city')
                          ? `${useHelperFunctions.toTitleCase(getValues('stops.0.city'))}, ${getValues('stops.0.state')}`
                          : getValues('stops.0.zip')}{' '}
                        to{' '}
                        {getValues('stops.1.city')
                          ? `${useHelperFunctions.toTitleCase(getValues('stops.1.city'))}, ${getValues('stops.1.state')}`
                          : getValues('stops.1.zip')}{' '}
                        on {new Date(getValues('pickupDate')).toDateString()}{' '}
                        for a{' '}
                        {useHelperFunctions.toTitleCase(
                          getValues('transportType')
                        )}
                        . The rate would be{' '}
                        <b>{formatCurrency(finalPrice, 'USD')}</b>.
                      </div>
                    )}
                  </>
                )}

                {isTMSQuoteSubmissionEnabled ? (
                  <>
                    <div className='flex items-center space-x-2 mt-6'>
                      <Checkbox
                        onCheckedChange={() => setIsSubmitToTMS(!isSubmitToTMS)}
                        checked={isSubmitToTMS}
                      />
                      <label
                        htmlFor='isSubmitToTMS'
                        className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                      >
                        Submit Quote to TMS
                      </label>
                    </div>

                    {isSubmitToTMS ? (
                      <div className='mt-4'>
                        <RHFDebounceSelect
                          required={true}
                          name='customerName'
                          label='Customer'
                          control={control}
                          errors={errors}
                          data={customers}
                          isLoading={false}
                          showSearchParamDropdown={false}
                          refreshHandler={handleRefreshCustomers}
                          resetOptionsHandler={handleResetCustomerSearch}
                          fetchOptions={handleCustomerSearch}
                          mapOptions={mapCustomerToAntdOptions}
                        />
                        <a
                          className='underline text-[12px] text-orange-main'
                          target='_blank'
                          rel='noreferrer'
                          href={`https://app.turvo.com/#/${tmsTenant}/accounts/cards?type=shipper`}
                        >
                          or create a new customer.
                        </a>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {createdQuoteId ? (
                  <>
                    <div className='whitespace-pre-wrap my-3 rounded py-3 text-grayscale-content-label px-4 bg-green-bg'>
                      <p className='mb-2'>Quote Created 🎉</p>
                      <p className='mb-2 text-[14px]'>
                        <b className='text-[14px]'>Quote ID #: </b>
                        {createdQuoteId}
                      </p>
                      <p className='mb-1 text-[14px]'>
                        {/** Feature is only supported on Turvo for now */}
                        <a
                          className='underline'
                          target='_blank'
                          rel='noreferrer'
                          href={`https://app.turvo.com/#/${tmsTenant}/shipments/${createdQuoteId}/details`}
                        >
                          Access the created quote for more details
                        </a>
                      </p>
                    </div>
                  </>
                ) : null}

                {/** Only supported on Outlook for now */}
                {drumkitPlatform === DrumkitPlatform.Outlook ? (
                  isQuoteSubmissionViaURLEnabled && hasThirdPartyQuoteURLs ? (
                    <Button
                      className='w-full mt-4'
                      type='button'
                      disabled={loadingDraftReply}
                      onClick={() =>
                        useHelperFunctions.handleQuoteSubmissionViaURL({
                          email,
                          quote,
                          getValues,
                          setError,
                          setLoadingDraftReply,
                          finalPrice,
                          isTMSQuoteSubmissionEnabled,
                          isSubmitToTMS,
                          setCreatedQuoteId,
                          clickedSuggestion,
                          setCurrentState,
                        })
                      }
                    >
                      {loadingDraftReply ? (
                        <ButtonLoader />
                      ) : (
                        'Submit quote via URL'
                      )}
                    </Button>
                  ) : (
                    <Button
                      className='w-full mt-4'
                      type='button'
                      disabled={loadingDraftReply}
                      onClick={() => handleDraftResponse()}
                    >
                      {isOutlookReply ? (
                        'Add reply to current draft'
                      ) : loadingDraftReply ? (
                        <ButtonLoader />
                      ) : (
                        'Create reply draft'
                      )}
                    </Button>
                  )
                ) : null}
              </div>
            )}
          </div>
        </FormProvider>
      </ExtendedFormProvider>
    </div>
  );
}
