import { UseFormSetError } from 'react-hook-form';

import dayjs from 'dayjs';
import { flatMapDeep } from 'lodash';

import {
  CarrierInformation,
  Truck,
  TruckError,
  TruckListErrors,
} from 'types/Truck';
import { FlattenObjectKeys, Maybe, MaybeUndef } from 'types/UtilityTypes';

import {
  carrierContactErrorMessages,
  carrierErrorMessages,
  dropoffLocationErrorMessages,
  equipmentErrorMessages,
  pickupLocationErrorMessages,
  postingErrorMessages,
} from './RedwoodTruckListErrors';
import {
  RedwoodTruckListInputs,
  TruckGroupByPickupDate,
} from './RedwoodTruckListForm';

export const getCarrierForSubmit = (data: Record<string, any>) => ({
  name: data.carrier.name,
  mc: data.carrier.mc,
  dot: data.carrier.dot,
  contactEmail: data.carrier.contact.email,
  contactName: data.carrier.contact.name,
});

export const getTrucksForSubmit = (truckArrayWatcher: any[]) =>
  truckArrayWatcher.map((t) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { rhfId, arrayIdx, ...rest } = t;
    return { ...rest, length: Number(t.length) };
  });

export const getParsedErrors = (truckListErrors: TruckListErrors) => {
  const { carrierErrors, carrierContactErrors, truckErrors, postedByErrors } =
    truckListErrors;

  return {
    carrierErrorsCount: Object.keys(carrierErrors ?? {}).length,
    carrierContactErrorsCount: carrierContactErrors?.length ?? 0,
    postedByErrorsCount: Object.keys(postedByErrors ?? {}).length,
    truckErrorsCount: Object.values(truckErrors ?? {})
      .map((errs) => flatMapDeep(errs).length)
      .reduce((acc, errsCount) => acc + errsCount, 0),
  };
};

type ParseCarrierErrorsProps = {
  carrierErrors: MaybeUndef<Record<string, CarrierInformation[]>>;
  setShowCarrierSelector: React.Dispatch<React.SetStateAction<boolean>>;
  setError: UseFormSetError<RedwoodTruckListInputs>;
  setCarriers: React.Dispatch<React.SetStateAction<CarrierInformation[]>>;
};

type ParseTruckErrorsProps = {
  truckErrors: MaybeUndef<Record<string, TruckError>>;
  truckArrayWatcher: any[];
  trucksArrayByPickupDate: TruckGroupByPickupDate;
  setError: UseFormSetError<RedwoodTruckListInputs>;
};

type ParseCarrierContactErrorsProps = {
  carrierContactErrors: MaybeUndef<Maybe<string[]>>;
  setError: UseFormSetError<RedwoodTruckListInputs>;
};

export const parseCarrierErrors = ({
  carrierErrors,
  setError,
  setShowCarrierSelector,
  setCarriers,
}: ParseCarrierErrorsProps) => {
  if (carrierErrors) {
    for (const [error, carriers] of Object.entries(carrierErrors)) {
      switch (error) {
        case 'no-carriers-found':
          setError('carrier.contact.email', {
            type: 'value',
            message: carrierErrorMessages['no-carriers-found'].message,
          });
          break;
        case 'multiple-carriers-found':
          setCarriers(carriers);
          setShowCarrierSelector(true);
          break;
      }
    }
  } else {
    setShowCarrierSelector(false);
  }
};

type TruckFieldPath = FlattenObjectKeys<
  Omit<Truck, 'id' | 'rhfId' | 'arrayIdx'>
>;

export const parseTruckErrors = ({
  truckErrors,
  truckArrayWatcher,
  trucksArrayByPickupDate,
  setError,
}: ParseTruckErrorsProps) => {
  if (truckErrors) {
    for (const [truck, truckError] of Object.entries(truckErrors)) {
      const truckWithErrorsIdx = truckArrayWatcher.findIndex(
        (t) => t.id === Number(truck)
      );
      const truckWithErrors = truckArrayWatcher[truckWithErrorsIdx];
      if (!truckWithErrors) return;

      const truckWithErrorsPickupDate = dayjs(
        truckWithErrors.pickupDate
      ).format('YYYY-MM-DD');
      const truckWithErrorsGroup =
        trucksArrayByPickupDate[truckWithErrorsPickupDate as any];
      if (!truckWithErrorsGroup) return;

      if (truckError.pickupLocationErrors?.length) {
        truckError.pickupLocationErrors.forEach((err) => {
          const error = pickupLocationErrorMessages[err];
          if (error) {
            error.fields.forEach(({ name, type }) =>
              setError(
                `trucks.${truckWithErrorsIdx}.${name as TruckFieldPath}`,
                type === 'showText'
                  ? { type: 'value', message: error.message }
                  : {}
              )
            );
          }
        });
      }
      if (truckError.dropoffLocationErrors?.length) {
        truckError.dropoffLocationErrors.forEach((err) => {
          const error = dropoffLocationErrorMessages[err];
          if (error) {
            error.fields.forEach(({ name, type }) =>
              setError(
                `trucks.${truckWithErrorsIdx}.${name as TruckFieldPath}`,
                type === 'showText'
                  ? { type: 'value', message: error.message }
                  : {}
              )
            );
          }
        });
      }
      if (truckError.equipmentErrors?.length) {
        truckError.equipmentErrors.forEach((err) => {
          const error = equipmentErrorMessages[err];
          if (error) {
            error.fields.forEach(({ name, type }) =>
              setError(
                `trucks.${truckWithErrorsIdx}.${name as TruckFieldPath}`,
                type === 'showText'
                  ? { type: 'value', message: error.message }
                  : {}
              )
            );
          }
        });
      }
      if (truckError.postingErrors?.length) {
        truckError.postingErrors.forEach((err) => {
          const error = postingErrorMessages[err];
          if (error) {
            error.fields.forEach(({ name, type }) =>
              setError(
                `trucks.${truckWithErrorsIdx}.${name as TruckFieldPath}`,
                type === 'showText'
                  ? { type: 'value', message: error.message }
                  : {}
              )
            );
          }
        });
      }
    }
  }
};

export const parseCarrierContactErrors = ({
  carrierContactErrors,
  setError,
}: ParseCarrierContactErrorsProps) => {
  if (carrierContactErrors) {
    carrierContactErrors.forEach((err: any) => {
      const error = carrierContactErrorMessages[err];
      if (error) {
        error.fields.forEach(() =>
          setError(`carrier.contact.email`, {
            type: 'value',
            message: error.message,
          })
        );
      }
    });
  }
};

type ToastDetailsType = {
  description: string;
  variant: 'default' | 'destructive' | 'success';
};

export const getToastForCarrierErrors = (): ToastDetailsType => ({
  description: 'Carrier-related error(s) found. Please review carrier details.',
  variant: 'destructive',
});

export const getToastForTruckErrors = (
  truckErrorsCount: number,
  postedByErrorsCount: number
): ToastDetailsType => ({
  description: [
    'The submitted truck list has',
    truckErrorsCount
      ? `${truckErrorsCount} truck error${truckErrorsCount > 1 ? 's' : ''},`
      : '',
    postedByErrorsCount
      ? `${postedByErrorsCount} posting error${postedByErrorsCount > 1 ? 's' : ''},`
      : '',
    'please review the information and try again.',
  ]
    .filter(Boolean)
    .join(' '),
  variant: 'destructive',
});

export const getToastForFailedSubmission = (): ToastDetailsType => ({
  description: 'Unable to submit truck list',
  variant: 'destructive',
});

export const getToastForSuccessSubmission = (): ToastDetailsType => ({
  description: 'Submitted truck list!',
  variant: 'success',
});

export const getToastForInvalidFields = (): ToastDetailsType => ({
  description: 'Some fields are invalid.',
  variant: 'destructive',
});

export const getToastForGenericError = (): ToastDetailsType => ({
  description: 'Something went wrong',
  variant: 'destructive',
});

export const getToastForIngestionTriggerSuccess = (): ToastDetailsType => ({
  description:
    'Email submitted for processing. Please refresh the page in a few minutes to view the parsed content.',
  variant: 'success',
});

export const getToastForIngestionTriggerFailed = (
  message: string
): ToastDetailsType => ({
  description: message || 'Failed to ingest email',
  variant: 'destructive',
});
