import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { Dropdown } from 'antd';
import { FolderHeartIcon } from 'lucide-react';

type StarredLoadToggleProps = React.PropsWithChildren<{
  tab: string;
  starredLoads: string[];
  addLoadTab: (proNumber: string) => void;
}>;

export default function StarredLoadList({
  tab,
  starredLoads,
  addLoadTab,
}: StarredLoadToggleProps) {
  return starredLoads.length ? (
    <Dropdown
      menu={{
        items: starredLoads.map((slID) => ({
          label: `Load - ${slID}`,
          key: slID,
          disabled: slID === tab,
          onClick: () => addLoadTab(slID),
        })),
      }}
      trigger={['click']}
    >
      <FolderHeartIcon className='min-h-5 h-5 min-w-5 w-5 cursor-pointer' />
    </Dropdown>
  ) : null;
}
