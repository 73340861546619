import React from 'react';

import dayjs from 'dayjs';
import { AlertTriangle, MapPin } from 'lucide-react';

import useTMSContext from 'hooks/useTMSContext';
import { CheckCall } from 'types/CheckCall';
import { TMS } from 'types/enums/Integrations';
import { titleCase } from 'utils/formatStrings';

type CheckCallProps = CheckCall;

type TimelineProps = {
  events: CheckCallProps[];
};

const Timeline: React.FC<TimelineProps> = ({ events }) => {
  if (!events) {
    return null;
  }

  const isTZAgnostic =
    useTMSContext().tmsName === TMS.Aljex ||
    useTMSContext().tmsName === TMS.Relay;

  const shortTZ = dayjs().tz().format('z');
  const normalizedTZ = isTZAgnostic ? '' : ` (${shortTZ})`;

  return (
    <>
      {isTZAgnostic && (
        <span className='text-xs font-normal italic text-gray-500 dark:text-gray-500 ms-4'>
          {`Note: Unless otherwise stated, all timestamps are in the timezone of the check call's location.`}
        </span>
      )}

      <ol className='relative border-s ms-4 border-violet-blue-st-patrick dark:border-violet-400 px-2'>
        {events
          // Sort in descending order (most recent first)
          .sort((a, b) =>
            isTZAgnostic
              ? new Date(b.dateTimeWithoutTimezone).getTime() -
                new Date(a.dateTimeWithoutTimezone).getTime()
              : new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
          )
          .map((event) => (
            <li className='mb-10 ms-3' key={event.ID}>
              <span className='absolute flex items-start justify-center w-6 h-6 mr-2 bg-white rounded-full -start-3 ring-1 ring-offset-4 ring-violet-blue-st-patrick dark:ring-gray-900 dark:bg-blue-900'>
                {(event.isException !== null && event.isException) ||
                (event.isOnTime !== null && !event.isOnTime) ? (
                  <AlertTriangle color='#E92C2C' size={'sm'} strokeWidth={1} />
                ) : (
                  <MapPin color='#E92C2C' size={'sm'} strokeWidth={1} />
                )}
              </span>
              <div className='flex items-start text-zinc-600 dark:text-white text-sm leading-6 mt-6 justify-between'>
                <span>{event.status}</span>

                <span>
                  <time className='flex text-right text-xs font-normal leading-none text-gray-500 dark:text-gray-500 pl-6'>
                    {(() => {
                      // Handle if somehow no timestamp provided
                      if (
                        !event.dateTime &&
                        !event.dateTimeWithoutTimezone &&
                        !event.capturedDateTime
                      ) {
                        return <p>N/A</p>;
                      }

                      const dateTime = isTZAgnostic
                        ? event.dateTimeWithoutTimezone
                        : event.dateTime;

                      // If no datetime, used captured time which is already in the correct TZ
                      if (!dateTime) {
                        return (
                          <>
                            {dayjs(event.capturedDateTime).format('M/D/YY')}
                            <br />
                            {`${dayjs(event.capturedDateTime).format('H:mm z')}`}
                          </>
                        );
                      }

                      return (
                        <>
                          {dayjs(dateTime)
                            .tz(isTZAgnostic ? 'UTC' : undefined) // If valid TZ, undefined = display in user's locale
                            .format('M/D/YY')}
                          <br />
                          {`${dayjs(dateTime)
                            .tz(isTZAgnostic ? 'UTC' : undefined) // If valid TZ, undefined = display in user's locale
                            .format('H:mm')} ${normalizedTZ}`}
                        </>
                      );
                    })()}
                  </time>
                </span>
              </div>

              {(event.city || event.state) && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Location:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline text-wrap'>{`${event.city}${event.state ? ', ' + event.state : ''}`}</span>
                </div>
              )}

              {event.reason && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Reason:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {titleCase(event.reason)}
                  </span>
                </div>
              )}

              {event.endDateTimeWithoutTimezone &&
                event.dateTimeWithoutTimezone && (
                  <div>
                    <span className='text-neutral-400 text-xs leading-5 inline'>
                      In:{' '}
                    </span>
                    <span className='text-gray-500 text-xs leading-5 inline'>
                      {dayjs(event.dateTimeWithoutTimezone)
                        .tz(isTZAgnostic ? 'UTC' : undefined)
                        .format('M/D/YY H:mm')}
                    </span>
                  </div>
                )}

              {event.endDateTimeWithoutTimezone &&
                event.dateTimeWithoutTimezone && (
                  <div>
                    <span className='text-neutral-400 text-xs leading-5 inline'>
                      Out:{' '}
                    </span>
                    <span className='text-gray-500 text-xs leading-5 inline'>
                      {dayjs(event.endDateTimeWithoutTimezone)
                        .tz(isTZAgnostic ? 'UTC' : undefined)
                        .format('M/D/YY H:mm')}
                    </span>
                  </div>
                )}

              {event.nextStopETAWithoutTimezone && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    ETA to Next Stop:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {dayjs(event.nextStopETAWithoutTimezone)
                      .tz(isTZAgnostic ? 'UTC' : undefined)
                      .format('M/D/YY H:mm')}
                  </span>
                </div>
              )}

              {event.isOnTime !== null && (
                <div>
                  <span
                    className={`text-neutral-400 text-xs leading-5 inline ${!event.isOnTime ? 'text-red-main bg-red-bg' : ''}`}
                  >
                    On Time:{' '}
                  </span>
                  <span
                    className={`text-gray-500 text-xs leading-5 inline ${!event.isOnTime ? 'text-red-main bg-red-bg px-2' : ''}`}
                  >
                    {`${event.isOnTime}`}
                  </span>
                </div>
              )}

              {event.isException !== null && (
                <div>
                  <span
                    className={`text-neutral-400 text-xs leading-5 inline ${event.isException ? 'bg-red-bg text-red-main' : ''}`}
                  >
                    Issue:{' '}
                  </span>
                  <span
                    className={`text-gray-500 text-xs leading-5 inline ${event.isException ? 'text-red-main bg-red-bg px-2' : ''}`}
                  >
                    {`${event.isException}`}
                  </span>
                </div>
              )}

              {event.notes && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Notes:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.notes}
                  </span>
                </div>
              )}

              {event.author && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Author:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.author}
                  </span>
                </div>
              )}

              {event.source && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Source:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.source}
                  </span>
                </div>
              )}

              {event.capturedDateTime && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Captured at:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {dayjs(event.capturedDateTime).format('M/D/YY H:mm z')}
                  </span>
                </div>
              )}
            </li>
          ))}
      </ol>
    </>
  );
};

export { Timeline, CheckCallProps, TimelineProps };
