import { FocusEventHandler, useEffect, useState } from 'react';

import { Label } from 'components/Label';
import { Input } from 'components/input';
import { MarginType } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';

export type CarrierPriceCalculatorProps = {
  showTitle: boolean;
  finalPrice: number;
  margin: number;
  marginType: MarginType;
  setMarginTypeHandler: React.Dispatch<React.SetStateAction<MarginType>>;
  carrierCost: number;
  onBlurHandler?: FocusEventHandler<HTMLInputElement>;
  setIsCarrierButtonClickedHandler?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedQuoteIndexHandler?: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setCarrierCostHandler?: React.Dispatch<React.SetStateAction<number>>;
  setMarkupHandler?: React.Dispatch<React.SetStateAction<number>>;
  setFinalPriceHandler?: React.Dispatch<React.SetStateAction<number>>;
};

export default function CarrierPriceCalculator({
  showTitle,
  finalPrice,
  margin,
  marginType,
  setMarginTypeHandler,
  carrierCost,
  onBlurHandler,
  setIsCarrierButtonClickedHandler,
  setSelectedQuoteIndexHandler,
  setCarrierCostHandler,
  setMarkupHandler,
  setFinalPriceHandler,
}: CarrierPriceCalculatorProps): JSX.Element {
  const [buyInputValue, setBuyInputValue] = useState(carrierCost.toString());
  const [marginInputValue, setMarginInputValue] = useState(margin.toString());

  useEffect(() => {
    setBuyInputValue(carrierCost.toString());
  }, [carrierCost]);

  useEffect(() => {
    if (!setFinalPriceHandler) return;

    const parsedCarrierCost = parseFloat(buyInputValue) || 0;
    const parsedMargin = parseFloat(marginInputValue) || 0;

    if (!isNaN(parsedCarrierCost) && !isNaN(parsedMargin)) {
      if (marginType === MarginType.Flat) {
        setFinalPriceHandler(parsedCarrierCost + parsedMargin);
      } else {
        setFinalPriceHandler(parsedCarrierCost * (1 + parsedMargin / 100));
      }
    }
  }, [buyInputValue, marginInputValue, marginType, setFinalPriceHandler]);

  const handleBuyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setBuyInputValue(value);

    if (setSelectedQuoteIndexHandler) {
      setSelectedQuoteIndexHandler(null);
    }
    if (setCarrierCostHandler && value !== '') {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        setCarrierCostHandler(numValue);
      }
    }
    if (setIsCarrierButtonClickedHandler) {
      setIsCarrierButtonClickedHandler(false);
    }
  };

  const handleMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMarginInputValue(value);

    if (setMarkupHandler && value !== '') {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        setMarkupHandler(numValue);
      }
    }
  };

  const handleMarginTypeToggle = () => {
    // Convert margin value when switching types
    if (marginInputValue) {
      const currentValue = parseFloat(marginInputValue) || 0;
      if (marginType === MarginType.Flat) {
        // Converting from flat rate to percentage
        const carrierValue = parseFloat(buyInputValue) || 0;
        if (carrierValue > 0) {
          const percentageValue = ((currentValue / carrierValue) * 100).toFixed(
            0
          );
          setMarginInputValue(percentageValue);
          if (setMarkupHandler) {
            setMarkupHandler(parseFloat(percentageValue));
          }
        }
      } else {
        // Converting from percentage to flat rate
        const carrierValue = parseFloat(buyInputValue) || 0;
        const flatValue = (carrierValue * (currentValue / 100)).toFixed(0);
        setMarginInputValue(flatValue);
        if (setMarkupHandler) {
          setMarkupHandler(parseFloat(flatValue));
        }
      }
    }
    setMarginTypeHandler(
      marginType === MarginType.Flat ? MarginType.Percentage : MarginType.Flat
    );
  };

  return (
    <>
      {showTitle && (
        <h2 className='mb-3 text-sm font-medium text-grayscale-content-description'>
          Calculate Final Price
        </h2>
      )}

      <div className='flex items-baseline gap-0 mb-2 xxs:gap-3'>
        {/* Carrier Cost */}
        <div className='flex flex-col gap-1 min-w-0'>
          <Label
            name='buy'
            className='text-grayscale-content-description !text-xs font-medium'
          >
            Buy
          </Label>
          <div className='flex items-center relative'>
            <span className='absolute left-1 xxs:left-2 text-sm text-grayscale-content-description'>
              $
            </span>
            <Input
              type='number'
              value={buyInputValue}
              onChange={handleBuyChange}
              onBlur={onBlurHandler ?? undefined}
              className='pl-4 pr-1 xxs:pr-2 xxs:pl-5 py-1.5 text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
              min={0}
              step='1'
              aria-label='Carrier cost input'
            />
          </div>
        </div>

        {/* Margin */}
        <div className='flex flex-col items-start gap-1 min-w-0'>
          <Label
            name='margin'
            className='text-grayscale-content-description !text-xs pl-4 font-medium'
          >
            Margin
          </Label>
          <div className='flex flex-col items-center gap-1'>
            <div className='flex items-center'>
              <span className='mx-1 xxs:mx-0 xxs:mr-2 text-sm text-grayscale-content-description'>
                +
              </span>
              <div className='relative flex items-center'>
                {marginType === MarginType.Flat && (
                  <div className='absolute top-1.5 left-1 xxs:left-2 text-sm text-grayscale-content-description'>
                    $
                  </div>
                )}
                <Input
                  type='number'
                  value={marginInputValue}
                  onChange={handleMarginChange}
                  onBlur={onBlurHandler ?? undefined}
                  className={`min-w-[60px] text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                    marginType === MarginType.Flat
                      ? 'pl-4 xxs:pl-5'
                      : 'pl-4 xxs:pl-5'
                  }`}
                  min={0}
                  step='1'
                  aria-label='Margin input'
                />
                {marginType !== MarginType.Flat && (
                  <div className='absolute top-1.5 right-1 xxs:right-2 text-sm text-grayscale-content-description'>
                    %
                  </div>
                )}
              </div>
              <span className='mx-1 xxs:mx-0 xxs:ml-2 text-sm text-grayscale-content-description'>
                =
              </span>
            </div>
            <div className='flex rounded-[4px] overflow-hidden border border-grayscale-border-input text-xs'>
              <button
                type='button'
                onClick={handleMarginTypeToggle}
                className={`px-2 transition-colors  ${
                  marginType === MarginType.Flat
                    ? 'text-[#FE9659] font-medium bg-orange-bg'
                    : 'text-grayscale-content-description hover:text-[#FE9659]'
                }`}
              >
                $
              </button>
              <button
                type='button'
                onClick={handleMarginTypeToggle}
                className={`px-2 transition-colors  ${
                  marginType === MarginType.Percentage
                    ? 'text-[#FE9659] font-medium bg-orange-bg'
                    : 'text-grayscale-content-description hover:text-[#FE9659]'
                }`}
              >
                %
              </button>
            </div>
          </div>
        </div>

        {/* Final Price */}
        <div className='flex flex-col gap-1'>
          <Label
            name='sell'
            className='text-grayscale-content-description !text-xs font-medium'
            required
          >
            Sell
          </Label>
          <div className='flex items-center relative'>
            <span className='absolute left-1 xxs:left-2 text-sm text-grayscale-content-description'>
              $
            </span>
            <Input
              className='pl-4 xxs:pl-5 pr-2 py-1.5 text-sm max-xxs:w-[62px] read-only:bg-gray-50 
                read-only:text-grayscale-content-description read-only:border-grayscale-border-input'
              type='text'
              value={isNaN(finalPrice) ? '' : finalPrice.toFixed(0)}
              disabled
              aria-label='Final price'
            />
          </div>
          {!finalPrice && finalPrice <= 0 && (
            <p className='text-red-500 text-xs '>Required</p>
          )}
        </div>
      </div>
    </>
  );
}
