import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { MarginType } from 'pages/QuoteView/Quoting/RequestQuickQuote/types';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type SubmitFinalQuoteBody = {
  id: number;
  finalQuotePrice: number;
  finalMargin: number;
  marginType: MarginType;
  finalCarrierCost: number;
};

export async function submitFinalQuote(
  quoteBody: SubmitFinalQuoteBody
): Promise<Result<void, ApiError>> {
  try {
    const resp = await axios.post(`/quote/private/final`, quoteBody);
    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'submitFinalQuote' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to submit final quote' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data?.message || 'Failed to submit final quote',
    });
  }
}
