import * as React from 'react';

export default function HighwayLogo(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 111.535 71.095'
      {...props}
    >
      <path d='M72.927 71.095L111.535 71.095L82.936 0L63.632 0L66.849 25.366L44.328 25.366L47.903 0L28.599 0L0 71.095L38.608 71.095L41.826 45.729L69.709 45.729L72.927 71.095Z' />
    </svg>
  );
}
