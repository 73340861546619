import useTMSContext from 'hooks/useTMSContext';
import { ObjectData } from 'types/UtilityTypes';
import { TMS } from 'types/enums/Integrations';

import { denormalizeUTCDate, normalizeUTCDate } from './time';

export function parseDatesForTMSForm(data: ObjectData) {
  const finalObject: ObjectData = {};
  const { tmsName } = useTMSContext();

  Object.keys(data).forEach((field) => {
    let value = data[field];

    const isTimeField =
      field.endsWith('Start') ||
      field.endsWith('End') ||
      field.endsWith('Time');

    if (value && isTimeField) {
      if (value !== '0001-01-01T00:00:00Z') {
        // NOTE: Aljex has no timezone data, so the backend defaults datetime conversions to UTC,
        // but the front-end preserves users' current experience by showing the timestamp
        // exactly as it is written in Aljex.
        value = new Date(
          tmsName === TMS.Aljex
            ? normalizeUTCDate(new Date(value))
            : new Date(value)
        ).toLocaleString('en-US', {
          hour12: false,
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      } else {
        value = '';
      }
    }

    finalObject[field] = value;
  });

  return finalObject;
}

export function normalizeDatesForTMSForm(tmsName: string, data: ObjectData) {
  const finalObject: ObjectData = {};

  Object.keys(data).forEach((field) => {
    let value = data[field];

    const isTimeField =
      field.endsWith('Start') ||
      field.endsWith('End') ||
      field.endsWith('Time');

    if (value && isTimeField) {
      if (value !== '0001-01-01T00:00:00Z') {
        // NOTE: Aljex has no timezone data, so the backend defaults datetime conversions to UTC,
        // but the front-end preserves users' current experience by showing the timestamp
        // exactly as it is written in Aljex.
        value =
          tmsName === TMS.Aljex
            ? normalizeUTCDate(new Date(value))
            : new Date(value);
      } else {
        value = null;
      }
    }

    finalObject[field] = value;
  });

  return finalObject;
}

export function denormalizeDatesForTMSForm(
  tmsName: string,
  data: ObjectData
): ObjectData {
  if (!data) {
    return data;
  }

  const finalObject: ObjectData = {};

  Object.keys(data).forEach((field) => {
    let value = data[field];

    const isTimeField =
      field.endsWith('Start') ||
      field.endsWith('End') ||
      field.endsWith('Time');

    if (isTimeField) {
      if (value === null) {
        value = '';
      } else {
        // For Aljex loads, preserve datetime values in UTC
        value =
          tmsName === TMS.Aljex
            ? denormalizeUTCDate(new Date(value))
            : new Date(value);
      }
    }

    finalObject[field] = value;
  });

  return finalObject;
}

/**
 * If the user's TMS is Aljex, normalizes the date to be agnostic of the user's locale.
 *
 * @example

 * const date = new Date('2024-03-30T15:30:00Z');
 * const normalizedDate = normalizeDateForTMS(date);
 * // If user TMS is Aljex, user sees: 2024-03-30 15:30
 * // If user's TMS is NOT Aljex, user sees 2024-03-30 11:30 (time converted to -4h offset)
 *
 * @param {Date} date - The date to be normalized.
 * @returns {Date} - The normalized date.
 */
export function normalizeDateForTMS(date: Date) {
  const { tmsName } = useTMSContext();

  return tmsName === TMS.Aljex ? normalizeUTCDate(date) : date;
}

export function denormalizeDateForTMS(date: Date) {
  const { tmsName } = useTMSContext();

  return tmsName === TMS.Aljex ? denormalizeUTCDate(date) : date;
}
