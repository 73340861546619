import React, { useContext, useEffect, useState } from 'react';

/* global Office */

export const INITIAL_OFFICE_CONTEXT: IOfficeContext = {
  isLoading: true,
  isInitialized: false,
  inboxEmailAddress: '',
  subject: '',
  threadId: '',
  threadItemId: '',
  isOutlookReply: false,
};

interface OfficeContextProviderProps {
  children: React.ReactNode;
}

const OfficeContext = React.createContext<IOfficeContext>(
  INITIAL_OFFICE_CONTEXT
);

export const useOfficeContext = () => useContext(OfficeContext);

export const OfficeContextProvider: React.FC<OfficeContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<IOfficeContext>(INITIAL_OFFICE_CONTEXT);

  useEffect(() => {
    const asyncHandler = async () => {
      // We'll call this function to read the current office state
      // from mailbox, item.
      const update = () => {
        // Unpack the office context.
        const mailbox = Office.context.mailbox;
        const item = mailbox?.item;
        const getItemState = (): IItemState | undefined => {
          if (!item) return undefined;
          const {
            from,
            to,
            internetMessageId,
            conversationId,
            itemType,
            cc,
            body,
            itemId,
            subject,
            dateTimeCreated,
          } = item;
          return {
            from,
            to,
            cc,
            internetMessageId,
            conversationId,
            itemType,
            itemId,
            body,
            subject,
            dateTimeCreated,
          };
        };

        const getMailboxState = (): IMailboxState | undefined => {
          if (!mailbox) return undefined;

          const { userProfile } = mailbox;

          return {
            userProfile,
            item: getItemState(),
          };
        };

        setState({
          state: {
            mailbox: getMailboxState(),
          },
          isLoading: false,
          isInitialized: true,
          inboxEmailAddress: mailbox.userProfile.emailAddress,
          subject: item?.subject ?? '',
          threadId: mailbox.item?.conversationId ?? '',
          threadItemId: mailbox.item?.itemId ?? '',
          // TODO improve logic
          // if drumkit is open on outlook but doesn't have item.from as a sender,
          // we can assume we're not reading but replying instead
          isOutlookReply: !!(
            mailbox?.item?.from && !mailbox.item.from.emailAddress
          ),
        });
      };

      // Update our application, now that Office is initialized.
      update();
      // update data when mail item changes, for pinnable task pane
      Office.context.mailbox &&
        Office.context.mailbox.addHandlerAsync(
          Office.EventType.ItemChanged,
          update
        );
    };
    Office.onReady(() => {
      asyncHandler();
    });
  }, []);

  return (
    <OfficeContext.Provider value={state}>{children}</OfficeContext.Provider>
  );
};

export const OfficeContextConsumer = OfficeContext.Consumer;

export type EmailAddressDetails = {
  displayName: string;
  emailAddress: string;
};

export interface IItemState {
  from: EmailAddressDetails;
  to: EmailAddressDetails[];
  cc: EmailAddressDetails[];
  internetMessageId: string;
  conversationId: string;
  itemType: string;
  itemId: string;
  body: Office.Body;
  subject: string;
  dateTimeCreated: Date;
}

export interface IMailboxState {
  item?: IItemState;
  userProfile: EmailAddressDetails;
}

export interface IOfficeState {
  mailbox?: IMailboxState;
}

export interface IOfficeContext {
  // Different from isInitialized because we don't want to show the "Please open add-in in Outlook" message while
  // OfficeContext is still loading
  isLoading: boolean;
  isInitialized: boolean;
  inboxEmailAddress: string;
  subject: string;
  threadId: string;
  threadItemId: string;
  isOutlookReply: boolean;
  state?: IOfficeState;
}
