type ErrorMessageField = {
  name: string;
  type: 'showText' | 'hideText';
};

type ErrorMessages = {
  [key: string]: {
    fields: ErrorMessageField[];
    message: string;
    isToast: boolean;
  };
};

export const carrierErrorMessages: ErrorMessages = {
  'no-carriers-found': {
    fields: [{ name: 'carrier.contact.email', type: 'showText' }],
    message: 'No carriers were found in Redwood',
    isToast: false,
  },
  // 'multiple-carriers-found': {}, - This is handled by a separate UI component
  'unexpected-error-occurred': {
    fields: [{ name: 'carrier.contact.email', type: 'showText' }],
    message: 'Unexpected carrier error from Redwood',
    isToast: false,
  },
};

export const carrierContactErrorMessages: ErrorMessages = {
  'no-carrier-contact-found': {
    fields: [
      { name: 'carrier.contact.name', type: 'showText' },
      { name: 'carrier.contact.email', type: 'showText' },
    ],
    message: 'Carrier contact not found in Redwood',
    isToast: false,
  },
  'unexpected-error-occurred': {
    fields: [
      { name: 'carrier.contact.name', type: 'showText' },
      { name: 'carrier.contact.email', type: 'showText' },
    ],
    message: 'Unexpected carrier contact error from Redwood',
    isToast: false,
  },
};

// Posted by errors mean there was an issue with Redwood Connect.
// Show user-friendly toast message instead of updating form with errors.
export const postedByErrorMessages: ErrorMessages = {
  'posted-by-not-found': {
    fields: [],
    message: 'Drumkit user not found on Redwood Connect for posting truck list',
    isToast: true,
  },
  'unexpected-error-occurred': {
    fields: [],
    message: 'Unexpected error while matching Drumkit user on Redwood Connect',
    isToast: true,
  },
};

export const pickupDateErrorMessages: ErrorMessages = {
  'availabledate-is-required': {
    fields: [{ name: 'update.pickupDate', type: 'showText' }],
    message: 'Date for Redwood is required',
    isToast: false,
  },
  'availabledate-mustbe-noworfuture': {
    fields: [{ name: 'update.pickupDate', type: 'showText' }],
    message: "Date for Redwood can't be in the past",
    isToast: false,
  },
  'availabledate-mustbe-withinthirtydays': {
    fields: [{ name: 'update.pickupDate', type: 'showText' }],
    message: 'Date for Redwood must be within 30 days',
    isToast: false,
  },
  'unexpected-error-occurred': {
    fields: [{ name: 'update.pickupDate', type: 'showText' }],
    message: 'Unexpected pickup date error from Redwood',
    isToast: false,
  },
};

export const pickupLocationErrorMessages: ErrorMessages = {
  'pickup-could-not-be-found': {
    fields: [
      { name: 'pickupLocation.city', type: 'showText' },
      { name: 'pickupLocation.state', type: 'hideText' },
    ],
    message: 'Location not found in Redwood',
    isToast: false,
  },
  'unexpected-error-occurred': {
    fields: [
      { name: 'pickupLocation.city', type: 'showText' },
      { name: 'pickupLocation.state', type: 'hideText' },
    ],
    message: 'Unexpected location error from Redwood',
    isToast: false,
  },
};

export const dropoffLocationErrorMessages: ErrorMessages = {
  'carrier-domicile-not-found': {
    fields: [
      { name: 'dropoffLocation.city', type: 'showText' },
      { name: 'dropoffLocation.state', type: 'hideText' },
    ],
    message: 'Carrier domicile not found in Redwood',
    isToast: false,
  },
  'destination-needs-more-than-city': {
    // State is always required as we don't support zipcodes yet
    fields: [{ name: 'dropoffLocation.state', type: 'showText' }],
    // Keep error message short since state input has a small width
    message: 'State is required by Redwood',
    isToast: false,
  },
  'destination-city-state-not-found': {
    fields: [
      { name: 'dropoffLocation.city', type: 'showText' },
      { name: 'dropoffLocation.state', type: 'hideText' },
    ],
    message: 'Unable to find city-state match in Redwood',
    isToast: false,
  },
  'destination-state-not-found': {
    // State is always required as we don't support zipcodes yet
    fields: [{ name: 'dropoffLocation.state', type: 'showText' }],
    // Keep error message short since state input has a small width
    message: 'State not found in Redwood',
    isToast: false,
  },
  'unexpected-error-occurred': {
    fields: [
      { name: 'dropoffLocation.city', type: 'showText' },
      { name: 'dropoffLocation.state', type: 'hideText' },
    ],
    message: 'Unexpected location error from Redwood',
    isToast: false,
  },
};

export const equipmentErrorMessages: ErrorMessages = {
  'invalid-or-unknown-truck-type': {
    fields: [{ name: 'type', type: 'showText' }],
    message: 'Invalid truck type in Redwood',
    isToast: false,
  },
  'no-matching-equipment-found': {
    fields: [
      { name: 'type', type: 'showText' },
      { name: 'length', type: 'hideText' },
    ],
    message: 'No matching equipment found in Redwood',
    isToast: false,
  },
  'no-matching-trailer-type-found-in-carrier-file': {
    fields: [{ name: 'type', type: 'showText' }],
    message: 'No matching trailer type for carrier in Redwood',
    isToast: false,
  },
  'no-carriers-found': {
    fields: [
      { name: 'type', type: 'showText' },
      { name: 'length', type: 'hideText' },
    ],
    message: 'Error matching equipment to carrier in Redwood', // TODO: Ask Redwood what this means
    isToast: false,
  },
  'unexpected-error-occurred': {
    fields: [
      { name: 'type', type: 'showText' },
      { name: 'length', type: 'hideText' },
    ],
    message: 'Unexpected equipment error from Redwood',
    isToast: false,
  },
};

export const postingErrorMessages: ErrorMessages = {
  'truck-not-posted': {
    fields: [],
    message:
      'Could not post truck to Redwood, please contact your organization administrator',
    isToast: true,
  },
  'unexpected-error-occurred': {
    fields: [],
    message:
      'Unexpected posting error from Redwood, please contact your organization administrator',
    isToast: true,
  },
};
