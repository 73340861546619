import { createContext, useCallback, useContext, useState } from 'react';

import putViewedLoadHistory from '../lib/api/putViewedLoadHistory';

export type AddLoadTabFn = (proNumber: string) => Promise<void>;

type LoadSearchContextType = {
  proNumberInput: string;
  setProNumberInput: (value: string) => void;
  addLoadTab: AddLoadTabFn;
  proNumbers: string[];
  setProNumbers: (
    proNumbers: string[] | ((prev: string[]) => string[])
  ) => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
};

const noop: AddLoadTabFn = async () => {};

export const LoadSearchContext = createContext<LoadSearchContextType>({
  proNumberInput: '',
  setProNumberInput: () => {},
  addLoadTab: noop,
  proNumbers: [],
  setProNumbers: () => {},
  activeTab: '',
  setActiveTab: () => {},
});

type LoadSearchProviderProps = {
  children: React.ReactNode;
  initialFreightTrackingIDs?: string[];
};

export function LoadSearchProvider({
  children,
  initialFreightTrackingIDs = [],
}: LoadSearchProviderProps) {
  const [proNumberInput, setProNumberInput] = useState('');
  const [proNumbers, setProNumbers] = useState<string[]>(
    initialFreightTrackingIDs
  );
  const [activeTab, setActiveTab] = useState<string>(
    initialFreightTrackingIDs[0] || ''
  );

  const addLoadTab = useCallback(async (proNumber: string) => {
    if (typeof proNumber !== 'string') {
      console.error('Invalid proNumber:', proNumber);
      return;
    }
    const trimmedProNumber = proNumber.trim();
    if (!trimmedProNumber) return;

    setProNumbers((prevProNumbers) => {
      if (prevProNumbers.includes(trimmedProNumber)) {
        setActiveTab(trimmedProNumber);
        return prevProNumbers;
      }
      setActiveTab(trimmedProNumber);
      return [...prevProNumbers, trimmedProNumber];
    });

    setProNumberInput('');

    try {
      const res = await putViewedLoadHistory(trimmedProNumber);
      if (!res.isOk()) {
        console.error('putViewedLoadHistory response not OK:', res);
      }
    } catch (error) {
      console.error('Error updating viewed loads:', error);
    }
  }, []);

  return (
    <LoadSearchContext.Provider
      value={{
        proNumberInput,
        setProNumberInput,
        addLoadTab,
        proNumbers,
        setProNumbers,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </LoadSearchContext.Provider>
  );
}

export function useLoadSearch() {
  const context = useContext(LoadSearchContext);
  if (context === undefined) {
    throw new Error('useLoadSearch must be used within a LoadSearchProvider');
  }
  return context;
}
