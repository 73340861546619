import { useEffect, useRef, useState } from 'react';

import { Input, Tag } from 'antd';
import { PlusIcon } from 'lucide-react';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/Tooltip';
import { cn } from 'utils/shadcn';

import { PurchaseOrder } from './RetalixForm';

type RetalixFormProps = {
  purchaseOrders: PurchaseOrder[];
  setPurchaseOrders: React.Dispatch<React.SetStateAction<PurchaseOrder[]>>;
};

export function RetalixPOList({
  purchaseOrders,
  setPurchaseOrders,
}: RetalixFormProps) {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');

  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      (inputRef as React.RefObject<HTMLInputElement>).current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    (editInputRef as React.RefObject<HTMLInputElement>).current?.focus();
  }, [editInputValue]);

  const showInput = () => setInputVisible(true);

  const handleClose = (removedPONumber: string) =>
    setPurchaseOrders((prevPurchaseOrders) =>
      prevPurchaseOrders.filter((poNum) => poNum.number !== removedPONumber)
    );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value);
  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditInputValue(e.target.value);

  const handleInputConfirm = () => {
    const newTag = inputValue.trim();
    if (inputValue && !purchaseOrders.map((po) => po.number).includes(newTag)) {
      setPurchaseOrders([
        ...purchaseOrders,
        { number: newTag, isValid: false, error: '' },
      ]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputConfirm = () => {
    const newTags = [...purchaseOrders];
    newTags[editInputIndex] = {
      number: editInputValue,
      isValid: false,
      error: '',
    };
    setPurchaseOrders(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  return (
    <TooltipProvider>
      <div className='flex justify-start flex-wrap gap-2 mt-2'>
        {purchaseOrders.map((order, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={order.number}
                className='h-9 me-2 align-center bg-white text-[14px]'
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }

          const poNumTag = (
            <Tag
              key={order.number}
              closable={true}
              className={cn(
                'flex justify-between items-center select-none mx-0 h-9 bg-white text-[14px]',
                !order.isValid && order.error !== '' && 'bg-red-100'
              )}
              onClose={() => handleClose(order.number)}
            >
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    setEditInputIndex(index);
                    setEditInputValue(order.number);
                    e.preventDefault();
                  }
                }}
              >
                {order.number}
              </span>
            </Tag>
          );

          return order.isValid || order.error === '' ? (
            poNumTag
          ) : (
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>{poNumTag}</TooltipTrigger>
              <TooltipContent className='mx-2 max-w-80 whitespace-pre-wrap'>
                {order.error}
              </TooltipContent>
            </Tooltip>
          );
        })}
      </div>

      <div className='mt-2'>
        {inputVisible ? (
          <Input
            ref={inputRef}
            type='text'
            className='h-9 me-2 align-top'
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag
            className='h-9 flex gap-2 border-dashed bg-white items-center text-[14px] cursor-pointer'
            icon={
              <PlusIcon className='h-4 w-4 stroke-[1px] stroke-grayscale-icon-stroke' />
            }
            onClick={showInput}
          >
            Add new PO #
          </Tag>
        )}
      </div>
    </TooltipProvider>
  );
}
