import { CheckCall } from 'types/CheckCall';
import { NormalizedLoadCarrier } from 'types/Load';

import { Maybe } from '../UtilityTypes';
import {
  GenericSuggestionCore,
  SuggestionCategories,
  SuggestionPipelines,
} from './CoreSuggestions';

export type S3Attachment = {
  originalFileName: string;
  transformedFileName: string;
  s3URL: string;
};

export enum SuggestionStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Pending = 'pending',
  InFlight = 'inFlight',
}

type SuggestedLoadChangeApptOrCarrier = {
  pipeline:
    | SuggestionPipelines.CarrierInfo
    | SuggestionPipelines.ApptConfirmation;
  category: SuggestionCategories.PickupInfo | SuggestionCategories.CarrierInfo;
  suggested: LoadChanges;
  applied: LoadChanges;
};

type SuggestedLoadChangeCheckCall = {
  pipeline: SuggestionPipelines.CheckCall;
  category: SuggestionCategories.CheckCall;
  suggested: {
    checkCallChanges: CheckCallChanges;
  };
  applied: {
    checkCallChanges: CheckCallChanges;
  };
};

type SuggestedLoadOptionalChangeApptOrCarrier = {
  pipeline:
    | SuggestionPipelines.CarrierInfo
    | SuggestionPipelines.ApptConfirmation;
  category: SuggestionCategories.PickupInfo | SuggestionCategories.CarrierInfo;
  suggested: LoadChanges;
  applied: LoadChanges;
};

type SuggestedLoadOptionalChangeCheckCall = {
  pipeline: SuggestionPipelines.CheckCall;
  category: SuggestionCategories.CheckCall;
  suggested: {
    checkCallChanges: CheckCallChanges;
  };
  applied: {
    checkCallChanges: CheckCallChanges;
  };
};

export type SuggestedLoadChange = GenericSuggestionCore &
  (SuggestedLoadChangeApptOrCarrier | SuggestedLoadChangeCheckCall);

export type SuggestedLoadOptionalChange = GenericSuggestionCore &
  (
    | SuggestedLoadOptionalChangeApptOrCarrier
    | SuggestedLoadOptionalChangeCheckCall
  );

export type LoadChanges = CarrierChanges & Partial<ApptConfirmationChanges>;

export type CarrierChanges = Partial<
  Pick<
    NormalizedLoadCarrier,
    | 'firstDriverName'
    | 'firstDriverPhone'
    | 'secondDriverName'
    | 'secondDriverPhone'
    | 'truckNumber'
    | 'trailerNumber'
    | 'dispatchSource'
    | 'dispatchCity'
    | 'dispatchState'
    | 'dispatchedTime'
    | 'expectedPickupTime'
  >
>;

export type ApptConfirmationChanges = {
  pickupApptTime: Date;
  dropoffApptTime: Date;
};

export type CheckCallChanges = Partial<
  Pick<
    CheckCall & {
      timestamp: Maybe<string>;
    },
    'status' | 'city' | 'state' | 'notes' | 'timestamp'
  >
>;

export type CheckCallSuggestion = SuggestedLoadChange & {
  pipeline: SuggestionPipelines.CheckCall;
};
