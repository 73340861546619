import { createContext } from 'react';

import { TMS } from 'types/enums/Integrations';

export type Service = {
  serviceID: number;
  tmsIntegrations: TMSCore[];
  serviceFeaturesEnabled: ServiceFeaturesListType;
};

export type TMSCore = {
  id: number;
  name: TMS;
};

export type ServiceFeaturesListType = {
  isAppointmentSchedulingEnabled: boolean;
  isTrackAndTraceEnabled: boolean;
  isQuickQuoteEnabled: boolean;
  isAppointmentSuggestionsEnabled: boolean;
  isCarrierInfoSuggestionsEnabled: boolean;
  isTurvoSectionLinksEnabled: boolean;
  isOperatorEnabled: boolean;
  isExceptionsEnabled: boolean;
  isAppointmentViewEnabled: boolean;
  isCheckCallCarrierSOPEnabled: boolean;
  isCheckCallShipperSOPEnabled: boolean;
  isCheckCallNotesEnabled: boolean;
  isCarrierNetworkQuotingEnabled: boolean;
  isCarrierEmailOutboxEnabled: boolean;
  isAdvancedSearchEnabled: boolean;
  isCarrierVerificationEnabled: boolean;
  isTruckListEnabled: boolean;
  isTMSQuoteSubmissionEnabled: boolean;
  isAljexWrapperEnabled: boolean;
  isLoadBuildingEnabled: boolean;
  isQuoteLaneHistoryEnabled: boolean;
  isQuoteSubmissionViaURLEnabled: boolean;
  isQuoteSubmissionToServiceEnabled: boolean;
  isGetLaneRateFromServiceEnabled: boolean;
};

export const allEnabledServiceFeatures = {
  isAppointmentSchedulingEnabled: true,
  isTrackAndTraceEnabled: true,
  isQuickQuoteEnabled: true,
  isAppointmentSuggestionsEnabled: true,
  isCarrierInfoSuggestionsEnabled: true,
  isTurvoSectionLinksEnabled: true,
  isOperatorEnabled: true,
  isExceptionsEnabled: true,
  isAppointmentViewEnabled: true,
  isCheckCallCarrierSOPEnabled: true,
  isCheckCallShipperSOPEnabled: true,
  isCheckCallNotesEnabled: true,
  isCarrierNetworkQuotingEnabled: true,
  isCarrierEmailOutboxEnabled: true,
  isAdvancedSearchEnabled: true,
  isCarrierVerificationEnabled: true,
  isTruckListEnabled: true,
  isTMSQuoteSubmissionEnabled: true,
  isAljexWrapperEnabled: true,
  isLoadBuildingEnabled: true,
  isQuoteLaneHistoryEnabled: true,
  isQuoteSubmissionViaURLEnabled: true,
  isQuoteSubmissionToServiceEnabled: true,
  isGetLaneRateFromServiceEnabled: true,
};

export const initialServiceFeatureValues: ServiceFeaturesListType = {
  isAppointmentSchedulingEnabled: false,
  isTrackAndTraceEnabled: false,
  isQuickQuoteEnabled: false,
  isAppointmentSuggestionsEnabled: false,
  isCarrierInfoSuggestionsEnabled: false,
  isTurvoSectionLinksEnabled: false,
  isOperatorEnabled: false,
  isExceptionsEnabled: false,
  isAppointmentViewEnabled: false,
  isCheckCallCarrierSOPEnabled: false,
  isCheckCallShipperSOPEnabled: false,
  isCheckCallNotesEnabled: false,
  isCarrierNetworkQuotingEnabled: false,
  isCarrierEmailOutboxEnabled: false,
  isAdvancedSearchEnabled: false,
  isCarrierVerificationEnabled: false,
  isTruckListEnabled: false,
  isTMSQuoteSubmissionEnabled: false,
  isAljexWrapperEnabled: false,
  isLoadBuildingEnabled: false,
  isQuoteLaneHistoryEnabled: false,
  isQuoteSubmissionViaURLEnabled: false,
  isQuoteSubmissionToServiceEnabled: false,
  isGetLaneRateFromServiceEnabled: false,
};

export const initialServiceValues: Service = {
  serviceID: 0,
  tmsIntegrations: [],
  serviceFeaturesEnabled: initialServiceFeatureValues,
};

export type ServiceContextType = Service & {
  setService: React.Dispatch<React.SetStateAction<Service>>;
};

export const ServiceContext = createContext<ServiceContextType>({
  serviceID: 0,
  tmsIntegrations: [],
  serviceFeaturesEnabled: initialServiceFeatureValues,
  setService: () => false,
});
