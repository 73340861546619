import { AxiosError, AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { SidebarState } from 'contexts/sidebarStateContext';

type FetchViewedLoadsResponse = {
  viewedLoadsHistory: string[];
};

export function fetchViewedLoads(setCurrentState: (state: any) => void): void {
  axios
    .get<FetchViewedLoadsResponse>(`load/viewed`)
    .then((axiosRes: AxiosResponse<FetchViewedLoadsResponse>) => {
      const {
        data: { viewedLoadsHistory },
      } = axiosRes;

      setCurrentState((prevState: SidebarState) => ({
        ...prevState,
        viewedLoads: viewedLoadsHistory ?? [],
      }));
    })
    .catch((error: AxiosError) => {
      console.error('Error fetching viewed loads:', error);
    });
}
