import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type SubmitQuoteViaURLRequest = {
  quoteAmount: number;
  quoteNumber: string;
  expirationDate: Date;
  eta: Date;
};

export async function submitQuoteViaURL(
  emailID: number,
  submissionBody: SubmitQuoteViaURLRequest
): Promise<Result<null, ApiError>> {
  try {
    const resp = await axios.post(
      `quote/private/url-submit/email/${encodeURIComponent(emailID)}`,
      submissionBody
    );
    return ok(resp.data);
  } catch (error) {
    console.log('this is the error', error);

    captureException(error, { functionName: 'submitQuoteViaURL' });

    if (isAxiosError(error)) {
      if (error.message === 'Extension context invalidated.') {
        throw error;
      }

      switch (error.response?.status) {
        case 500:
          return err({
            message: 'Invalid quote number or expired link.',
          });
        default:
          throw error;
      }
    }

    return err({
      message: 'Error submitting quote via hyperlink.',
    });
  }
}
